import update from 'immutability-helper';

import {
  IC_SET_CLIENT_FIRST_NAME,
  IC_SET_CLIENT_LAST_NAME,
  IC_SET_CLIENT_EMAIL,
  IC_SET_CLIENT_TYPE,
  IC_SET_CLIENT_USER_TYPE,
  IC_SET_CLIENTS_LIST,
  IC_SET_FUNDS_LIST,
  IC_SET_SELECTED_FUNDS,
  IC_SET_INVITE_MODAL_STATUS,
  IC_RESET_FORM_DATA,
  IC_SET_ERROR,
  IC_SET_MESSAGE,
  IC_SET_ALLOW_FILES_MANAGEMENT,
  IC_SET_STORED_FILES,
  IC_SET_MULTIPLE_CLIENTS_INVITATION_LIST,
  IC_SET_INVITE_TYPE,
  IC_RESET_INVITE_CLIENTS_DATA,
  IC_SET_PRE_EXISTING_RELATIONSHIP,
  IC_SET_INVITE_RESPONSE,
  IC_SET_HIDE_COOLOFF_GP_ATTESTATION,
  IC_SET_DEEPLINKING_FUNDS_LIST,
  IC_SET_DEEPLINKING_SELECTED_FUNDS,
  IC_SET_CURRENT_BATCH_LABEL,
  IC_SET_TOTAL_BATCH_INVITES,
  IC_SET_GP_ADMIN_COOLOFF_DIRECTS_ATTESTATION,
  IC_SET_GP_ADMIN_COOLOFF_FEEDERS_ATTESTATION,
} from 'actions/actionsConstants';

export const INVITE_MODAL_STATUS = {
  none: 'none',
  invite_form: 'invite_form',
  reinvitation_confirmation: 'reinvitation_confirmation',
  representative_confirmation: 'representative_confirmation',
  reminder_confirmation: 'reminder_confirmation',
  show_status_message: 'show_status_message',
  show_preview_files_data: 'show_preview_files_data',
  show_failure_message: 'show_failure_message',
  preview_clients_to_invite: 'preview_clients_to_invite',
  send_batch_invites: 'send_batch_invites',
};

export const initialState = {
  client_first_name: '',
  client_last_name: '',
  client_email_address: '',
  client_type: '',
  client_user_type: '',
  funds_list: [],
  deeplinking_funds_list: [],
  pre_existing_relationship: false,
  allow_files_management: true,
  stored_files: {
    files: [],
    size: 0,
  },
  selected_funds: [],
  deeplinking_selected_funds: [],
  invite_modal_status: INVITE_MODAL_STATUS.none,
  message: '',
  invite_failure_modal_message: '',
  error: false,
  error_title: '',
  multiple_clients_invitation_list: [],
  clients_list: [],
  clients_for_reminder: [],
  clients_for_reinvitation: [],
  invite_type: { type: 'individual' },
  has_clients_for_reinvitation: false,
  has_clients_for_reminder: false,
  confirmed_clients_for_reinvitation: [],
  confirmed_clients_for_reminder: [],
  invite_response: { invited_clients: 0, warnings: {}, errors: {} },
  current_batch: null,
  total_batch_invites: null,
  hide_cooloff_gp_attestation: false,
  gp_admin_cooloff_directs_attestation: false,
  gp_admin_cooloff_feeders_attestation: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case IC_SET_CLIENT_FIRST_NAME: {
      return update(state, {
        client_first_name: { $set: payload },
      });
    }
    case IC_SET_CLIENT_LAST_NAME: {
      return update(state, {
        client_last_name: { $set: payload },
      });
    }
    case IC_SET_CLIENT_EMAIL: {
      return update(state, {
        client_email_address: { $set: payload },
      });
    }
    case IC_SET_CLIENT_TYPE: {
      return update(state, {
        client_type: { $set: payload },
      });
    }
    case IC_SET_CLIENT_USER_TYPE: {
      return update(state, {
        client_user_type: { $set: payload },
      });
    }
    case IC_SET_CLIENTS_LIST: {
      return update(state, {
        clients_list: { $set: payload },
      });
    }
    case IC_SET_MULTIPLE_CLIENTS_INVITATION_LIST: {
      return update(state, {
        multiple_clients_invitation_list: { $set: payload },
      });
    }
    case IC_SET_FUNDS_LIST: {
      const pafs = payload.private_access_funds;
      return update(state, {
        funds_list: {
          $set: pafs.map((fund) => ({ paf_id: fund.id, content: fund.name })),
        },
      });
    }
    case IC_SET_DEEPLINKING_FUNDS_LIST: {
      const pafs = payload.private_access_funds;
      return update(state, {
        deeplinking_funds_list: {
          $set: pafs.map((fund) => ({ paf_id: fund.id, content: fund.name })),
        },
      });
    }
    case IC_SET_ALLOW_FILES_MANAGEMENT: {
      return update(state, {
        allow_files_management: { $set: payload },
      });
    }
    case IC_SET_STORED_FILES: {
      return update(state, {
        stored_files: { $set: payload },
      });
    }
    case IC_SET_SELECTED_FUNDS: {
      return update(state, {
        selected_funds: { $set: payload },
      });
    }
    case IC_SET_DEEPLINKING_SELECTED_FUNDS: {
      return update(state, {
        deeplinking_selected_funds: { $set: payload.deeplinking_funds_list },
        selected_funds: { $set: payload.funds_list },
      });
    }
    case IC_SET_INVITE_MODAL_STATUS: {
      return update(state, {
        invite_modal_status: { $set: payload },
      });
    }
    case IC_SET_HIDE_COOLOFF_GP_ATTESTATION: {
      return update(state, {
        hide_cooloff_gp_attestation: { $set: payload },
      });
    }
    case IC_SET_INVITE_TYPE: {
      return update(state, {
        invite_type: { $set: payload },
      });
    }
    case IC_SET_ERROR: {
      return update(state, {
        error: { $set: payload },
      });
    }
    case IC_SET_MESSAGE: {
      return update(state, {
        message: { $set: payload },
      });
    }
    case IC_RESET_FORM_DATA: {
      return update(state, {
        client_first_name: { $set: initialState.client_first_name },
        client_last_name: { $set: initialState.client_last_name },
        client_email_address: { $set: initialState.client_email_address },
        client_type: { $set: initialState.client_type },
        client_user_type: { $set: initialState.client_user_type },
        selected_funds: { $set: initialState.selected_funds },
        deeplinking_selected_funds: { $set: initialState.deeplinking_selected_funds },
        pre_existing_relationship: { $set: initialState.pre_existing_relationship },
        gp_admin_cooloff_directs_attestation: { $set: initialState.gp_admin_cooloff_directs_attestation },
        gp_admin_cooloff_feeders_attestation: { $set: initialState.gp_admin_cooloff_feeders_attestation },
        error: { $set: initialState.error },
      });
    }
    case IC_RESET_INVITE_CLIENTS_DATA: {
      return {
        ...initialState,
        funds_list: state.funds_list,
        deeplinking_funds_list: state.deeplinking_funds_list,
        clients_list: [],
        clients_for_reinvitation: [],
        clients_for_reminder: [],
        confirmed_clients_for_reinvitation: [],
        confirmed_clients_for_reminder: [],
      };
    }
    case IC_SET_PRE_EXISTING_RELATIONSHIP: {
      return update(state, {
        pre_existing_relationship: { $set: payload },
      });
    }
    case IC_SET_INVITE_RESPONSE: {
      return update(state, {
        invite_response: { $set: payload },
      });
    }
    case IC_SET_CURRENT_BATCH_LABEL: {
      return update(state, {
        current_batch: { $set: payload },
      });
    }
    case IC_SET_TOTAL_BATCH_INVITES: {
      return update(state, {
        total_batch_invites: { $set: payload },
      });
    }
    case IC_SET_GP_ADMIN_COOLOFF_DIRECTS_ATTESTATION: {
      return update(state, {
        gp_admin_cooloff_directs_attestation: { $set: payload },
      });
    }
    case IC_SET_GP_ADMIN_COOLOFF_FEEDERS_ATTESTATION: {
      return update(state, {
        gp_admin_cooloff_feeders_attestation: { $set: payload },
      });
    }
    default:
      return state;
  }
};
