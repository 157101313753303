/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import update from 'immutability-helper';

import {
  RECONCILIATION_SET_SUMMARY_TOTALS,
  RECONCILIATION_SET_PERIOD,
  RECONCILIATION_SET_FUNDS,
  RECONCILIATION_SET_FUND_GROUP,
  RECONCILIATION_SET_PAFS_WITHOUT_REPORTS,
  RECONCILIATION_SET_DATA_TOTALS,
  RECONCILIATION_SET_DOCUMENT_EXPORTS,
  RECONCILIATION_SET_TAB_STATUS,
  RECONCILIATION_SET_APPROVAL_HISTORY,
  RECONCILIATION_UPDATE_ACTION_COMMENTS,
  RECONCILIATION_SET_PUBLISH_TYPES,
  RECONCILIATION_SELECTED_FUND_GROUPS,
  RECONCILIATION_SELECTED_PERIODS,
  RECONCILIATION_SELECTED_DEFAULT_PERIOD,
  RECONCILIATION_SELECTED_FUND,
  RECONCILIATION_SELECTED_PUBLISH_TYPES,
  RECONCILIATION_SET_GRID_COLUMNS_STATE,
  RECONCILIATION_SET_GRID_COLUMNS_SET_STATE,
} from 'actions/actionsConstants';

export const getExportsCount = (state) => state.document_exports.total;

export const initialState = {
  fund_groups_list: [],
  selected_fund_groups: 'all',
  period_list: [],
  selected_period: undefined,
  funds_list: [],
  selected_fund: { id: undefined },
  publish_types_list: [],
  selected_publish_type: undefined,
  selected_tab: undefined,
  columns_state: [],
  columns_set_state: [],
  current_page: 1,
  results_per_page: 25,
  fund_group_list: [],
  pafs_without_reports_list: [],
  summary_totals: {
    fund_name: 'Total',
    beginning_balance: 0,
    beginning_net: 0,
    underlying_fund: 0,
    fund_expenses_admin: 0,
    management_fee: 0,
    ending_net_capital_activity: 0,
    ending_nav: 0,
  },
  summary_total_records: 0,
  summary_total_records_approved: 0,
  dataListTotals: {
    record_totals: [],
    total_share_classes: 0,
    total_investments: 0,
  },
  document_exports: {
    exports: [],
    total: 0,
  },
  tab_status: {
    status: 'not_started',
    first_approver_finished: false,
    final_approver_kickoff: false,
    first_approver_id: undefined,
    first_approver: undefined,
    first_approver_action_id: undefined,
    first_approver_comments: undefined,
    first_approver_create_date: undefined,
    first_approver_update_date: undefined,
    final_approver_id: undefined,
    final_approver: undefined,
    final_approver_action_id: undefined,
    final_approver_comments: undefined,
    final_approver_create_date: undefined,
    final_approver_update_date: undefined,
    can_approve: false,
    was_canceled: false,
  },
  approval_history_list: [],
};

export default function navReducer(state = initialState, action) {
  switch (action.type) {
    case RECONCILIATION_SET_SUMMARY_TOTALS:
      return update(state, {
        summary_totals: { $set: action.payload.summary_totals },
        summary_total_records: { $set: action.payload.total_records },
        summary_total_records_approved: { $set: action.payload.total_records_approved },
      });
    case RECONCILIATION_SET_PERIOD:
      return update(state, {
        period_list: { $set: action.payload },
        selected_period: { $set: state.selected_period || action.payload[0]?.id },
      });
    case RECONCILIATION_SET_FUNDS:
      return update(state, {
        funds_list: { $set: action.payload },
      });
    case RECONCILIATION_SET_FUND_GROUP:
      return update(state, {
        fund_group_list: { $set: action.payload },
      });
    case RECONCILIATION_SET_PAFS_WITHOUT_REPORTS:
      return update(state, {
        pafs_without_reports_list: { $set: action.payload },
      });
    case RECONCILIATION_SET_DATA_TOTALS:
      return update(state, {
        dataListTotals: { $set: action.payload },
      });
    case RECONCILIATION_SET_DOCUMENT_EXPORTS:
      return update(state, {
        document_exports: { $set: action.payload },
      });
    case RECONCILIATION_SET_TAB_STATUS:
      return update(state, {
        tab_status: { $set: action.payload },
      });
    case RECONCILIATION_SET_APPROVAL_HISTORY:
      return update(state, {
        approval_history_list: { $set: action.payload },
      });
    case RECONCILIATION_UPDATE_ACTION_COMMENTS:
      if (action.payload.type === 'first_approval') {
        return update(state, {
          tab_status: (item) => update(item, {
            first_approver_comments: {
              $apply: () => action.payload.comments,
            },
            first_approver_update_date: {
              $apply: () => action.payload.updated_at,
            },
          }),
        });
      }
      return update(state, {
        tab_status: (item) => update(item, {
          final_approver_comments: {
            $apply: () => action.payload.comments,
          },
          final_approver_update_date: {
            $apply: () => action.payload.updated_at,
          },
        }),
      });
    case RECONCILIATION_SET_PUBLISH_TYPES:
      return update(state, {
        publish_types_list: { $set: action.payload },
      });
    case RECONCILIATION_SELECTED_FUND_GROUPS:
      return update(state, {
        selected_fund_groups: { $set: action.payload },
      });
    case RECONCILIATION_SELECTED_PERIODS:
      return update(state, {
        selected_period: { $set: action.payload },
      });
    case RECONCILIATION_SELECTED_DEFAULT_PERIOD:
      return update(state, {
        selected_period: { $set: state.period_list[0].id },
      });
    case RECONCILIATION_SELECTED_FUND:
      return update(state, {
        selected_fund: { $set: action.payload },
      });
    case RECONCILIATION_SELECTED_PUBLISH_TYPES:
      return update(state, {
        selected_publish_type: { $set: action.payload },
      });
    case RECONCILIATION_SET_GRID_COLUMNS_STATE:
      return update(state, {
        columns_state: { $set: action.payload },
      });
    case RECONCILIATION_SET_GRID_COLUMNS_SET_STATE:
      return update(state, {
        columns_set_state: { $set: action.payload },
      });
    default:
      return state;
  }
}
