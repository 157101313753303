import React from 'react';
import { func, shape } from 'prop-types';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@icapitalnetwork/supernova-core';
import { attestationsData, attestationGroups, getRoleKey } from './attestationData';

function RiaAttestation({ dataPoint, updateStore, profileData }) {
  const { questionKey, text } = dataPoint;
  return (
    <FormGroup>
      <FormControlLabel
        tietoid={questionKey}
        label={<Typography sx={{ marginTop: '10px' }}>{text}</Typography>}
        control={<Checkbox data-testid={`${questionKey}-checkbox`} checked={profileData[questionKey] || false} />}
        onChange={({ target: { checked } }) => {
          updateStore({ [questionKey]: checked });
        }}
      />
    </FormGroup>
  );
}

const AdditionalAttestations = ({ updateStore, profileData }) => {
  const roleKey = getRoleKey(profileData.role);
  return attestationGroups[roleKey].map((key) => (
    <RiaAttestation key={key} dataPoint={attestationsData[key]} updateStore={updateStore} profileData={profileData} />
  ));
};

const AdditionalRiaAttestations = ({ updateStore, profileData }) => (
  <Box sx={{ marginTop: '12px' }}>
    <AdditionalAttestations updateStore={updateStore} profileData={profileData} />
  </Box>
);

AdditionalRiaAttestations.propTypes = {
  updateStore: func.isRequired,
  profileData: shape({}).isRequired,
};
RiaAttestation.propTypes = {
  dataPoint: shape({}).isRequired,
  profileData: shape({}).isRequired,
  updateStore: func.isRequired,
};

export default AdditionalRiaAttestations;
