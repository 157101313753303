/* eslint-disable max-statements */
/* eslint-disable max-lines */
import isEqual from 'lodash/isEqual';
import intersectionWith from 'lodash/intersectionWith';
import { createAction } from 'redux-actions';
import { getRequest, postRequest } from 'actions/utils';
import { postRequest as apiPostRequest } from 'services/axiosRequest';

import {
  FF_SET_REPRESENTATIVES_FOR_FILTER,
  FF_SET_OPTIONS_FOR_FILTER,
  FF_SET_FUNDS_FOR_FILTER,
  FF_SELECTED_FUNDS,
  FF_SELECTED_PERIOD,
  FF_CURRENT_PERIOD_TYPE,
  FF_SET_CURRENT_PAGE,
  FF_SET_RESULTS_PER_PAGE,
  FF_SELECTED_PERIOD_PICKER_TYPE,
  FF_SET_IS_LOADING_FUND_DATA_FOR_FILTER,
  FF_SET_IS_LOADING_REP_DATA_FOR_FILTER,
  FF_SET_IS_LOADING_FUNDS_FOR_FILTER,
  FF_SET_IS_LOADING_DEFAULT_VIEW,
} from 'actions/actionsConstants';

const ffSetRepresentativesForFilter = createAction(FF_SET_REPRESENTATIVES_FOR_FILTER);
const ffSetOptionsForFilter = createAction(FF_SET_OPTIONS_FOR_FILTER);
const ffSetFundsForFilter = createAction(FF_SET_FUNDS_FOR_FILTER);
const ffSetSelectedFunds = createAction(FF_SELECTED_FUNDS);
const ffSetSelectedPeriod = createAction(FF_SELECTED_PERIOD);
const ffSetCurrentPeriodType = createAction(FF_CURRENT_PERIOD_TYPE);
const ffSetCurrentPage = createAction(FF_SET_CURRENT_PAGE);
const ffSetResultsPerPage = createAction(FF_SET_RESULTS_PER_PAGE);
const ffSetSelectedPeriodPickerType = createAction(FF_SELECTED_PERIOD_PICKER_TYPE);
const ffSetIsLoadingFundDataForFilter = createAction(FF_SET_IS_LOADING_FUND_DATA_FOR_FILTER);
const ffSetIsLoadingRepDataForFilter = createAction(FF_SET_IS_LOADING_REP_DATA_FOR_FILTER);
const ffSetIsLoadingFundsForFilter = createAction(FF_SET_IS_LOADING_FUNDS_FOR_FILTER);
const ffSetIsLoadingDefaultView = createAction(FF_SET_IS_LOADING_DEFAULT_VIEW);

function ffLoadRepresentativeForFilter() {
  return (dispatch) => {
    dispatch(ffSetIsLoadingRepDataForFilter(true));

    return dispatch(
      getRequest({
        url: 'fund_master/fund_representative_relationship',
        onSuccess: ffSetRepresentativesForFilter,
        showSpinner: false,
      })
    ).finally(() => dispatch(ffSetIsLoadingRepDataForFilter(false)));
  };
}

function ffLoadOptionsForFilter() {
  return (dispatch) => {
    dispatch(ffSetIsLoadingFundDataForFilter(true));

    return dispatch(
      getRequest({
        url: 'fund_master/funds',
        onSuccess: ffSetOptionsForFilter,
        showSpinner: false,
      })
    ).finally(() => dispatch(ffSetIsLoadingFundDataForFilter(false)));
  };
}

function ffLoadFundsForFilter(options = {}) {
  return (dispatch, getState) => {
    let success = false;

    if (!getState().fundFees.is_loading_funds_for_filter) {
      dispatch(ffSetIsLoadingFundsForFilter(true));
    }

    return dispatch(
      getRequest({
        url: 'private_access_funds/for_fund_fees',
        params: {
          profit_and_loss: options.profitAndLoss,
          asset_class: options.assetClass,
          representative_type: options.representativeType,
          representative: options.representative,
          fees_are_live: options.feesAreLive,
        },
        showSpinner: false,
        onSuccess: (data) => {
          dispatch(ffSetFundsForFilter(data));

          const oldSelectedFunds = getState().fundFees.selected_funds;
          const newFunds = data.length && data.map((item) => item.id);
          const updatedSelectedFunds = intersectionWith(oldSelectedFunds, newFunds, isEqual);

          if (!isEqual(oldSelectedFunds, updatedSelectedFunds)) {
            return ffSetSelectedFunds(updatedSelectedFunds);
          }

          success = true;
          return ffSetIsLoadingFundsForFilter(false);
        },
      })
    ).finally(() => {
      if (getState().fundFees.is_loading_funds_for_filter && !success) {
        dispatch(ffSetIsLoadingFundsForFilter(false));
      }
    });
  };
}

function ffLoadDashboardTotals(
  selectedFunds,
  periodStart,
  periodEnd,
  currentPeriodType,
  accrualPeriodStart,
  accrualPeriodEnd,
  filterClause,
  axiosConfig
) {
  return apiPostRequest({
    url: '/fund_fee_distributor/dashboard/totals',
    data: {
      private_access_fund_ids: selectedFunds,
      period_start: periodStart,
      period_end: periodEnd,
      period_type: currentPeriodType,
      accrual_period_start: accrualPeriodStart,
      accrual_period_end: accrualPeriodEnd,
      filter_clause: filterClause,
    },
    ...axiosConfig,
  });
}

function ffExportFundFeesDashboard(
  pafIds,
  periodStart,
  periodEnd,
  accrualPeriodStart,
  accrualPeriodEnd,
  currentPeriodType,
  filterClause,
  groupFields,
  additionalFields
) {
  return (dispatch) =>
    dispatch(
      postRequest({
        url: '/fund_fee_distributor/dashboard/export',
        data: {
          private_access_fund_ids: pafIds,
          period_start: periodStart,
          period_end: periodEnd,
          period_type: currentPeriodType,
          accrual_period_start: accrualPeriodStart,
          accrual_period_end: accrualPeriodEnd,
          filter_clause: filterClause,
          group_fields: groupFields.length > 0 ? groupFields : null,
          additional_fields: additionalFields,
        },
      })
    );
}

function ffLoadFeeDetailsForFilter(
  selectedFunds,
  periodStart,
  periodEnd,
  currentPeriodType,
  accrualPeriodStart,
  accrualPeriodEnd,
  filterClause
) {
  return apiPostRequest({
    url: '/fund_fee_distributor/dashboard',
    data: {
      private_access_fund_ids: selectedFunds,
      period_start: periodStart,
      period_end: periodEnd,
      period_type: currentPeriodType,
      accrual_period_start: accrualPeriodStart,
      accrual_period_end: accrualPeriodEnd,
      filter_clause: filterClause,
      fields: ['fee_type'],
      distinct: true,
    },
  });
}

export default {
  ffLoadRepresentativeForFilter,
  ffLoadOptionsForFilter,
  ffLoadFundsForFilter,
  ffSetSelectedFunds,
  ffSetSelectedPeriod,
  ffSetCurrentPeriodType,
  ffSetSelectedPeriodPickerType,
  ffLoadDashboardTotals,
  ffExportFundFeesDashboard,
  ffSetCurrentPage,
  ffSetResultsPerPage,
  ffSetIsLoadingFundDataForFilter,
  ffSetIsLoadingRepDataForFilter,
  ffSetIsLoadingFundsForFilter,
  ffSetIsLoadingDefaultView,
  ffLoadFeeDetailsForFilter,
};
