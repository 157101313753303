import React from 'react';
import i18n from 'i18n.js';

const BROKER_CHECK_URL = 'http://brokercheck.finra.org/';

export const USER_MANAGEMENT = 'user_management';
export const GP_TEAM_MEMBER = 'gp_team_member';
export const GP_ADMIN = 'gp_admin';
export const EDUCATION_USER = 'education_user';
export const PROFESSIONAL_DESIGNATION_CFP = 'CFP';

export const ADVISOR_ROLES = ['ria_default', 'branch_manager', 'home_office_default', 'paf_admin', 'advisor_admin'];

export const COUNTRIES_ZIP_NOT_REQUIRED = ['Hong Kong'];

export const USA = 'United States of America';

export const COUNTRIES_USA_AND_CANADA = [USA, 'Canada'];

export const FINANCIAL_ADVISOR_ROLE = i18n.t('sales_dashboard:client_types.fa', 'Financial Advisor');

export const FIRM_TYPES_WITH_REQUIRED_AUM = [
  i18n.t('registration:registered_investment_advisor', 'Registered Investment Advisor'),
  i18n.t('registration:bank_trust_company', 'Bank / Trust Company'),
];

export const registeredWithSec = {
  question_key: 'registered_with_sec',
  text: i18n.t('registration:registered_with_sec', 'Is the Firm registered with the SEC?'),
  responses: [
    { text: i18n.t('common:yes_pascal', 'Yes'), value: true },
    { text: i18n.t('common:no_pascal', 'No'), value: false },
  ],
};

export const registeredAsBDOrIA = {
  question_key: 'is_registered_bd_or_ia',
  text: 'Is your firm registered with the SEC or a state securities authority as a Broker Dealer and/or Investment Adviser?',
  options: [
    { text: 'Yes, as a Broker Dealer', value: 'registered_with_sec_broker_dealer' },
    { text: 'Yes, as an Investment Adviser', value: 'registered_with_sec_investment_adviser' },
    { text: 'Yes, as both', value: 'registered_with_sec_bd_ia' },
    { text: 'No', value: 'none' },
  ],
};

export const isRegisteredRepresentative = {
  question_key: 'is_registered_representative',
  text: 'Are you a registered representative of a Broker Dealer?',
  responses: [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ],
};

export const registeredRepresentativeSeries = {
  question_key: 'registered_representative_series',
  series_options: [
    { text: 'Yes, Series 7', chipText: 'Series 7', value: 'registered_representative_series_7' },
    { text: 'Yes, Series 6', chipText: 'Series 6', value: 'registered_representative_series_6' },
    { text: 'Yes, Series 1', chipText: 'Series 1', value: 'registered_representative_series_1' },
  ],
};

export const iaWithSeries65 = {
  question_key: 'investment_adviser_representative',
  text: 'Are you an Investment Adviser Representative holding the Series 65?',
};

export const insuranceAgent = {
  question_key: 'insurance_agent',
  text: 'Are you an Insurance Agent?',
};

export const trustOrFiduciary = {
  question_key: 'trust_or_fiduciary_division',
  text: 'Are you part of the Trust or Fiduciary Division of a bank?',
};

export const isOtherTypeInstitution = {
  question_key: 'other_institutional_investor',
  text: 'Are you another type of institutional investor?',
  responses: [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
  ],
};

export const otherTypeInstitutionalInvestor = {
  question_key: 'other_type_institutional_investor',
  institution_options: [
    { text: 'Savings and Loan Association', value: 'savings_and_loan_association' },
    { text: 'Insurance Company', value: 'insurance_company' },
    { text: 'Registered Investment Company', value: 'registered_investment_company' },
    {
      text: 'Any other entity (not a natural person) with total assets of at least $75 million',
      value: 'any_other_entity',
    },
  ],
};

export const totalAssets = {
  question_key: 'other_entity_total_assets',
  text: 'Total Assets',
};

export const firmName = {
  question_key: 'firm_name',
};

export const firmId = {
  question_key: 'firm_id',
};

export const firmType = {
  question_key: 'firm_type',
  ria_options: [
    {
      text: i18n.t('registration:registered_investment_advisor', 'Registered Investment Advisor'),
      value: 'Registered Investment Advisor',
    },
    { text: i18n.t('registration:broker_dealer', 'Broker Dealer'), value: 'Broker Dealer' },
    { text: i18n.t('registration:bank_trust_company', 'Bank / Trust Company'), value: 'Bank / Trust Company' },
    { text: i18n.t('common:other', 'Other'), value: 'Other' },
  ],
  sfo_options: [
    { text: i18n.t('sales_dashboard:single_family_office', 'Single Family Office'), value: 'Single Family Office' },
    { text: i18n.t('sales_dashboard:multi_family_office', 'Multi-Family Office'), value: 'Multi-Family Office' },
  ],
};

export const advisorCrdNumber = {
  question_key: 'advisor_crd_number',
  text: 'Please provide your Individual CRD Number',
  tooltip: (
    <span>
      {i18n.t('registration:find_crd_number', 'To find your CRD Number, visit')}
      <a
        href={BROKER_CHECK_URL}
        target="_blank"
        rel="noreferrer"
      >{` ${i18n.t('registration:finra_brokercheck', 'FINRA BrokerCheck')}`}</a>
    </span>
  ),
};

export const crdNumber = {
  question_key: 'crd_number',
};

export const firmConfirmUsLocation = {
  question_key: 'firm_confirm_us_location',
  text: i18n.t('registration:firm_based_in', 'What country are you located in?'),
};

export const firmHasQpClients = {
  question_key: 'firm_has_qp_clients',
  text: i18n.t(
    'registration:firm_has_qp_clients',
    'Does the Firm advise and/or have clients who are Qualified Purchasers and/or Accredited Investors?'
  ),
  responses: [
    { text: i18n.t('common:yes_pascal', 'Yes'), value: true },
    { text: i18n.t('common:no_pascal', 'No'), value: false },
  ],
  tooltip: i18n.t(
    'registration:firm_has_qp_clients_tooltip',
    'Qualified Purchasers as defined under the Investment Company Act of 1940; Accredited Investors as defined under Regulation D promulgated under the Securities Act of 1933'
  ),
};

export const firmAssets = {
  question_key: 'firm_assets',
  text: i18n.t('registration:firm_assets', 'Firm assets under management (approximately)'),
};

export const firmRole = {
  question_key: 'firm_role',
  role_options: [
    {
      text: i18n.t(
        'registration:role_options.financial_professionals',
        'Financial Professionals including advisor and/or person managing client relationships'
      ),
      value: 'Financial Advisor',
    },
    {
      text: i18n.t(
        'registration:role_options.chief_investment_officer',
        'Chief Investment Officer or other Investment Research Professional'
      ),
      value: 'CIO or Investment Research',
    },
    {
      text: i18n.t('registration:role_options.client_services', 'Client Services and/or Administrative Support'),
      value: 'Client Service or Admin Support',
    },
    {
      text: i18n.t(
        'registration:role_options.other_supervisory_roles',
        'Home Office, Sales Manager, Branch Manager or other Supervisory roles'
      ),
      value: 'Home Office, Sales Manager, Branch Manager or Supervisor',
    },
    { text: i18n.t('registration:role_options.operations', 'Operations'), value: 'Operations' },
    { text: i18n.t('registration:role_options.compliance', 'Compliance'), value: 'Compliance' },
    { text: i18n.t('common:other', 'Other'), value: 'Other' },
  ],
};

export const firmRoleIfOther = {
  question_key: 'role_response',
};

export const secFinancialAdvisor = {
  question_key: 'advisor_confirmed',
  text: i18n.t('registration:have_financial_advisor', 'Do you have a Financial Advisor?'),
  responses: [
    { text: i18n.t('common:yes_pascal', 'Yes'), value: true },
    { text: i18n.t('common:no_pascal', 'No'), value: false },
  ],
};

export const professionalDesignation = {
  question_key: 'professional_designation',
  label: i18n.t('registration:professional_designation.professional_designation', 'Professional Designation'),
};
