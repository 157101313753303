export default {
  funds: [],
  workflow_statuses: [],
  status_display_labels: {},
  filtered_funds: [],
  funds_total_count: 0,
  funds_firms: [],
  totals_summary: {
    pending_subscriptions: [
      {
        count: 0,
        fund_currency: '',
      },
    ],
    pending_investments: [
      {
        count: 0,
        fund_currency: '',
      },
    ],
    total_subscriptions: [
      {
        count: 0,
        fund_currency: '',
      },
    ],
    total_investments_count: 0,
    reverted: [
      {
        invs_count: 0,
        total_amount: 0,
        fund_currency: '',
      },
    ],
    pending_review: [
      {
        invs_count: 0,
        total_amount: 0,
        fund_currency: '',
      },
    ],
    pre_approval: [
      {
        invs_count: 0,
        total_amount: 0,
        fund_currency: '',
      },
    ],
    pre_approval_secondary: [
      {
        invs_count: 0,
        total_amount: 0,
        fund_currency: '',
      },
    ],
    external_review: [
      {
        invs_count: 0,
        total_amount: 0,
        fund_currency: '',
      },
    ],
    external_secondary_review: [
      {
        invs_count: 0,
        total_amount: 0,
        fund_currency: '',
      },
    ],
    ir_review: [
      {
        invs_count: 0,
        total_amount: 0,
        fund_currency: '',
      },
    ],
    compliance_review: [
      {
        invs_count: 0,
        total_amount: 0,
        fund_currency: '',
      },
    ],
    approved: [
      {
        invs_count: 0,
        total_amount: 0,
        fund_currency: '',
      },
    ],
    accepted: [
      {
        invs_count: 0,
        total_amount: 0,
        fund_currency: '',
      },
    ],
  },
  advisory_firms: [],
  search_type: 'account_name',
  search_term: '',
  selected_advisory_firms: [],
  selected_funds: [],
  selected_statuses: [],
  funds_for_filter: [],
  branch_codes: [],
  referral_codes: [],
  selected_branch_codes: [],
  selected_referral_codes: [],
  active_saved_view: null,
  selected_fund_status: [],
  selected_activity: 30,
};
