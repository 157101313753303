import {
  SUPP_FORM_ACCOUNT_INFO,
  SUPP_FORM_END_REQUEST,
  SUPP_FORM_SET_EDIT_PERMISSION,
  SUPP_FORM_SET_FORM_DATA,
  SUPP_FORM_SET_SCHEMA,
  SUPP_FORM_START_REQUEST,
  SUPP_FORM_STARTED,
  SUPP_FORM_UPDATE_END_REQUEST,
  SUPP_FORM_UPDATE_START_REQUEST,
  SUPPLEMENTAL_FORM_UNMOUNT,
} from 'actions/actionsConstants';

import update from 'immutability-helper';
import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case SUPP_FORM_START_REQUEST:
      return update(state, {
        requestsLoading: { $set: state.requestsLoading + action.payload.requestsLoading },
      });
    case SUPP_FORM_END_REQUEST:
      return update(state, {
        requestsLoading: { $set: state.requestsLoading - 1 },
      });
    case SUPP_FORM_SET_FORM_DATA:
      return update(state, {
        formData: { $merge: action.payload },
      });
    case SUPP_FORM_SET_SCHEMA:
      return update(state, {
        schema: { $set: action.payload.schema },
        uiSchema: { $set: action.payload.uiSchema },
      });
    case SUPP_FORM_STARTED:
      return update(state, {
        formStarted: { $set: action.payload },
      });
    case SUPP_FORM_ACCOUNT_INFO:
      return update(state, {
        accountName: { $set: action.payload.accountName },
        accountNum: { $set: action.payload.accountNum },
      });
    case SUPP_FORM_SET_EDIT_PERMISSION:
      return update(state, {
        canEditFrozenSuppForm: { $set: action.payload ?? true },
      });
    case SUPP_FORM_UPDATE_START_REQUEST:
      return update(state, {
        updateRequestsLoading: { $set: state.updateRequestsLoading + action.payload.updateRequestsLoading },
      });
    case SUPP_FORM_UPDATE_END_REQUEST:
      return update(state, {
        updateRequestsLoading: { $set: state.updateRequestsLoading - 1 },
      });
    case SUPPLEMENTAL_FORM_UNMOUNT:
      return initialState;
    default:
      return state;
  }
};
