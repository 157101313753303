import React from 'react';
import Stack from '@icapitalnetwork/supernova-core-v2/Stack';
import HomeDisclaimer from 'modules/landing_page/components/home/Disclaimer';
import MarketingBanner from '../../components/home/MarketingBanner/MarketingBanner';
import styles from './index.module.scss';

const HomepageMFE = () => {
  return (
    <Stack>
      <MarketingBanner className={styles.marketingBanner} />
      <div style={{ minHeight: '800px' }} id="single-spa-application:unified-homepage" />
      <HomeDisclaimer />
    </Stack>
  );
};

export default HomepageMFE;
