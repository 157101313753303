export const validateWlpName = (name) => {
  if (!name) {
    return '';
  }

  if (name.toLowerCase() === 'icapital network') {
    return 'iCapital Marketplace';
  }

  return name;
};
