/* eslint-disable max-lines-per-function */
import {
  DM_SELECT_FUND,
  DM_SELECT_FUNDS,
  DM_SELECT_WLP,
  DM_SELECT_WLPS,
  DM_SELECT_FIRM,
  DM_SELECT_FIRMS,
  DM_TOGGLE_ALL_DOCS,
  DM_SET_FUNDS,
  DM_SET_WLPS,
  DM_SET_FIRMS,
  DM_SET_DOCUMENTS,
  DM_SET_EXPIRING_DOCUMENTS,
  DM_SET_EXPIRED_DOCUMENTS,
  DM_SET_PENDING_APPROVAL,
  DM_SET_PENDING_NOTIFICATIONS,
  DM_SET_LIBRARY_DOCUMENTS,
  DM_SET_DOCUMENT_TYPES,
  DM_SET_BRANCH_CODES,
  DM_SET_FUND_GROUPS,
  DM_UPLOAD_DOCUMENT,
  DM_UPLOAD_LIBRARY_DOCUMENT,
  DM_UPDATE_DOCUMENT,
  DM_UPDATE_LIBRARY_DOCUMENT,
  DM_DELETE_DOCUMENT,
  DM_DELETE_LIBRARY_DOCUMENT,
  DM_LOADING_FUNDS,
  DM_LOADING_WLPS,
  DM_LOADING_FIRMS,
  DM_SET_DOCUMENTS_STATUS,
  DM_SET_TEMPLATE_DOCUMENTS,
  DM_UPDATE_TEMPLATE_DOCUMENT,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import {
  createDocument,
  createLibraryDocument,
  createFundDocumentTemplate,
  allWlpObj,
  allFirmsObj,
  formatWlps,
  formatFunds,
  formatFirms,
  formatBranchCodes,
  formatFundGroups,
  formatDocuments,
  formatLibraryDocuments,
  formatTemplateDocuments,
  updateDocumentStatus,
  formatDocumentTypes,
  addDocument,
  addLibraryDocument,
  addTemplateDocument,
  updateDocument,
  deleteDocument,
} from 'services/document_manager';
import { DM_UPLOAD_TEMPLATE_DOCUMENT } from '../../actions/actionsConstants';

const initialState = {
  selected_fund: null,
  selected_funds: [],
  selected_wlp: allWlpObj,
  selected_wlps: [],
  selected_firm: allFirmsObj,
  selected_firms: [],
  all_documents: true,
  funds: [],
  wlps: [],
  firms: [],
  branch_codes: [],
  funds_loading: false,
  wlps_loading: false,
  firms_loading: false,
  documents: [],
  libraryDocuments: [],
  templates: [],
  document_types: [],
  allow_row_drag: false,
  form_responses: {},
};

const allowRowDrag = (state) => state.selected_fund !== null &&
  state.selected_wlp?.value === allWlpObj.value &&
  state.selected_firm?.value === allFirmsObj.value;

export default (state = initialState, action) => {
  switch (action.type) {
    case DM_SELECT_FUND:
      return update(state, {
        selected_fund: { $set: action.payload },
        allow_row_drag: { $set: allowRowDrag({ ...state, selected_fund: action.payload }) },
      });

    case DM_SELECT_FUNDS:
      return update(state, {
        selected_funds: { $set: action.payload },
      });

    case DM_SELECT_WLP:
      return update(state, {
        selected_wlp: { $set: action.payload },
        allow_row_drag: { $set: allowRowDrag({ ...state, selected_wlp: action.payload }) },
      });

    case DM_SELECT_WLPS:
      return update(state, {
        selected_wlps: { $set: action.payload },
      });

    case DM_SELECT_FIRM:
      return update(state, {
        selected_firm: { $set: action.payload },
        allow_row_drag: { $set: allowRowDrag({ ...state, selected_firm: action.payload }) },
      });

    case DM_SELECT_FIRMS:
      return update(state, {
        selected_firms: { $set: action.payload },
      });

    case DM_TOGGLE_ALL_DOCS:
      return update(state, {
        all_documents: { $set: !state.all_documents },
      });

    case DM_SET_FUNDS:
      return update(state, { funds: { $set: formatFunds(action.payload, state.selected_fund) } });

    case DM_LOADING_FUNDS:
      return update(state, { funds_loading: { $set: action.payload } });

    case DM_SET_WLPS:
      return update(state, { wlps: { $set: formatWlps(action.payload) } });

    case DM_LOADING_WLPS:
      return update(state, { wlps_loading: { $set: action.payload } });

    case DM_SET_FIRMS:
      return update(state, { firms: { $set: formatFirms(action.payload, state.selected_firm) } });

    case DM_SET_BRANCH_CODES:
      return update(state, { branch_codes: { $set: formatBranchCodes(action.payload) } });

    case DM_SET_FUND_GROUPS:
      return update(state, { fund_groups: { $set: formatFundGroups(action.payload) } });

    case DM_LOADING_FIRMS:
      return update(state, { firms_loading: { $set: action.payload } });

    case DM_SET_DOCUMENTS:
      return update(state, { documents: { $set: formatDocuments(action.payload) } });

    case DM_SET_EXPIRING_DOCUMENTS:
      return update(state, { expiringDocuments: { $set: formatDocuments(action.payload) } });

    case DM_SET_EXPIRED_DOCUMENTS:
      return update(state, { expiredDocuments: { $set: formatDocuments(action.payload) } });

    case DM_SET_PENDING_APPROVAL:
      return update(state, { pendingApprovalDocuments: { $set: formatDocuments(action.payload) } });

    case DM_SET_PENDING_NOTIFICATIONS:
      return update(state, { pendingDocumentsNotifications: { $set: formatDocuments(action.payload) } });

    case DM_SET_LIBRARY_DOCUMENTS:
      return update(state, { libraryDocuments: { $set: formatLibraryDocuments(action.payload) } });

    case DM_SET_DOCUMENTS_STATUS:
      return update(state, { documents: { $set: updateDocumentStatus(action.payload, state.documents) } });

    case DM_SET_DOCUMENT_TYPES:
      return update(state, { document_types: { $set: formatDocumentTypes(action.payload) } });

    case DM_UPLOAD_DOCUMENT:
      return update(state, {
        documents: {
          $set: addDocument(action.payload.map((e) => createDocument(e)), state.documents, (doc) => {
            if (state.selected_fund?.value !== doc.fund.id ||
              (!state.all_documents &&
                state.selected_wlp.value !== -1 &&
                doc.whiteLabelPartner.id !== -1 &&
                state.selected_wlp.value !== doc.whiteLabelPartner.id) ||
              (!state.all_documents &&
                state.selected_firm.value !== 0 &&
                doc.firm.id !== 0 &&
                state.selected_firm.value !== doc.firm.id)) return false;
            return true;
          })
        }
      });

    case DM_SET_TEMPLATE_DOCUMENTS:
      return update(state, { templates: { $set: formatTemplateDocuments(action.payload) } });

    case DM_SET_DOCUMENTS_STATUS: {
      const documentStatus = Array.isArray(action.payload) ? action.payload : [action.payload];
      const newDocuments = [];

      state.documents.forEach((doc) => {
        const docStatus = documentStatus.find((e) => e.document_id === doc.id);
        const newDoc = doc;
        if (docStatus) {
          newDoc.status = docStatus.status;
        }
        newDocuments.push(newDoc);
      });

      return update(state, { documents: { $set: newDocuments } });
    }

    case DM_UPLOAD_DOCUMENT: {
      if (state.selected_fund?.value !== action.payload.fund.id ||
        state.selected_wlp.value !== action.payload.white_label_partner.id ||
        state.selected_firm.value !== action.payload.firm.id) {
        return state;
      }

      return update(state, { documents: { $set: addDocument(createDocument(action.payload), state.documents) } });
    }

    case DM_UPLOAD_LIBRARY_DOCUMENT:
      return update(state, {
        libraryDocuments: {
          $set: addLibraryDocument(action.payload.map((e, i) => createLibraryDocument(e, state.libraryDocuments.length + i)), state.libraryDocuments, (doc) => {
            if (state.selected_fund?.value !== doc.fund?.id) return false;
            return true;
          })
        },
      });

    case DM_UPLOAD_TEMPLATE_DOCUMENT:
      return update(state, {
        templates: { $set: addTemplateDocument(createFundDocumentTemplate(action.payload, state.templates.length), state.templates) },
      });

    case DM_UPDATE_DOCUMENT:
      return update(state, { documents: { $set: updateDocument(createDocument(action.payload), state.documents) } });

    case DM_UPDATE_LIBRARY_DOCUMENT:
      return update(state, {
        libraryDocuments: { $set: updateDocument(createLibraryDocument(action.payload, state.libraryDocuments.length), state.libraryDocuments) },
      });

    case DM_UPDATE_TEMPLATE_DOCUMENT:
      return update(state, {
        templates: { $set: updateDocument(createFundDocumentTemplate(action.payload, state.templates.length), state.templates) },
      });

    case DM_DELETE_DOCUMENT:
      return update(state, { documents: { $set: formatDocuments(action.payload.documents) } });

    case DM_DELETE_LIBRARY_DOCUMENT:
      return update(state, { libraryDocuments: { $set: deleteDocument(action.payload.doc_id, state.libraryDocuments) } });

    default:
      return state;
  }
};
