export default {
  is_loading_default_view: true,
  is_loading_rep_data_for_filter: false,
  is_loading_fund_data_for_filter: false,
  is_loading_funds_for_filter: true,
  funds: [],
  selected_funds: [],
  current_period_type: 'accruals',
  selected_period: '',
  current_page: 1,
  results_per_page: 25,
  selected_period_picker_type: 'accrual',
  representatives: [],
  options_for_filter: [],
};
