export default {
  formData: {},
  schema: {
    type: 'string',
    const: 'No schema definition available',
  },
  uiSchema: {},
  requestsLoading: 0,
  updateRequestsLoading: 0,
  formStarted: false,
  accountName: '',
  accountNum: '',
  canEditFrozenSuppForm: true,
};
