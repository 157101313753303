import { useQuery } from '@tanstack/react-query';
import { getRequest } from 'services/apiRequest';

function queryFn({ queryKey }) {
  const url = queryKey[0];
  return getRequest({ url });
}

export const useQueryRelatedInvestorProfiles = (investmentId, accountNum) => useQuery({
  queryKey: [`client_management/external_apis/investments/${investmentId}/related_investor_profiles`, accountNum],
  queryFn,
  staleTime: Infinity,
});
