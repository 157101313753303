/* eslint-disable max-lines */
// ICN Bootstrap
export const SET_ICN_BOOTSTRAP = 'SET_ICN_BOOTSTRAP';
export const ADD_REFERRAL_CODE = 'ADD_REFERRAL_CODE';
export const UPDATE_CURRENT_LANGUAGE = 'UPDATE_CURRENT_LANGUAGE';
export const UPDATE_USER_ATTRIBUTE = 'UPDATE_USER_ATTRIBUTE';

// HTTP request actions
export const BEGIN_API_REQUEST = 'BEGIN_API_REQUEST';
export const END_API_REQUEST = 'END_API_REQUEST';
export const SET_SPINNER_TEXT = 'SET_SPINNER_TEXT';
export const SET_SPINNER_DISPLAY = 'SET_SPINNER_DISPLAY';
export const FAILED_API_REQUEST = 'FAILED_API_REQUEST';

// DC Filter
export const DC_REMOVE_FILTER = 'DC_REMOVE_FILTER';
export const DC_REMOVE_ALL_FILTERS = 'DC_REMOVE_ALL_FILTERS';
export const DC_SET_FILTERS = 'DC_SET_FILTERS';
export const DC_SET_ALL_FILTERS = 'DC_SET_ALL_FILTERS';
export const CHANGE_DATE_PICKER_DATE_TYPE = 'CHANGE_DATE_PICKER_DATE_TYPE';
export const DC_SET_SEQUENCE = 'DC_SET_SEQUENCE';

// DC Filter List
export const DC_SET_DOCUMENT_FILTER_LIST = 'FETCH_DC_DOCUMENT_FILTER_LIST';
export const DC_SET_FUND_FILTER_LIST = 'FETCH_DC_FUND_FILTER_LIST';
export const DC_SET_FUND_ADMIN_GROUP_FILTER_LIST = 'FETCH_DC_FUND_ADMIN_GROUP_FILTER_LIST';
export const DC_SET_INVESTORS_FILTER_LIST = 'DC_SET_INVESTORS_FILTER_LIST';
export const DC_SET_ADVISORY_FIRM_FILTER_LIST = 'DC_SET_ADVISORY_FIRM_FILTER_LIST';
export const DC_SET_ACCOUNT_NAME_FILTER_LIST = 'FETCH_DC_ACCOUNT_NAME_FILTER_LIST';
export const DC_SET_ACCOUNT_NUMBER_FILTER_LIST = 'FETCH_DC_ACCOUNT_NUMBER_FILTER_LIST';
export const DC_SET_ADVISOR_FILTER_LIST = 'DC_SET_ADVISOR_FILTER_LIST';
export const DC_CLEAR_FILTER_LISTS = 'DC_CLEAR_FILTER_LISTS';
export const DC_SET_AUTO_SUGGEST_FILTER_LIST = 'DC_SET_AUTO_SUGGEST_FILTER_LIST';
export const DC_SET_DISPLAY_NAME_FILTER_LIST = 'DC_SET_DISPLAY_NAME_FILTER_LIST';
export const DC_SET_WHITE_LABEL_PARTNER_FILTER_LIST = 'DC_SET_WHITE_LABEL_PARTNER_FILTER_LIST';

// DC Pagination
export const DC_SET_RESULTS_PER_PAGE = 'DC_SET_RESULTS_PER_PAGE';

// DC Hot Buttons
export const DC_SET_HOT_BUTTONS = 'DC_SET_HOT_BUTTONS';
export const DC_SET_HOT_BUTTONS_CURRENT_SELECTED = 'DC_SET_HOT_BUTTONS_CURRENT_SELECTED';
export const DC_HOT_BUTTONS_CLEAR_SELECTED = 'DC_HOT_BUTTONS_CLEAR_SELECTED';

// DC Results
export const DC_SET_RESULTS = 'DC_SET_RESULTS';
export const DC_SET_NOTE_RESULT = 'DC_SET_NOTE_RESULT';
export const DC_SET_VIEW_TABLE_OPERATION_DOCS = 'DC_SET_VIEW_TABLE_OPERATION_DOCS';
export const DC_SET_PAGE = 'DC_SET_PAGE';

export const DC_FAILED_API_REQUEST = 'DC_FAILED_API_REQUEST';
export const DC_SET_IMPERSONATE_USER_EMAIL = 'DC_SET_IMPERSONATE_USER_EMAIL';

export const DC_SET_IMPERSONATE_USER_ID = 'DC_SET_IMPERSONATE_USER_ID';
export const DC_SET_LOGIN_FETCHING = 'DC_SET_LOGIN_FETCHING';
export const DC_FETCH_LOGIN_AUTO_SUGGEST = 'DC_FETCH_LOGIN_AUTO_SUGGEST';
export const DC_SET_SORT = 'DC_SET_SORT';

export const DC_CLEAR_SELECTED_DOCUMENTS = 'DC_CLEAR_SELECTED_DOCUMENTS';
export const DC_CLEAR_UNSELECTED_DOCUMENTS = 'DC_CLEAR_UNSELECTED_DOCUMENTS';
export const DC_SET_SELECTED_DOCUMENTS = 'DC_SET_SELECTED_DOCUMENTS';
export const DC_SET_UNSELECTED_DOCUMENTS = 'DC_SET_UNSELECTED_DOCUMENTS';
export const DC_CLEAR_ALL_SELECTIONS = 'DC_CLEAR_ALL_SELECTIONS';
export const DC_TOGGLE_SELECT_ALL = 'DC_TOGGLE_SELECT_ALL';
export const DC_TOGGLE_DOWNLOAD_ERROR = 'DC_TOGGLE_DOWNLOAD_ERROR';
export const DC_SET_MAX_FILES_LIMIT = 'DC_SET_MAX_FILES_LIMIT';

export const DC_SET_IS_FETCHING = 'DC_SET_IS_FETCHING';

export const DC_SET_IS_TABLE_FETCHING = 'DC_SET_IS_TABLE_FETCHING';

// DC Saved Views
export const DC_SET_SAVED_VIEWS = 'DC_SET_SAVED_VIEWS';
export const DC_SET_SAVED_VIEWS_SORT = 'DC_SET_SAVED_VIEWS_SORT';
export const DC_SET_ACTIVE_SAVED_VIEW = 'DC_SET_ACTIVE_SAVED_VIEW';

// DC Bulk Download
export const DC_DELETE_INTERVAL = 'DC_DELETE_INTERVAL';
export const DC_NEW_DOWNLOAD_AVAILABLE = 'DC_NEW_DOWNLOAD_AVAILABLE';
export const DC_CREATE_INTERVAL = 'DC_CREATE_INTERVAL';
export const DC_CLEAR_DOWNLOAD_AVAILABLE = 'DC_CLEAR_DOWNLOAD_AVAILABLE';

// DC Uploader
export const DC_SET_FORM_INFO = 'DC_SET_FORM_INFO';
export const DC_UPLOAD_DOCUMENT = 'DC_UPLOAD_DOCUMENT';

// IP General
export const FETCH_INVESTOR_PROFILE = 'FETCH_INVESTOR_PROFILE';
export const SET_INVESTOR_PROFILE = 'SET_INVESTOR_PROFILE';
export const SET_CUSTODIANS = 'SET_CUSTODIANS';
export const SET_PROFILE_FIELDS = 'SET_PROFILE_FIELDS';
export const IP_SET_INITIAL_VALUES = 'IP_SET_INITIAL_VALUES';
export const SET_HAS_CUSTODIAN = 'SET_HAS_CUSTODIAN';
export const SET_BATCH_UPDATE_OPTIONS = 'SET_BATCH_UPDATE_OPTIONS';
export const SET_PROFILE_COUNTRIES = 'SET_PROFILE_COUNTRIES';
export const SET_CONDITIONAL_COUNTRIES = 'SET_CONDITIONAL_COUNTRIES';
export const SET_PROFILE_COUNTRY_BACKUP = 'SET_PROFILE_COUNTRY_BACKUP';
export const SET_PAF_EXCEPTION_APPLIES = 'SET_PAF_EXCEPTION_APPLIES';
export const FETCH_INVESTOR_PROFILE_COMPLETENESS = 'FETCH_INVESTOR_PROFILE_COMPLETENESS';
export const SET_INVESTOR_PROFILE_COMPLETENESS = 'SET_INVESTOR_PROFILE_COMPLETENESS';
export const SET_INVESTOR_PROFILE_SIGNER_TYPES = 'SET_INVESTOR_PROFILE_SIGNER_TYPES';
export const SET_INVESTOR_PROFILE_VISIBILITY = 'SET_INVESTOR_PROFILE_VISIBILITY';
export const WHITE_LABEL_SIDE_EFFECTS = 'WHITE_LABEL_SIDE_EFFECTS';
export const RAYMOND_JAMES_SIDE_EFFECTS = 'RAYMOND_JAMES_SIDE_EFFECTS';
export const SET_INBOUND_ANGULAR_QUERY_PARAMS = 'SET_INBOUND_ANGULAR_QUERY_PARAMS';
export const SET_PROFILE_WLP_CONFIG = 'SET_PROFILE_WLP_CONFIG';
export const SET_SIGNERS_BACKUP = 'SET_SIGNERS_BACKUP';
export const SET_INTERESTED_PARTIES_BACKUP = 'SET_INTERESTED_PARTIES_BACKUP';

// IP Errors
export const SET_PROFILE_ERRORS = 'SET_PROFILE_ERRORS';
export const RESET_CUSTODIAN_ACCOUNT_ERRORS = 'RESET_CUSTODIAN_ACCOUNT_ERRORS';
export const SET_INTERESTED_PARTIES_ERRORS = 'SET_INTERESTED_PARTIES_ERRORS';
export const SET_CUSTODIAN_ACCOUNT_ERRORS = 'SET_CUSTODIAN_ACCOUNT_ERRORS';
export const SET_SIGNERS_ERRORS = 'SET_SIGNERS_ERRORS';
export const CHECK_AND_SET_API_ERRORS = 'CHECK_AND_SET_API_ERRORS';
export const REMOVE_SIGNER_ERROR = 'REMOVE_SIGNER_ERROR';
export const RESET_SIGNER_ERRORS = 'RESET_SIGNER_ERRORS';
export const ADD_SIGNER_ERROR = 'ADD_SIGNER_ERROR';

// IP New Issues
export const SAVE_NEW_ISSUES_PROFILE = 'SAVE_NEW_ISSUES_PROFILE';
export const SET_CHOOSE_TO_PARTICIPATE = 'SET_CHOOSE_TO_PARTICIPATE';
export const SET_NEW_ISSUES_QUESTIONS = 'SET_NEW_ISSUES_QUESTIONS';
export const SET_NEW_ISSUES_NONE_APPLY = 'SET_NEW_ISSUES_NONE_APPLY';
export const SET_NEW_ISSUES_DEFAULT = 'SET_NEW_ISSUES_DEFAULT';

// IP Custodian Account
export const FETCH_CUSTODIANS_FOR_CONTACT_CARD = 'FETCH_CUSTODIANS_FOR_CONTACT_CARD';
export const SET_CUSTODIAN_ACCOUNT_FIELD = 'SET_CUSTODIAN_ACCOUNT_FIELD';
export const RESET_CUSTODIAN_ACCOUNT = 'RESET_CUSTODIAN_ACCOUNT';
export const SET_CURRENT_CUSTODIAN = 'SET_CURRENT_CUSTODIAN';

// IP Interested Parties
export const ADD_INTERESTED_PARTY = 'ADD_INTERESTED_PARTY';
export const REMOVE_INTERESTED_PARTY = 'REMOVE_INTERESTED_PARTY';
export const UPDATE_INTERESTED_PARTY = 'UPDATE_INTERESTED_PARTY';
export const RESET_INTERESTED_PARTY_ERRORS = 'RESET_INTERESTED_PARTY_ERRORS';
export const REMOVE_ALL_INTERESTED_PARTIES = 'REMOVE_ALL_INTERESTED_PARTIES';
export const RESET_INTERESTED_PARTIES_ERRORS = 'RESET_INTERESTED_PARTIES_ERRORS';

// IP Signers
export const ADD_SIGNER = 'ADD_SIGNER';
export const REMOVE_SIGNER = 'REMOVE_SIGNER';
export const UPDATE_SIGNER = 'UPDATE_SIGNER';

// IP Cleanup
export const INVESTOR_PROFILE_UNMOUNT = 'INVESTOR_PROFILE_UNMOUNT';

// Fund Admin
export const FUND_ADMIN_SET_FUNDS = 'FUND_ADMIN_SET_FUNDS';
export const FUND_ADMIN_SET_MENU = 'FUND_ADMIN_SET_MENU';
export const FUND_ADMIN_SET_HISTORY = 'FUND_ADMIN_SET_HISTORY';
export const FUND_ADMIN_MENU_IS_LOADING = 'FUND_ADMIN_MENU_IS_LOADING';
export const FUND_ADMIN_SET_BNY_FUNDS = 'FUND_ADMIN_SET_BNY_FUNDS';

export const CAPITAL_EVENTS_SET_GRID_FETCHING = 'CAPITAL_EVENTS_SET_GRID_FETCHING';
export const CAPITAL_EVENTS_UNSET_GRID_FETCHING = 'CAPITAL_EVENTS_UNSET_GRID_FETCHING';
export const CAPITAL_EVENTS_SET_MODAL_FETCHING = 'CAPITAL_EVENTS_SET_MODAL_FETCHING';
export const CAPITAL_EVENTS_UNSET_MODAL_FETCHING = 'CAPITAL_EVENTS_UNSET_MODAL_FETCHING';

export const CAPITAL_EVENTS_SET_PRIVATE_ACCESS_FUNDS = 'CAPITAL_EVENTS_SET_PRIVATE_ACCESS_FUNDS';
export const CAPITAL_EVENTS_OPEN_NOTICE_EDITOR = 'CAPITAL_EVENTS_OPEN_NOTICE_EDITOR';
export const CAPITAL_EVENTS_CLOSE_NOTICE_EDITOR = 'CAPITAL_EVENTS_CLOSE_NOTICE_EDITOR';
export const CAPITAL_EVENTS_UPDATE_GROUPS = 'CAPITAL_EVENTS_UPDATE_GROUPS';
export const CAPITAL_EVENTS_OPEN_CREATE_FORM = 'CAPITAL_EVENTS_OPEN_CREATE_FORM';
export const CAPITAL_EVENTS_CLOSE_CREATE_FORM = 'CAPITAL_EVENTS_CLOSE_CREATE_FORM';
export const CAPITAL_EVENTS_OPEN_EDIT_FORM = 'CAPITAL_EVENTS_OPEN_EDIT_FORM';
export const CAPITAL_EVENTS_CLOSE_EDIT_FORM = 'CAPITAL_EVENTS_CLOSE_EDIT_FORM';
export const CAPITAL_EVENTS_OPEN_EXPENSES_MODAL = 'CAPITAL_EVENTS_OPEN_EXPENSES_MODAL';
export const CAPITAL_EVENTS_CLOSE_EXPENSES_MODAL = 'CAPITAL_EVENTS_CLOSE_EXPENSES_MODAL';

export const CAPITAL_EVENTS_OPEN_REMINDER_MODAL = 'CAPITAL_EVENTS_OPEN_REMINDER_MODAL';
export const CAPITAL_EVENTS_CLOSE_REMINDER_MODAL = 'CAPITAL_EVENTS_CLOSE_REMINDER_MODAL';

export const CAPITAL_EVENTS_OPEN_PAST_DUE_MODAL = 'CAPITAL_EVENTS_OPEN_PAST_DUE_MODAL';
export const CAPITAL_EVENTS_CLOSE_PAST_DUE_MODAL = 'CAPITAL_EVENTS_CLOSE_PAST_DUE_MODAL';

export const CAPITAL_EVENTS_SET_EXECUTE_INFO = 'CAPITAL_EVENTS_SET_EXECUTE_INFO';

export const CAPITAL_EVENTS_OPEN_STATUS_REPORT_MODAL = 'CAPITAL_EVENTS_OPEN_STATUS_REPORT_MODAL';
export const CAPITAL_EVENTS_CLOSE_STATUS_REPORT_MODAL = 'CAPITAL_EVENTS_CLOSE_STATUS_REPORT_MODAL';
export const CAPITAL_EVENTS_SET_STATUS_REPORTS = 'CAPITAL_EVENTS_SET_STATUS_REPORTS';

export const CAPITAL_EVENTS_OPEN_CUSTODIAN_NOTICES = 'CAPITAL_EVENTS_OPEN_CUSTODIAN_NOTICES';
export const CAPITAL_EVENTS_CLOSE_CUSTODIAN_NOTICES = 'CAPITAL_EVENTS_CLOSE_CUSTODIAN_NOTICES';
export const CAPITAL_EVENTS_SET_CUSTODIAN_NOTICES = 'CAPITAL_EVENTS_SET_CUSTODIAN_NOTICES';

export const CAPITAL_EVENTS_OPEN_NOMINEE_NOTICES = 'CAPITAL_EVENTS_OPEN_NOMINEE_NOTICES';
export const CAPITAL_EVENTS_CLOSE_NOMINEE_NOTICES = 'CAPITAL_EVENTS_CLOSE_NOMINEE_NOTICES';
export const CAPITAL_EVENTS_SET_NOMINEE_NOTICES = 'CAPITAL_EVENTS_SET_NOMINEE_NOTICES';

export const CAPITAL_EVENTS_OPEN_EXECUTE_MODAL = 'CAPITAL_EVENTS_OPEN_EXECUTE_MODAL';
export const CAPITAL_EVENTS_CLOSE_EXECUTE_MODAL = 'CAPITAL_EVENTS_CLOSE_EXECUTE_MODAL';

export const CAPITAL_EVENTS_OPEN_FIRM_REPORTS = 'CAPITAL_EVENTS_OPEN_FIRM_REPORTS';
export const CAPITAL_EVENTS_CLOSE_FIRM_REPORTS = 'CAPITAL_EVENTS_CLOSE_FIRM_REPORTS';
export const CAPITAL_EVENTS_SET_FIRM_REPORTS = 'CAPITAL_EVENTS_SET_FIRM_REPORTS';

export const CAPITAL_EVENTS_OPEN_DISTRIBUTION_REPORTS_MODAL = 'CAPITAL_EVENTS_OPEN_DISTRIBUTION_REPORTS_MODAL';
export const CAPITAL_EVENTS_CLOSE_DISTRIBUTION_REPORTS_MODAL = 'CAPITAL_EVENTS_CLOSE_DISTRIBUTION_REPORTS_MODAL';
export const CAPITAL_EVENTS_SET_DISTRIBUTION_REPORTS = 'CAPITAL_EVENTS_SET_DISTRIBUTION_REPORTS';

export const CAPITAL_EVENTS_OPEN_DELETE_MODAL = 'CAPITAL_EVENTS_OPEN_DELETE_MODAL';
export const CAPITAL_EVENTS_CLOSE_DELETE_MODAL = 'CAPITAL_EVENTS_CLOSE_DELETE_MODAL';

export const CAPITAL_EVENTS_SET_RECONCILIATION_EXPORTS = 'CAPITAL_EVENTS_SET_RECONCILIATION_EXPORTS';
export const CAPITAL_EVENTS_OPEN_RECONCILIATION_MODAL = 'CAPITAL_EVENTS_OPEN_RECONCILIATION_MODAL';
export const CAPITAL_EVENTS_CLOSE_RECONCILIATION_MODAL = 'CAPITAL_EVENTS_CLOSE_RECONCILIATION_MODAL';

export const CAPITAL_EVENTS_SET_FILTER_DATE_LIST = 'CAPITAL_EVENTS_SET_FILTER_DATE_LIST';
export const CAPITAL_EVENTS_SET_SELECTED_FILTER_DATE = 'CAPITAL_EVENTS_SET_SELECTED_FILTER_DATE';
export const CAPITAL_EVENTS_SET_FILTER_WHITE_LABEL_PARTNERS_LIST =
  'CAPITAL_EVENTS_SET_FILTER_WHITE_LABEL_PARTNERS_LIST';
export const CAPITAL_EVENTS_SET_SELECTED_FILTER_WHITE_LABEL_PARTNER =
  'CAPITAL_EVENTS_SET_SELECTED_FILTER_WHITE_LABEL_PARTNER';
export const CAPITAL_EVENTS_SET_FILTER_OPTIONS = 'CAPITAL_EVENTS_SET_FILTER_OPTIONS';
export const CAPITAL_EVENTS_SET_SELECTED_FILTER_TEXT = 'CAPITAL_EVENTS_SET_SELECTED_FILTER_TEXT';
export const CAPITAL_EVENTS_SET_SELECTED_FILTER_OPTION = 'CAPITAL_EVENTS_SET_SELECTED_FILTER_OPTION';
export const CAPITAL_EVENTS_SET_SELECTED_GROUP_BY_CLOSE = 'CAPITAL_EVENTS_SET_SELECTED_GROUP_BY_CLOSE';
export const CAPITAL_EVENTS_SET_SELECTED_FILTER_UNRECONCILED = 'CAPITAL_EVENTS_SET_SELECTED_FILTER_UNRECONCILED';
export const CAPITAL_EVENTS_SET_UF_DATES = 'CAPITAL_EVENTS_SET_UF_DATES';
export const CAPITAL_EVENTS_SET_UF_DUE_DATE = 'CAPITAL_EVENTS_SET_UF_DUE_DATE';
export const CAPITAL_EVENTS_SET_UF_RECEIVED_DATE = 'CAPITAL_EVENTS_SET_UF_RECEIVED_DATE';
export const CAPITAL_EVENTS_SET_SELECTED_EVENT = 'CAPITAL_EVENTS_SET_SELECTED_EVENT';

export const CAPITAL_EVENTS_OPEN_REJECT_MODAL = 'CAPITAL_EVENTS_OPEN_REJECT_MODAL';
export const CAPITAL_EVENTS_CLOSE_REJECT_MODAL = 'CAPITAL_EVENTS_CLOSE_REJECT_MODAL';

export const CAPITAL_EVENTS_OPEN_UPLOAD_FORM = 'CAPITAL_EVENTS_OPEN_UPLOAD_FORM';
export const CAPITAL_EVENTS_CLOSE_UPLOAD_FORM = 'CAPITAL_EVENTS_CLOSE_UPLOAD_FORM';

export const CAPITAL_EVENTS_OPEN_RESET_MODAL = 'CAPITAL_EVENT_OPEN_RESET_MODAL';
export const CAPITAL_EVENTS_CLOSE_RESET_MODAL = 'CAPITAL_EVENT_CLOSE_RESET_MODAL';
export const CAPITAL_EVENTS_SET_FINANCE_OWNER_OPTIONS = 'CAPITAL_EVENTS_SET_FINANCE_OWNER_OPTIONS';
export const CAPITAL_EVENTS_SET_UNDERLYING_FUNDS = 'CAPITAL_EVENTS_SET_UNDERLYING_FUNDS';
export const CAPITAL_EVENTS_SET_UNDERLYING_CAPITAL_EVENTS = 'CAPITAL_EVENTS_SET_UNDERLYING_CAPITAL_EVENTS';

export const CAPITAL_EVENTS_OPEN_RESEND_FAILED_NOTICE_MODAL = 'CAPITAL_EVENTS_OPEN_RESEND_FAILED_NOTICE_MODAL';
export const CAPITAL_EVENTS_CLOSE_RESEND_FAILED_NOTICE_MODAL = 'CAPITAL_EVENTS_CLOSE_RESEND_FAILED_NOTICE_MODAL';

export const CAPITAL_EVENTS_OPEN_RESEND_EVENT_NOTICE_MODAL = 'CAPITAL_EVENTS_OPEN_RESEND_EVENT_NOTICE_MODAL';
export const CAPITAL_EVENTS_CLOSE_RESEND_EVENT_NOTICE_MODAL = 'CAPITAL_EVENTS_CLOSE_RESEND_EVENT_NOTICE_MODAL';

export const CAPITAL_EVENTS_OPEN_UPLOAD_UPDATE_MODAL = 'CAPITAL_EVENTS_OPEN_UPLOAD_UPDATE_MODAL';
export const CAPITAL_EVENTS_CLOSE_UPLOAD_UPDATE_MODAL = 'CAPITAL_EVENTS_CLOSE_UPLOAD_UPDATE_MODAL';

export const CAPITAL_EVENTS_OPEN_UPLOAD_RECONCILIATION_MODAL = 'CAPITAL_EVENTS_OPEN_UPLOAD_RECONCILIATION_MODAL';
export const CAPITAL_EVENTS_CLOSE_UPLOAD_RECONCILIATION_MODAL = 'CAPITAL_EVENTS_CLOSE_UPLOAD_RECONCILIATION_MODAL';

export const CAPITAL_EVENTS_OPEN_EDIT_FROM_FILE_FORM = 'CAPITAL_EVENTS_OPEN_EDIT_FROM_FILE_FORM';
export const CAPITAL_EVENTS_CLOSE_EDIT_FROM_FILE_FORM = 'CAPITAL_EVENTS_CLOSE_EDIT_FROM_FILE_FORM';
export const CAPITAL_EVENTS_SET_SUMMARY = 'CAPITAL_EVENTS_SET_SUMMARY';
export const CAPITAL_EVENTS_CLEAR_SUMMARIES = 'CAPITAL_EVENTS_CLEAR_SUMMARIES';

export const CAPITAL_EVENTS_OPEN_UF_MODAL = 'CAPITAL_EVENTS_OPEN_UF_MODAL';
export const CAPITAL_EVENTS_CLOSE_UF_MODAL = 'CAPITAL_EVENTS_CLOSE_UF_MODAL';

export const CAPITAL_EVENTS_OPEN_NOTES_MODAL = 'CAPITAL_EVENTS_OPEN_NOTES_MODAL';
export const CAPITAL_EVENTS_CLOSE_NOTES_MODAL = 'CAPITAL_EVENTS_CLOSE_NOTES_MODAL';
export const CAPITAL_EVENTS_SET_NOTES = 'CAPITAL_EVENTS_SET_NOTES';

export const CAPITAL_EVENTS_OPEN_SUBMIT_APPROVAL_MODAL = 'CAPITAL_EVENTS_OPEN_SUBMIT_APPROVAL_MODAL';
export const CAPITAL_EVENTS_CLOSE_SUBMIT_APPROVAL_MODAL = 'CAPITAL_EVENTS_CLOSE_SUBMIT_APPROVAL_MODAL';
export const CAPITAL_EVENTS_SET_PAF_CHARGED_FEE_QUARTERS = 'CAPITAL_EVENTS_SET_PAF_CHARGED_FEE_QUARTERS';
export const CAPITAL_EVENTS_SET_MANAGEMENT_FEES = 'CAPITAL_EVENTS_SET_MANAGEMENT_FEES';
export const CAPITAL_EVENTS_FETCHING_MENU_OPTIONS = 'CAPITAL_EVENTS_FETCHING_MENU_OPTIONS';
export const CAPITAL_EVENTS_SET_MENU_OPTIONS = 'CAPITAL_EVENTS_SET_MENU_OPTIONS';
export const CAPITAL_EVENTS_OPEN_UNPUBLISH_NOTICE_MODAL = 'CAPITAL_EVENTS_OPEN_UNPUBLISH_NOTICE_MODAL';
export const CAPITAL_EVENTS_OPEN_RESEND_ITEM_NOTICE_MODAL = 'CAPITAL_EVENTS_OPEN_RESEND_ITEM_NOTICE_MODAL';
export const CAPITAL_EVENTS_CLOSE_UNPUBLISH_NOTICE_MODAL = 'CAPITAL_EVENTS_CLOSE_UNPUBLISH_NOTICE_MODAL';
export const CAPITAL_EVENTS_CLOSE_RESEND_ITEM_NOTICE_MODAL = 'CAPITAL_EVENTS_CLOSE_RESEND_ITEM_NOTICE_MODAL';
export const CAPITAL_EVENTS_OPEN_INITIAL_DEFAULT_MODAL = 'CAPITAL_EVENTS_OPEN_INITIAL_DEFAULT_MODAL';
export const CAPITAL_EVENTS_CLOSE_INITIAL_DEFAULT_MODAL = 'CAPITAL_EVENTS_CLOSE_INITIAL_DEFAULT_MODAL';
export const CAPITAL_EVENTS_OPEN_FINAL_DEFAULT_MODAL = 'CAPITAL_EVENTS_OPEN_FINAL_DEFAULT_MODAL';
export const CAPITAL_EVENTS_CLOSE_FINAL_DEFAULT_MODAL = 'CAPITAL_EVENTS_CLOSE_FINAL_DEFAULT_MODAL';
export const CAPITAL_EVENTS_OPEN_UNPUBLISH_EVENT_NOTICES_MODAL = 'CAPITAL_EVENTS_OPEN_UNPUBLISH_EVENT_NOTICES_MODAL';
export const CAPITAL_EVENTS_CLOSE_UNPUBLISH_EVENT_NOTICES_MODAL = 'CAPITAL_EVENTS_CLOSE_UNPUBLISH_EVENT_NOTICES_MODAL';
export const CAPITAL_EVENTS_DISABLE_GRID_MENU = 'CAPITAL_EVENTS_DISABLE_GRID_MENU';
export const CAPITAL_EVENTS_ENABLE_GRID_MENU = 'CAPITAL_EVENTS_ENABLE_GRID_MENU';
export const CAPITAL_EVENTS_SET_UF_FUNDS_FETCHING = 'CAPITAL_EVENTS_SET_UF_FUNDS_FETCHING';
export const CAPITAL_EVENTS_UNSET_UF_FUNDS_FETCHING = 'CAPITAL_EVENTS_UNSET_UF_FUNDS_FETCHING';
export const CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DRAWER =
  'CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DRAWER';
export const CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DRAWER =
  'CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DRAWER';
export const CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL =
  'CAPITAL_EVENTS_OPEN_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL';
export const CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL =
  'CAPITAL_EVENTS_CLOSE_UNDERLYING_CAPITAL_EVENT_DELETE_MODAL';

export const FUND_ADMIN_UPLOADER_SET_RESULT_DOCUMENTS = 'FUND_ADMIN_UPLOADER_SET_RESULT_DOCUMENTS';
export const FUND_ADMIN_NAV_UPLOADER_SET_RESULT_DOCUMENTS = 'FUND_ADMIN_NAV_UPLOADER_SET_RESULT_DOCUMENTS';
export const FUND_ADMIN_NAV_UPLOADER_REMOVE_RESULT_DOCUMENT = 'FUND_ADMIN_NAV_UPLOADER_REMOVE_RESULT_DOCUMENT';
export const FUND_ADMIN_UPLOADER_REFRESH_PAGE = 'FUND_ADMIN_UPLOADER_REFRESH_PAGE';
export const FUND_ADMIN_NAV_UPLOADER_SET_FUNDS = 'FUND_ADMIN_NAV_UPLOADER_SET_FUNDS';
export const FUND_ADMIN_UPLOADER_SET_HF_REPORT = 'FUND_ADMIN_UPLOADER_SET_HF_REPORT';

// CAPITAL EVENTS CASH FUNDING
export const CE_CASH_FUNDING_CUSTODIAN_LIST = 'CE_CASH_FUNDING_CUSTODIAN_LIST';
export const CE_CASH_FUNDING_FIRM_LIST = 'CE_CASH_FUNDING_FIRM_LIST';
export const CE_CASH_FUNDING_SET_SELECTED_CUSTODIANS = 'CE_CASH_FUNDING_SET_SELECTED_CUSTODIANS';
export const CE_CASH_FUNDING_SET_SELECTED_FIRMS = 'CE_CASH_FUNDING_SET_SELECTED_FIRMS';
export const CE_CASH_FUNDING_MODAL_OPEN = 'CE_CASH_FUNDING_MODAL_OPEN';
export const CE_CASH_FUNDING_MODAL_CLOSE = 'CE_CASH_FUNDING_MODAL_CLOSE';
export const CE_CASH_FUNDING_SET_SELECTED_ITEMS_TO_MODAL = 'CE_CASH_FUNDING_SET_SELECTED_ITEMS_TO_MODAL';
export const CE_CASH_FUNDING_SET_MODAL_SELECTED_CUSTODIANS = 'CE_CASH_FUNDING_SET_MODAL_SELECTED_CUSTODIANS';
export const CE_CASH_FUNDING_SET_MODAL_SELECTED_FIRMS = 'CE_CASH_FUNDING_SET_MODAL_SELECTED_FIRMS';
export const CE_CASH_FUNDING_SET_DETAILS = 'CE_CASH_FUNDING_SET_DETAILS';
export const CE_CASH_FUNDING_UPDATE_DETAILS = 'CE_CASH_FUNDING_UPDATE_DETAILS';
export const CE_CASH_FUNDING_EXPORT_DOCS_LIST = 'CE_CASH_FUNDING_EXPORT_DOCS_LIST';
export const CE_CASH_FUNDING_EVENTS_LIST = 'CE_CASH_FUNDING_EVENTS_LIST';
export const CE_CASH_FUNDING_REFRESH_GRID_INFO = 'CE_CASH_FUNDING_REFRESH_GRID_INFO';
export const CE_CASH_FUNDING_UPLOAD_RESULTS = 'CE_CASH_FUNDING_UPLOAD_RESULTS';
export const CE_CASH_FUNDING_BUCKETS_COUNT = 'CE_CASH_FUNDING_BUCKETS_COUNT';
export const CE_CASH_FUNDING_RESET_WIRE_AMOUNT_INPUT = 'CE_CASH_FUNDING_RESET_WIRE_AMOUNT_INPUT';
export const CE_CASH_FUNDING_CAPITAL_EVENT_DETAILS = 'CE_CASH_FUNDING_CAPITAL_EVENT_DETAILS';
export const CE_CASH_FUNDING_CAPITAL_EVENT_SELECT_ALL = 'CE_CASH_FUNDING_CAPITAL_EVENT_SELECT_ALL';
export const CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT = 'CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT';
export const CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT_REMOVE = 'CE_CASH_FUNDING_CAPITAL_EVENT_BATCH_SELECT_REMOVE';
export const CE_CASH_FUNDING_CAPITAL_EVENT_CURRENT_TABLE_SELECT = 'CE_CASH_FUNDING_CAPITAL_EVENT_CURRENT_TABLE_SELECT';
export const CE_CASH_FUNDING_SET_FILTER_OPTIONS = 'CE_CASH_FUNDING_SET_FILTER_OPTIONS';

// PCAPS
export const PCAPS_SET_RESULTS = 'PCAPS_SET_RESULTS';
export const PREVIEW_SET_RESULTS = 'PREVIEW_SET_RESULTS';
export const PREVIEW_TOTALS_SET_RESULTS = 'PREVIEW_TOTALS_SET_RESULTS';
export const PREVIEW_UPDATE_RESULT = 'PREVIEW_UPDATE_RESULT';
export const TOTAL_DOCS_PUBLISHED_UPDATE_RESULT = 'TOTAL_DOCS_PUBLISHED_UPDATE_RESULT';
export const TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT = 'TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT';
export const TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT = 'TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT';
export const SET_DOC_CENTER_SYNC_TO_FALSE = 'SET_DOC_CENTER_SYNC_TO_FALSE';

// NAVS
export const NAVS_SET_RESULTS = 'NAVS_SET_RESULTS';
export const NAV_PREVIEW_SET_RESULTS = 'NAV_PREVIEW_SET_RESULTS';
export const NAV_PREVIEW_TOTALS_SET_RESULTS = 'NAV_PREVIEW_TOTALS_SET_RESULTS';
export const NAV_PREVIEW_UPDATE_RESULT = 'NAV_PREVIEW_UPDATE_RESULT';
export const NAV_TOTAL_DOCS_PUBLISHED_UPDATE_RESULT = 'NAV_TOTAL_DOCS_PUBLISHED_UPDATE_RESULT';
export const NAV_TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT = 'NAV_TOTAL_DOCS_PUBLISHED_PLUS_UPDATE_RESULT';
export const NAV_TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT = 'NAV_TOTAL_DOCS_PUBLISHED_MINUS_UPDATE_RESULT';

// Statements
export const STATEMENTS_SET_DOCUMENT_EXPORTS = 'STATEMENTS_SET_DOCUMENT_EXPORTS';
export const STATEMENTS_SET_RESULTS_PER_PAGE = 'STATEMENTS_SET_RESULTS_PER_PAGE';
export const STATEMENTS_SET_SELECTED = 'STATEMENTS_SET_SELECTED';
export const STATEMENTS_SET_INVESTORS = 'STATEMENTS_SET_INVESTORS';

// Reconciliation
export const RECONCILIATION_SET_SUMMARY_TOTALS = 'RECONCILIATION_SET_SUMMARY_TOTALS';
export const RECONCILIATION_SET_PERIOD = 'RECONCILIATION_SET_PERIOD';
export const RECONCILIATION_SET_FUNDS = 'RECONCILIATION_SET_FUNDS';
export const RECONCILIATION_SET_FUND_GROUP = 'RECONCILIATION_SET_FUND_GROUP';
export const RECONCILIATION_SET_PAFS_WITHOUT_REPORTS = 'RECONCILIATION_SET_PAFS_WITHOUT_REPORTS';
export const RECONCILIATION_SET_DATA_TOTALS = 'RECONCILIATION_SET_DATA_TOTALS';
export const RECONCILIATION_SET_DOCUMENT_EXPORTS = 'RECONCILIATION_SET_DOCUMENT_EXPORTS';
export const RECONCILIATION_SET_TAB_STATUS = 'RECONCILIATION_SET_TAB_STATUS';
export const RECONCILIATION_SET_APPROVAL_HISTORY = 'RECONCILIATION_SET_APPROVAL_HISTORY';
export const RECONCILIATION_UPDATE_ACTION_COMMENTS = 'RECONCILIATION_UPDATE_ACTION_COMMENTS';
export const RECONCILIATION_SET_PUBLISH_TYPES = 'RECONCILIATION_SET_PUBLISH_TYPES';

export const RECONCILIATION_SELECTED_FUND_GROUPS = 'RECONCILIATION_SELECTED_FUND_GROUPS';
export const RECONCILIATION_SELECTED_PERIODS = 'RECONCILIATION_SELECTED_PERIODS';
export const RECONCILIATION_SELECTED_DEFAULT_PERIOD = 'RECONCILIATION_SELECTED_DEFAULT_PERIOD';
export const RECONCILIATION_SELECTED_FUND = 'RECONCILIATION_SELECTED_FUND';
export const RECONCILIATION_SELECTED_PUBLISH_TYPES = 'RECONCILIATION_SELECTED_PUBLISH_TYPES';

export const RECONCILIATION_SET_GRID_COLUMNS_STATE = 'RECONCILIATION_SET_GRID_COLUMNS_STATE';
export const RECONCILIATION_SET_GRID_COLUMNS_SET_STATE = 'RECONCILIATION_SET_GRID_COLUMNS_SET_STATE';

// Fund Fees
export const FF_SET_REPRESENTATIVES_FOR_FILTER = '[FUND FEES] FF_SET_REPRESENTATIVES_FOR_FILTER';
export const FF_SET_OPTIONS_FOR_FILTER = '[FUND FEES] FF_SET_OPTIONS_FOR_FILTER';
export const FF_SET_FUNDS_FOR_FILTER = '[FUND FEES] FF_SET_FUNDS_FOR_FILTER';
export const FF_SELECTED_FUNDS = '[FUND FEES] FF_SELECTED_FUNDS';
export const FF_SELECTED_PERIOD = '[FUND FEES] FF_SELECTED_PERIOD';
export const FF_CURRENT_PERIOD_TYPE = '[FUND FEES] FF_CURRENT_PERIOD_TYPE';
export const FF_SET_CURRENT_PAGE = '[FUND FEES] FF_SET_CURRENT_PAGE';
export const FF_SET_RESULTS_PER_PAGE = '[FUND FEES] FF_SET_RESULTS_PER_PAGE';
export const FF_SELECTED_PERIOD_PICKER_TYPE = '[FUND FEES] FF_SELECTED_PERIOD_PICKER_TYPE';
export const FF_SET_IS_LOADING_REP_DATA_FOR_FILTER = '[FUND FEES] FF_SET_IS_LOADING_REP_DATA_FOR_FILTER';
export const FF_SET_IS_LOADING_FUND_DATA_FOR_FILTER = '[FUND FEES] FF_SET_IS_LOADING_FUND_DATA_FOR_FILTER';
export const FF_SET_IS_LOADING_FUNDS_FOR_FILTER = '[FUND FEES] FF_SET_IS_LOADING_FUNDS_FOR_FILTER';
export const FF_SET_IS_LOADING_DEFAULT_VIEW = '[FUND FEES] FF_SET_IS_LOADING_DEFAULT_VIEW';

// General Ledgers
export const GL_SET_FUNDS_FOR_FILTER = '[GENERAL LEDGERS] GL_SET_FUNDS_FOR_FILTER';
export const GL_SELECTED_FUND = '[GENERAL LEDGERS] GL_SELECTED_FUND';
export const GL_SELECTED_PERIOD = '[GENERAL LEDGERS] GL_SELECTED_PERIOD';
export const GL_SET_ACTIVE_TAB = '[GENERAL LEDGERS] GL_SET_ACTIVE_TAB';
export const GL_SET_ACCOUNTS_FOR_FILTER = '[GENERAL LEDGERS] GL_SET_ACCOUNTS_FOR_FILTER';
export const GL_SELECTED_ACCOUNTS = '[GENERAL LEDGERS] GL_SELECTED_ACCOUNTS';
export const GL_SET_STATUSES_FOR_FILTER = '[GENERAL LEDGERS] GL_SET_STATUSES_FOR_FILTER';
export const GL_SELECTED_STATUSES = '[GENERAL LEDGERS] GL_SELECTED_STATUSES';
export const GL_DOCUMENT_EXPORTS = '[GENERAL LEDGERS] GL_DOCUMENT_EXPORTS';
export const GL_SELECTED_TRANSACTION_IDS = '[GENERAL LEDGERS] GL_SELECTED_TRANSACTION_IDS';
export const GL_SET_SELECTED_BATCHES = '[GENERAL LEDGERS] GL_SET_SELECTED_BATCHES';
export const GL_SELECT_ALL = '[GENERAL LEDGERS] GL_SELECT_ALL';
export const GL_PENDING_TOTALS = '[GENERAL LEDGERS] GL_PENDING TOTALS';
export const GL_TRANSACTIONS_TOTALS = '[GENERAL LEDGERS] GL_TRANSACTIONS TOTALS';
export const GL_SET_FETCHING = '[GENERAL LEDGERS] GL_SET_FETCHING';
export const GL_SET_TRANSACTION_FETCHING = '[GENERAL LEDGERS] GL_SET_TRANSACTION_FETCHING';
export const GL_SET_TABLE_FETCHING = '[GENERAL LEDGERS] GL_TABLE_SET_FETCHING';
export const GL_RESET_STATE = '[GENERAL LEDGERS] GL_RESET_STATE';
export const GL_MANAGE_ARCHIVE_MODAL = '[GENERAL LEDGERS GL_MANAGE_ARCHIVE_MODAL';
export const GL_MANAGE_MOVE_TO_PENDING_MODAL = '[GENERAL LEDGERS GL_MANAGE_MOVE_TO_PENDING_MODAL';
export const GL_MANAGE_APPROVE_MODAL = '[GENERAL LEDGERS GL_MANAGE_APPROVE_MODAL';

// Operations
export const OPERATIONS_SET_ACTIVE_STAT = 'OPERATIONS_SET_ACTIVE_STAT';
export const OPERATIONS_SET_FILTERED_CAPITAL_EVENTS = 'OPERATIONS_SET_FILTERED_CAPITAL_EVENTS';
export const OPERATIONS_SET_CAPITAL_EVENTS = 'OPERATIONS_SET_CAPITAL_EVENTS';
export const OPERATIONS_UPDATE_CAPITAL_EVENT_STATUS = 'OPERATIONS_UPDATE_CAPITAL_EVENT_STATUS';
export const OPERATIONS_UPDATE_UND_CAPITAL_EVENTS = 'OPERATIONS_UPDATE_UND_CAPITAL_EVENTS';
export const OPERATIONS_DELETE_STANDALONE_UND_CAPITAL_EVENT = 'OPERATIONS_DELETE_STANDALONE_UND_CAPITAL_EVENT';
export const OPERATIONS_SET_CAPITAL_EVENT_AS_PAID = 'OPERATIONS_SET_CAPITAL_EVENT_AS_PAID';
export const OPERATIONS_SET_OUTSTANDING_INVESTMENTS = 'OPERATIONS_SET_OUTSTANDING_INVESTMENTS';
export const OPERATIONS_SET_ALL_CAPITAL_EVENTS_EXPORTS = 'OPERATIONS_SET_ALL_CAPITAL_EVENTS_EXPORTS';
export const OPERATIONS_SET_ALL_OUTSTANDING_INVESTMENTS = 'OPERATIONS_SET_ALL_OUTSTANDING_INVESTMENTS';
export const OPERATIONS_SET_CAPITAL_EVENTS_FILTERS = 'OPERATIONS_SET_CAPITAL_EVENTS_FILTERS';
export const OPERATIONS_SET_CAPITAL_EVENTS_ACTIVE_FILTERS = 'OPERATIONS_SET_CAPITAL_EVENTS_ACTIVE_FILTERS';
export const OPERATIONS_SET_CAPITAL_EVENTS_CALL_DATE_FILTER = 'OPERATIONS_SET_CAPITAL_EVENTS_CALL_DATE_FILTER';
export const OPERATIONS_SET_FILTERED_OUTSTANDING_INVESTMENTS = 'OPERATIONS_SET_FILTERED_OUTSTANDING_INVESTMENTS';
export const OPERATIONS_SET_OUTSTANDING_DISTRIBUTION_REPORTS = 'OPERATIONS_SET_OUTSTANDING_DISTRIBUTION_REPORTS';
export const OPERATIONS_SET_VISIBLE_CAPITAL_EVENT_IDS = 'OPERATIONS_SET_VISIBLE_CAPITAL_EVENT_IDS';
export const OPERATIONS_SET_CAPITAL_EVENTS_IS_FETCHING = 'OPERATIONS_SET_CAPITAL_EVENTS_IS_FETCHING';
export const OPERATIONS_SET_GL_APPROVED = 'OPERATIONS_SET_GL_APPROVED';
export const OPERATIONS_SET_LAST_EXPORTED_FILE = 'OPERATIONS_SET_LAST_EXPORTED_FILE';
export const OPERATIONS_SET_HAS_NEW_EXPORTED_FILE = 'OPERATIONS_SET_HAS_NEW_EXPORTED_FILE';

// Notifications
export const NOTIFY_SUCCESS = 'NOTIFY_SUCCESS';
export const NOTIFY_ERROR = 'NOTIFY_ERROR';

// Report Generator Admin or Report Generator Configs
export const RGA_SET_REPORT_LOADED = '[REPORT GENERATOR ADMIN] SET_REPORT_LOADED';
export const RGA_SET_REPORT_CONFIGS = '[REPORT GENERATOR ADMIN] SET_REPORT_CONFIGS';
export const RGA_SET_REPORT_CONFIGS_FAILED = '[REPORT GENERATOR ADMIN] SET_REPORT_CONFIGS_FAILED';
export const RGA_SET_USER_ACCESS_FAILED = '[REPORT GENERATOR ADMIN] RGA_SET_USER_ACCESS_FAILED';
export const RGA_SET_SELECTED_FIELDS = '[REPORT GENERATOR ADMIN] SET_SELECTED_FIELDS';
export const RGA_SET_REPORT_FIELDS = '[REPORT GENERATOR ADMIN] SET_REPORT_FIELDS';
export const RGA_REMOVE_ALL_FIELDS_FILTER = '[REPORT GENERATOR ADMIN] REMOVE_ALL_FIELDS_FILTER';
export const RGA_SET_CONFIGURABLE = '[REPORT GENERATOR ADMIN] SET_CONFIGURABLE';
export const RGA_SET_TEMPORARY_CONFIGURABLE = '[REPORT GENERATOR ADMIN] SET_TEMPORARY_CONFIGURABLE';

export const RGA_OPEN_FIELD_FILTER = '[REPORT GENERATOR ADMIN] OPEN_FIELD_FILTER';
export const RGA_CLOSE_FIELD_FILTER = '[REPORT GENERATOR ADMIN] CLOSE_FIELD_FILTER';
export const RGA_ADD_FIELD_FILTER = '[REPORT GENERATOR ADMIN] ADD_FILTER';
export const RGA_SELECT_ALL_FILTERS = '[REPORT GENERATOR ADMIN] SELECT ALL FILTERS';
export const RGA_EMPTY_FILTERS = '[REPORT GENERATOR ADMIN] EMPTY_FILTERS';
export const RGA_SET_FIELD_METADATA = '[REPORT GENERATOR ADMIN] SET FIELD METADATA';

export const RGA_LOADING_OPTIONS_REQUEST = '[REPORT GENERATOR ADMIN] LOADING_OPTIONS_REQUEST';
export const RGA_LOADING_OPTIONS_SUCCESS = '[REPORT GENERATOR ADMIN] LOADING_OPTIONS_SUCCESS';
export const RGA_LOADING_OPTIONS_FAILURE = '[REPORT GENERATOR ADMIN] LOADING_OPTIONS_FAILURE';
export const RGA_SAVE_FILTER_NUMERIC = '[REPORT GENERATOR ADMIN] SAVE_FILTER_NUMERIC';
export const RGA_SAVE_FILTER_TRANSFORM = '[REPORT GENERATOR ADMIN] SAVE_FILTER_TRANSFORM';

export const RGA_CHANGE_DATE_FILTER_RANGE = '[REPORT GENERATOR ADMIN] CHANGE_DATE_FILTER_RANGE';
export const RGA_SAVE_FILTER_DATE = '[REPORT GENERATOR ADMIN] SAVE_FILTER_DATE';

export const RGA_SET_STRING_FILTER_OPTIONS = '[REPORT GENERATOR ADMIN] SET_STRING_FILTER_OPTIONS';
export const RGA_REMOVE_STRING_FILTERED_OPTIONS = '[REPORT GENERATOR ADMIN] REMOVE_STRING_FILTERED_OPTIONS';

export const RGA_SAVE_FILTER_QUERY = '[REPORT GENERATOR ADMIN] SAVE_FILTER_QUERY';

export const RGA_CHANGE_BOOLEAN_FILTER = '[REPORT GENERATOR ADMIN] CHANGE_BOOLEAN_FILTER';
export const RGA_SAVE_FILTER_BOOLEAN = '[REPORT GENERATOR ADMIN] SAVE_FILTER_BOOLEAN';
export const RG_RESET_BOOLEAN_FILTER = '[REPORT GENERATOR] RESET_BOOLEAN_FILTER';

export const RGA_SAVE_FILE_NAME = '[REPORT GENERATOR ADMIN] SAVE_FILE_NAME';
export const RGA_UPDATE_REPORT_CONFIG = '[REPORT GENERATOR ADMIN] RGA_UPDATE_CONFIG';

export const RGA_SET_DATA_FETCH_IN_PROGRESS = '[REPORT GENERATOR ADMIN] RGA_SET_DATA_FETCH_IN_PROGRESS';
export const RGA_SET_REPORT_DATA = '[REPORT GENERATOR ADMIN] SET_REPORT_DATA';
export const RGA_RESET_REPORT_DATA = '[REPORT GENERATOR ADMIN] RESET_REPORT_DATA';

export const RGA_WORKER_STARTED = '[REPORT_GENERATOR ADMIN] WORKER_STARTED';
export const RGA_WORKER_FINISHED = '[REPORT_GENERATOR ADMIN] WORKER_FINISHED';
export const RGA_UPDATE_WORKER_PROGRESS = '[REPORT_GENERATOR ADMIN] UPDATE_WORKER_PROGRESS';

export const RGA_SET_NUMERIC_FILTER_OPTIONS = '[REPORT GENERATOR ADMIN] SET_NUMERIC_FILTER_OPTIONS';
export const RGA_REMOVE_NUMERIC_FILTERED_OPTIONS = '[REPORT GENERATOR ADMIN] REMOVE_NUMERIC_FILTERED_OPTIONS';
export const RGA_SET_NUMERIC_FILTER_TYPE = '[REPORT GENERATOR ADMIN] SET_NUMERIC_FILTER_TYPE';
export const RGA_SET_NUMERIC_FILTER_RANGE = '[REPORT GENERATOR ADMIN] SET_NUMERIC_FILTER_RANGE';
export const RGA_SET_NUMERIC_NULL_OPTION = '[REPORT GENERATOR ADMIN] SET_NUMERIC_NULL_OPTION';

export const RGA_SET_TRANSFORM_NULL_FILTER = '[REPORT GENERATOR ADMIN] SET_TRANSFORM_NULL_FILTER';

export const RGA_LOAD_SCHEDULE = '[REPORT GENERATOR ADMIN] RGA_LOAD_SCHEDULE';
export const RGA_CHANGE_SCHEDULE_TYPES = '[REPORT GENERATOR ADMIN] CHANGE_SCHEDULE_TYPES';
export const RGA_SET_SFTP_ACCOUNTS = '[REPORT GENERATOR ADMIN] SET_SFTP_ACCOUNTS';
export const RG_SET_SNOWFLAKE_CLIENTS = '[REPORT GENERATOR] SET_SNOWFLAKE_CLIENTS';

export const RG_SET_FAILED_SCHEDULE_NOTIFICATION_RECIPIENTS =
  '[REPORT GENERATOR] FAILED_SCHEDULE_NOTIFICATION_RECIPIENTS';

export const RG_APPLY_FILTERS = '[REPORT GENERATOR] REPORT_GENERATOR_APPLY_FILTERS';
// DM
export const DM_SET_FUNDS = 'DM_SET_FUNDS';
export const DM_SET_WLPS = 'DM_SET_WLPS';
export const DM_SET_FIRMS = 'DM_SET_FIRMS';
export const DM_SELECT_FUND = 'DM_SELECT_FUND';
export const DM_SELECT_FUNDS = 'DM_SELECT_FUNDS';
export const DM_SELECT_WLP = 'DM_SELECT_WLP';
export const DM_SELECT_WLPS = 'DM_SELECT_WLPS';
export const DM_SELECT_FIRM = 'DM_SELECT_FIRM';
export const DM_SELECT_FIRMS = 'DM_SELECT_FIRMS';
export const DM_TOGGLE_ALL_DOCS = 'DM_TOGGLE_ALL_DOCS';

export const DM_SET_DOCUMENTS = 'DM_SET_DOCUMENTS';
export const DM_SET_EXPIRING_DOCUMENTS = 'DM_SET_EXPIRING_DOCUMENTS';
export const DM_SET_EXPIRED_DOCUMENTS = 'DM_SET_EXPIRED_DOCUMENTS';
export const DM_SET_PENDING_APPROVAL = 'DM_SET_PENDING_APPROVAL';
export const DM_SET_PENDING_NOTIFICATIONS = 'DM_SET_PENDING_NOTIFICATIONS';
export const DM_SET_LIBRARY_DOCUMENTS = 'DM_SET_LIBRARY_DOCUMENTS';
export const DM_SET_DOCUMENT_TYPES = 'DM_SET_DOCUMENT_TYPES';
export const DM_SET_DOCUMENTS_STATUS = 'DM_SET_DOCUMENTS_STATUS';
export const DM_SET_BRANCH_CODES = 'DM_SET_BRANCH_CODES';
export const DM_SET_TEMPLATE_DOCUMENTS = 'DM_SET_TEMPLATE_DOCUMENTS';
export const DM_SET_FUND_GROUPS = 'DM_SET_FUND_GROUPS';

export const DM_UPLOAD_DOCUMENT = 'DM_UPLOAD_DOCUMENT';
export const DM_UPLOAD_LIBRARY_DOCUMENT = 'DM_UPLOAD_LIBRARY_DOCUMENT';
export const DM_UPLOAD_TEMPLATE_DOCUMENT = 'DM_UPLOAD_TEMPLATE_DOCUMENT';
export const DM_UPDATE_DOCUMENT = 'DM_UPDATE_DOCUMENT';
export const DM_UPDATE_LIBRARY_DOCUMENT = 'DM_UPDATE_LIBRARY_DOCUMENT';
export const DM_UPDATE_TEMPLATE_DOCUMENT = 'DM_UPDATE_TEMPLATE_DOCUMENT';
export const DM_DELETE_DOCUMENT = 'DM_DELETE_DOCUMENT';
export const DM_DELETE_LIBRARY_DOCUMENT = 'DM_DELETE_LIBRARY_DOCUMENT';

export const DM_LOADING_FUNDS = 'DM_LOADING_FUNDS';
export const DM_LOADING_WLPS = 'DM_LOADING_WLPS';
export const DM_LOADING_FIRMS = 'DM_LOADING_FIRMS';
export const DM_SET_FUND_DOCUMENT_EMAILS = 'DM_SET_FUND_DOCUMENT_EMAILS';
export const DM_SET_FUND_DOCUMENT_EMAIL_PARTNERS = 'DM_SET_FUND_DOCUMENT_EMAIL_PARTNERS';

// Metrics Fund Dashboard
export const FD_SELECT_FUNDS = 'FD_SELECT_FUNDS';
export const FD_SET_FUNDS = 'FD_SET_FUNDS';
export const FD_SET_FUND_INFO = 'FD_SET_FUND_INFO';
export const FD_SET_CURRENT_STATUS = 'FD_SET_CURRENT_STATUS';
export const FD_SET_SITE_ENGAGEMENT = 'FD_SET_SITE_ENGAGEMENT';
export const FD_SET_SUBSCRIPTION_STATUS = 'FD_SET_SUBSCRIPTION_STATUS';
export const FD_RUN_FUND_METRICS = 'FD_RUN_FUND_METRICS';
export const FD_SET_VIEW = 'FD_SET_VIEW';
export const FD_SET_VIEW_MODE = 'FD_SET_VIEW_MODE';

// Supplemental Questions
export const SUPP_FORM_SET_FORM_DATA = 'SUPP_FORM_SET_FORM_DATA';
export const SUPP_FORM_SET_SCHEMA = 'SUPP_FORM_SET_SCHEMA';
export const SUPPLEMENTAL_FORM_UNMOUNT = 'SUPPLEMENTAL_FORM_UNMOUNT';
export const SUPP_FORM_START_REQUEST = 'SUPP_FORM_START_REQUEST';
export const SUPP_FORM_END_REQUEST = 'SUPP_FORM_END_REQUEST';
export const SUPP_FORM_STARTED = 'SUPP_FORM_STARTED';
export const SUPP_FORM_ACCOUNT_INFO = 'SUPP_FORM_ACCOUNT_INFO';
export const SUPP_FORM_SET_EDIT_PERMISSION = 'SUPP_FORM_SET_EDIT_PERMISSION';
export const SUPP_FORM_UPDATE_START_REQUEST = 'SUPP_FORM_UPDATE_START_REQUEST';
export const SUPP_FORM_UPDATE_END_REQUEST = 'SUPP_FORM_UPDATE_END_REQUEST';

// Metrics Sales Detail
export const SD_SELECT_FUNDS = 'SD_SELECT_FUNDS';
export const SD_SET_FUNDS = 'SD_SET_FUNDS';
export const SD_SET_SELECTED_VIEW_MODE = 'SD_SET_SELECTED_VIEW_MODE';
export const SD_SET_DRILLDOWN = 'SD_SET_DRILLDOWN';
export const SD_CLEAR_DRILLDOWN = 'SD_CLEAR_DRILLDOWN';
export const SD_SET_SUMMARY = 'SD_SET_SUMMARY';

// Sales Reports
export const SR_SET_SALES_REPORTS = 'SR_SET_SALES_REPORTS';

// Sales Invite Clients
export const IC_SET_CLIENT_FIRST_NAME = 'IC_SET_CLIENT_FIRST_NAME';
export const IC_SET_CLIENT_LAST_NAME = 'IC_SET_CLIENT_LAST_NAME';
export const IC_SET_CLIENT_EMAIL = 'IC_SET_CLIENT_EMAIL';
export const IC_SET_CLIENT_TYPE = 'IC_SET_CLIENT_TYPE';
export const IC_SET_CLIENT_USER_TYPE = 'IC_SET_CLIENT_USER_TYPE';
export const IC_SET_CLIENTS_LIST = 'IC_SET_CLIENTS_LIST';
export const IC_SET_FUNDS_LIST = 'IC_SET_FUNDS_LIST';
export const IC_SET_DEEPLINKING_FUNDS_LIST = 'IC_SET_DEEPLINKING_FUNDS_LIST';
export const IC_SET_SELECTED_FUNDS = 'IC_SET_SELECTED_FUNDS';
export const IC_SET_DEEPLINKING_SELECTED_FUNDS = 'IC_SET_DEEPLINKING_SELECTED_FUNDS';
export const IC_SET_INVITE_MODAL_STATUS = 'IC_SET_INVITE_MODAL_STATUS';
export const IC_SET_HIDE_COOLOFF_GP_ATTESTATION = 'IC_SET_HIDE_COOLOFF_GP_ATTESTATION';
export const IC_RESET_FORM_DATA = 'IC_RESET_FORM_DATA';
export const IC_SET_ERROR = 'IC_SET_ERROR';
export const IC_SET_MESSAGE = 'IC_SET_MESSAGE';
export const IC_SET_ALLOW_FILES_MANAGEMENT = 'IC_SET_ALLOW_FILES_MANAGEMENT';
export const IC_SET_STORED_FILES = 'IC_SET_STORED_FILES';
export const IC_SET_MULTIPLE_CLIENTS_INVITATION_LIST = 'IC_SET_MULTIPLE_CLIENTS_INVITATION_LIST';
export const IC_SET_INVITE_TYPE = 'IC_SET_INVITE_TYPE';
export const IC_RESET_INVITE_CLIENTS_DATA = 'IC_RESET_INVITE_CLIENTS_DATA';
export const IC_SET_PRE_EXISTING_RELATIONSHIP = 'IC_SET_PRE_EXISTING_RELATIONSHIP';
export const IC_SET_INVITE_RESPONSE = 'IC_SET_INVITE_RESPONSE';
export const IC_SET_CURRENT_BATCH_LABEL = 'IC_SET_CURRENT_BATCH_LABEL';
export const IC_SET_TOTAL_BATCH_INVITES = 'IC_SET_TOTAL_BATCH_INVITES';
export const IC_SET_GP_ADMIN_COOLOFF_DIRECTS_ATTESTATION = 'IC_SET_GP_ADMIN_COOLOFF_DIRECTS_ATTESTATION';
export const IC_SET_GP_ADMIN_COOLOFF_FEEDERS_ATTESTATION = 'IC_SET_GP_ADMIN_COOLOFF_FEEDERS_ATTESTATION';

// Investment Dashboard
export const ID_SET_ADVISORS = 'ID_SET_ADVISORS';
export const ID_SELECT_ADVISOR = 'ID_SELECT_ADVISOR';
export const ID_SET_INVESTORS = 'ID_SET_INVESTORS';
export const ID_SELECT_INVESTOR = 'ID_SELECT_INVESTOR';
export const ID_SET_ACCOUNTS = 'ID_SET_ACCOUNTS';
export const ID_SELECT_ACCOUNTS = 'ID_SELECT_ACCOUNTS';
export const ID_RESET_ADVISOR = 'ID_RESET_ADVISOR';
export const ID_RESET_INVESTOR = 'ID_RESET_INVESTOR';
export const ID_RESET_ACCOUNT = 'ID_RESET_ACCOUNT';
export const ID_SET_FUNDS = 'ID_SET_FUNDS';
export const ID_SELECT_FUND = 'ID_SELECT_FUND';
export const ID_SET_FUND_DATA = 'ID_SET_FUND_DATA';
export const ID_RESET_FUND_DATA = 'ID_RESET_FUND_DATA';
export const ID_SET_INVESTMENT_DATA = 'ID_SET_INVESTMENT_DATA';
export const ID_RESET_INVESTMENT_DATA = 'ID_RESET_INVESTMENT_DATA';
export const ID_SET_GENERAL_LEDGER_TRANSACTIONS_DATA = 'ID_SET_GENERAL_LEDGER_TRANSACTIONS_DATA';
export const ID_SET_ALL_GENERAL_LEDGER_TRANSACTIONS_DATA = 'ID_SET_ALL_GENERAL_LEDGER_TRANSACTIONS_DATA';
export const ID_SET_ALL_GENERAL_LEDGER_EXTERNAL_COMMITMENT_TRANSACTIONS_DATA =
  'ID_SET_ALL_GENERAL_LEDGER_EXTERNAL_COMMITMENT_TRANSACTIONS_DATA';
export const ID_SET_INVESTOR_PROFILE_QUERY_STRING = 'ID_SET_INVESTOR_PROFILE_QUERY_STRING';
export const ID_SELECT_ACTIVE_TAB = 'ID_SELECT_ACTIVE_TAB';
export const ID_UPDATE_DATA_SETS_LOADED = 'ID_UPDATE_DATA_SETS_LOADED';
export const ID_SET_FUND_DATA_METRICS = 'ID_SET_FUND_DATA_METRICS';
export const ID_SET_EXPORTS = 'ID_SET_EXPORTS';
export const ID_SET_EXPORT_FLYOVER_STATUS = 'ID_SET_EXPORT_FLYOVER_STATUS';
export const ID_SET_EXPORT_CSV_DATA = 'ID_SET_EXPORT_CSV_DATA';
export const ID_SET_INVESTMENT_DATA_LEGACY = 'ID_SET_INVESTMENT_DATA_LEGACY';
export const ID_FETCHING_ADVISORS_ON_GOING = 'ID_FETCHING_ADVISORS_ON_GOING';
export const ID_FETCHING_INVESTORS_ON_GOING = 'ID_FETCHING_INVESTORS_ON_GOING';
export const ID_FETCHING_INVESTOR_PROFILE_QUERY_STRING_ON_GOING = 'ID_FETCHING_INVESTOR_PROFILE_QUERY_STRING_ON_GOING';
export const ID_FETCHING_ACCOUNTS_ON_GOING = 'ID_FETCHING_ACCOUNTS_ON_GOING';
export const ID_FETCHING_FUNDS_ON_GOING = 'ID_FETCHING_FUNDS_ON_GOING';
export const ID_SET_HIDE_ADVISOR_DROPDOWN = 'ID_SET_HIDE_ADVISOR_DROPDOWN';
export const ID_SET_DOC_CENTER_WIDGET_CACHE = 'ID_SET_DOC_CENTER_WIDGET_CACHE';
export const ID_SET_AGGREGATION_LEVEL = 'ID_SET_AGGREGATION_LEVEL';
export const ID_SET_DISCLAIMERS = 'ID_SET_DISCLAIMERS';
export const ID_SET_CONFIG = 'ID_SET_CONFIG';
export const ID_SET_SELECTED_ASSET_CLASS = 'ID_SET_SELECTED_ASSET_CLASS';
export const ID_SET_TABS = 'ID_SET_TABS';
export const ID_SET_ASSET_CLASSES = 'ID_SET_ASSET_CLASSES';
export const ID_SET_FILTERED_ACCOUNTS = 'ID_SET_FILTERED_ACCOUNTS';
export const ID_RESET_FUND = 'ID_RESET_FUND';
export const ID_RESET_ASSET_CLASS = 'ID_RESET_ASSET_CLASS';
export const ID_SET_SELECTED_CURRENCY = 'ID_SET_SELECTED_CURRENCY';

// Subscription Workflow Status
export const SWS_SET_COMBINATIONS = 'SWS_SET_COMBINATIONS';
export const SWS_DELETE_FROM_GRID = 'SWS_DELETE_FROM_GRID';
export const SWS_SET_FILTER_OPTIONS = 'SWS_SET_FILTER_OPTIONS';
export const SWS_SET_SELECTED_FILTERS_FOR_GROUP = 'SWS_SET_SELECTED_FILTERS_FOR_GROUP';
export const SWS_SET_FILTERED_COMBINATIONS = 'SWS_SET_FILTERED_COMBINATIONS';
export const SWS_CLEAR_SELECTED_FILTERS = 'SWS_CLEAR_SELECTED_FILTERS';
export const SWS_SET_TRIGGER_OPTIONS = 'SWS_SET_TRIGGER_OPTIONS';

// Subscription Reviewer
export const SR_LOAD_ASSIGNMENTS_SUCCESS = 'SR_LOAD_ASSIGNMENTS_SUCCESS';
export const SR_LOAD_ASSIGNMENTS_COUNT_SUCCESS = 'SR_LOAD_ASSIGNMENTS_COUNT_SUCCESS';
export const SR_LOAD_ASSIGNMENTS_REVIEWERS_SUCCESS = 'SR_LOAD_ASSIGNMENTS_REVIEWERS_SUCCESS';
export const SR_LOAD_RIAS_FIRMS_SUCCESS = 'SR_LOAD_RIAS_FIRMS_SUCCESS';
export const SR_SET_RESULTS_PER_PAGE = 'SR_SET_RESULTS_PER_PAGE';
export const SR_REMOVE_ASSIGNMENT = 'SR_REMOVE_ASSIGNMENT';
export const SR_SET_CURRENT_PAGE = 'SR_SET_CURRENT_PAGE';
export const SR_SELECTED_RIAS_FIRMS = 'SR_SELECTED_RIAS_FIRMS';
export const SR_LOAD_ASSIGNABLE_RIAS_FIRMS_SUCCESS = 'SR_LOAD_ASSIGNABLE_RIAS_FIRMS_SUCCESS';
export const SR_SELECTED_CONTACTS = 'SR_SELECTED_CONTACTS';
export const SR_SET_SORTING = 'SR_SET_SORTING';
export const SR_ADD_ASSIGNABLE_RIAS_FIRM_SUCCESS = 'SR_ADD_ASSIGNABLE_RIAS_FIRM_SUCCESS';
export const SR_DELETE_ASSIGNABLE_RIAS_FIRM_SUCCESS = 'SR_DELETE_ASSIGNABLE_RIAS_FIRM_SUCCESS';
export const SR_SET_ACTIVE_MODAL = 'SR_SET_ACTIVE_MODAL';
export const SR_SET_SELECTED_ROW = 'SR_SET_SELECTED_ROW';
export const SR_SET_SELECTED_FUNDS = 'SR_SET_SELECTED_FUNDS';
export const SR_LOAD_PRIVATE_ACCESS_FUNDS_SUCCESS = 'SR_LOAD_PRIVATE_ACCESS_FUNDS_SUCCESS';
export const SR_ADD_ASSIGNABLE_FUND_SUCCESS = 'SR_ADD_ASSIGNABLE_FUND_SUCCESS';
export const SR_DELETE_ASSIGNABLE_FUND_SUCCESS = 'SR_DELETE_ASSIGNABLE_FUND_SUCCESS';

// Subscription Document Definitions
export const SUB_DOCS_LOAD_DOCUMENTS_SUCCESS = 'SUB_DOCS_LOAD_DOCUMENTS_SUCCESS';
export const DEFINITION_SET_CURRENT_PAGE = 'DEFINITION_SET_CURRENT_PAGE';
export const DEFINITION_SET_RESULTS_PER_PAGE = 'DEFINITION_SET_RESULTS_PER_PAGE';
export const SUB_DOCS_SET_ACTIVE_MODAL = 'SUB_DOCS_SET_ACTIVE_MODAL';
export const SUB_DOCS_SET_SELECTED_ROW = 'SUB_DOCS_SET_SELECTED_ROW';
export const SUB_DOCS_SET_WLP_FILTER = 'SUB_DOCS_SET_WLP_FILTER';
export const SUB_DOCS_SET_DOCUMENT_TYPES_FILTER = 'SUB_DOCS_SET_DOCUMENT_TYPES_FILTER';
export const SUB_DOCS_SET_PAF_FILTER = 'SUB_DOCS_SET_PAF_FILTER';
export const SUB_DOCS_SET_TYPE_FILTER = 'SUB_DOCS_SET_TYPE_FILTER';
export const SUB_DOCS_SET_LIMIT_CODE_FILTER = 'SUB_DOCS_SET_LIMIT_CODE_FILTER';
export const SUB_DOCS_SET_FIRM_FILTER = 'SUB_DOCS_SET_FIRM_FILTER';
export const SUB_DOCS_SET_SORTING = 'SUB_DOCS_SET_SORTING';
export const DEFINITION_SET_DOCUMENT_TYPES = 'DEFINITION_SET_DOCUMENT_TYPES';
export const DEFINITION_SET_FUNDS = 'DEFINITION_SET_FUNDS';
export const DEFINITION_SET_WHITE_LABEL_PARTNERS = 'DEFINITION_SET_WHITE_LABEL_PARTNERS';
export const DEFINITION_SET_DEFINITION_TYPES = 'DEFINITION_SET_DEFINITION_TYPES';
export const DEFINITION_SET_SIGNER_TYPES = 'DEFINITION_SET_SIGNER_TYPES';
export const DEFINITION_SET_LIBRARY_TEMPLATES = 'DEFINITION_SET_LIBRARY_TEMPLATES';
export const DEFINITION_SET_REQUIRED_DOCUMENT_TYPES = 'DEFINITION_SET_REQUIRED_DOCUMENT_TYPES';
export const DEFINITION_SET_ACCOUNT_TYPES = 'DEFINITION_SET_ACCOUNT_TYPES';

// One Time Popups
export const ONE_TIME_POPUP_SET = 'ONE_TIME_POPUP_SET';
export const ONE_TIME_POPUP_READ = 'ONE_TIME_POPUP_READ';

// Terms of Use Popups
export const TOU_SET_TERMS = 'TOU_SET_TERMS';
export const TOU_SET_ERROR_MESSAGE = 'TOU_SET_ERROR_MESSAGE';

// Data Syncs
export const DD_LOAD_WHITE_LABEL_PARTNERS = '[DATA DASHBOARD] LOAD_WHITE_LABEL_PARTNERS';
export const DD_LOAD_PROVIDERS = '[DATA DASHBOARD] LOAD_PROVIDERS';
export const DD_LOAD_DATA_SYNCS = '[DATA DASHBOARD] LOAD_DATA_SYNCS';
export const DD_LOAD_DATA_SYNC = '[DATA DASHBOARD] LOAD_DATA_SYNC';
export const DD_CANCEL_DATA_SYNC = '[DATA DASHBOARD] CANCEL_DATA_SYNC';
export const DD_LOAD_DATA_SYNC_FILES = '[DATA DASHBOARD] LOAD_DATA_SYNC_FILES';
export const DD_LOAD_DATA_SYNC_FILE_REPORT = '[DATA DASHBOARD] LOAD_DATA_SYNC_FILE_REPORT';
export const DD_EXECUTE_DATA_SYNC = '[DATA DASHBOARD] EXECUTE_DATA_SYNC';
export const DD_EXECUTE_TRANSFORMATION = '[DATA DASHBOARD] EXECUTE_TRANSFORMATION';
export const DD_LOAD_DATA_SYNC_SCHEMA = '[DATA DASHBOARD] LOAD_DATA_SYNC_SCHEMA';
export const DD_LOAD_DATA_SYNC_IDENTIFIERS = '[DATA DASHBOARD] LOAD_DATA_SYNC_IDENTIFIERS';
export const DD_LOAD_DATA_SYNC_STATUSES = '[DATA DASHBOARD] LOAD_DATA_SYNC_STATUSES';
export const DD_LOAD_DATA_SYNC_USERS = '[DATA DASHBOARD] LOAD_DATA_SYNC_USERS';
export const DD_LOAD_DATA_SYNC_TIME_RANGES = '[DATA DASHBOARD] LOAD_DATA_SYNC_TIME_RANGES';
export const DD_LOAD_STORAGED_FILES = '[DATA DASHBOARD] LOAD_STORAGED_FILES';
export const DD_INITIATE_UPLOAD_FILE = '[DATA DASHBOARD] INITIATE_UPLOAD_FILE';
export const DD_DELETE_STORAGED_FILE = '[DATA DASHBOARD] DELETE_STORAGED_FILE';

// REPORTING
export const RP_SET_REPORTS = '[REPORTING] SET_REPORTS';
export const RP_AVAILABLE_FUNDS = '[REPORTING] AVAILABLE_FUNDS';
export const RP_OPERATIONS_REPORTS = '[REPORTING] OPERATIONS_REPORTS';
export const RP_SET_SELECTED_FUNDS = '[REPORTING] SET_SELECTED_FUNDS';
export const RP_OR_SET_MESSAGE_BANNER = '[REPORTING] OR_SET_MESSAGE_BANNER';
export const RP_REPORT_TYPE = '[REPORTING] RP_REPORT_TYPE';
export const RP_PW_DISABLE_GENERATE_BUTTON = '[REPORTING] RP_PW_DISABLE_GENERATE_BUTTON';
export const RP_PW_SET_MESSAGE_BANNER = '[REPORTING] RP_PW_SET_PPMMESSAGE_BANNER';
export const RP_SET_WATERMARKED_FILES = '[REPORTING] RP_SET_WATERMARKED_FILES';

// Subscription Review
export const SUBSCRIPTION_REVIEW_SELECT_PAF = 'SUBSCRIPTION_REVIEW_SELECT_PAF';
export const SUBSCRIPTION_REVIEW_SET_PAFS = 'SUBSCRIPTION_REVIEW_SET_PAFS';
export const SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENTS = 'SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENTS';
export const SUBSCRIPTION_REVIEW_SET_FUND_CATEGORIES = 'SUBSCRIPTION_REVIEW_SET_FUND_CATEGORIES';
export const SUBSCRIPTION_REVIEW_SELECT_FUND_CATEGORY = 'SUBSCRIPTION_REVIEW_SELECT_FUND_CATEGORY';
export const SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS =
  'SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS';
export const SUBSCRIPTION_REVIEW_SET_SEARCH_TERM = 'SUBSCRIPTION_REVIEW_SET_SEARCH_TERM';
export const SUBSCRIPTION_REVIEW_SET_SEARCH_TYPE = 'SUBSCRIPTION_REVIEW_SET_SEARCH_TYPE';
export const SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS_FOR_GROUP =
  'SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS_FOR_GROUP';
export const SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENT_GROUPS = 'SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENT_GROUPS';
export const SUBSCRIPTION_REVIEW_SET_PAF_TRANSFERS = 'SUBSCRIPTION_REVIEW_SET_PAF_TRANSFERS';
export const SUBSCRIPTION_REVIEW_REMOVE_PAF_TRANSFERS = 'SUBSCRIPTION_REVIEW_REMOVE_PAF_TRANSFERS';
export const SUBSCRIPTION_REVIEW_UPDATE_TRANSFER = 'SUBSCRIPTION_REVIEW_UPDATE_TRANSFER';
export const SUBSCRIPTION_REVIEW_SET_PAF_SUMMARY = 'SUBSCRIPTION_REVIEW_SET_PAF_SUMMARY';
export const SUBSCRIPTION_REVIEW_SET_PAF_NEXT_CLOSE = 'SUBSCRIPTION_REVIEW_SET_PAF_NEXT_CLOSE';
export const SUBSCRIPTION_REVIEW_SET_PAF_AGGREGATIONS = 'SUBSCRIPTION_REVIEW_SET_PAF_AGGREGATIONS';
export const SUBSCRIPTION_REVIEW_SET_GENERATED_ZIPS = 'SUBSCRIPTION_REVIEW_SET_GENERATED_ZIPS';
export const SUBSCRIPTION_REVIEW_SET_ACCEPTED_INVESTMENTS_CLOSES =
  'SUBSCRIPTION_REVIEW_SET_ACCEPTED_INVESTMENTS_CLOSES';
export const SUBSCRIPTION_REVIEW_UPDATE_INVESTMENT = 'SUBSCRIPTION_REVIEW_UPDATE_INVESTMENT';
export const SUBSCRIPTION_REVIEW_REMOVE_INVESTMENT = 'SUBSCRIPTION_REVIEW_REMOVE_INVESTMENT';
export const SUBSCRIPTION_REVIEW_ADD_INVESTMENT = 'SUBSCRIPTION_REVIEW_ADD_INVESTMENT';
export const SUBSCRIPTION_REVIEW_SET_GENERATED_REDEMPTIONS_EXPORTS =
  'SUBSCRIPTION_REVIEW_SET_GENERATED_REDEMPTIONS_EXPORTS';
export const SUBSCRIPTION_REVIEW_SET_GENERATED_INVESTMENTS_EXPORTS =
  'SUBSCRIPTION_REVIEW_SET_GENERATED_INVESTMENTS_EXPORTS';
export const SUBSCRIPTION_REVIEW_SET_GENERATED_REMEDIATION_COMMENTS_EXPORTS =
  'SUBSCRIPTION_REVIEW_SET_GENERATED_REMEDIATION_COMMENTS_EXPORTS';
export const SUBSCRIPTION_REVIEW_SET_GENERATED_SIGNERS_EXPORTS = 'SUBSCRIPTION_REVIEW_SET_GENERATED_SIGNERS_EXPORTS';
export const SUBSCRIPTION_REVIEW_SET_GENERATED_APPROVAL_HISTORY_EXPORTS =
  'SUBSCRIPTION_REVIEW_SET_GENERATED_APPROVAL_HISTORY_EXPORTS';
export const SUBSCRIPTION_REVIEW_SET_GENERATED_LEAD_APPROVER_ASSIGNMENTS_EXPORTS =
  'SUBSCRIPTION_REVIEW_SET_GENERATED_LEAD_APPROVER_ASSIGNMENTS_EXPORTS';
export const SUBSCRIPTION_REVIEW_SET_INVESTMENT_STATS_BY_STATUS = 'SUBSCRIPTION_REVIEW_SET_INVESTMENT_STATS_BY_STATUS';
export const SUBSCRIPTION_REVIEW_SET_CLOSES_FOR_FUND = 'SUBSCRIPTION_REVIEW_SET_CLOSES_FOR_FUND';
export const SUBSCRIPTION_REVIEW_ADD_NEW_CLOSE = 'SUBSCRIPTION_REVIEW_ADD_NEW_CLOSE';
export const SUBSCRIPTION_REVIEW_SET_CLOSE_DATE_FOR_INVESTMENT = 'SUBSCRIPTION_REVIEW_SET_CLOSE_DATE_FOR_INVESTMENT';
export const SUBSCRIPTION_REVIEW_REMOVE_CLOSE = 'SUBSCRIPTION_REVIEW_REMOVE_CLOSE';
export const SUBSCRIPTION_REVIEW_SET_PAF_NOTE = 'SUBSCRIPTION_REVIEW_SET_PAF_NOTE';
export const SUBSCRIPTION_REVIEW_DELETE_PAF_NOTE = 'SUBSCRIPTION_REVIEW_DELETE_PAF_NOTE';
export const SUBSCRIPTION_REVIEW_SET_USER_VIEWS = 'SUBSCRIPTION_REVIEW_SET_USER_VIEWS';
export const SUBSCRIPTION_REVIEW_UPDATE_CLOSE = 'SUBSCRIPTION_REVIEW_UPDATE_CLOSE';
export const SUBSCRIPTION_REVIEW_SET_INVESTMENT_NOTES = 'SUBSCRIPTION_REVIEW_SET_INVESTMENT_NOTES';
export const SUBSCRIPTION_REVIEW_ADD_INVESTMENT_NOTE = 'SUBSCRIPTION_REVIEW_ADD_INVESTMENT_NOTE';
export const SUBSCRIPTION_REVIEW_SET_SUB_BOOKS_FOR_INVESTMENT = 'SUBSCRIPTION_REVIEW_SET_SUB_BOOKS_FOR_INVESTMENT';
export const SUBSCRIPTION_REVIEW_SET_COMMITMENT = 'SUBSCRIPTION_REVIEW_SET_COMMITMENT';
export const SUBSCRIPTION_REVIEW_SET_PLACEMENT_FEE = 'SUBSCRIPTION_REVIEW_SET_PLACEMENT_FEE';
export const SUBSCRIPTION_REVIEW_SET_SUB_ADVISORY_FEE = 'SUBSCRIPTION_REVIEW_SET_SUB_ADVISORY_FEE';
export const SUBSCRIPTION_REVIEW_SET_NEW_SUB_BOOKS = 'SUBSCRIPTION_REVIEW_SET_NEW_SUB_BOOKS';
export const SUBSCRIPTION_REVIEW_REMOVE_INVESTMENT_NOTE = 'SUBSCRIPTION_REVIEW_REMOVE_INVESTMENT_NOTE';
export const SUBSCRIPTION_REVIEW_SET_INVESTMENT_NOTE = 'SUBSCRIPTION_REVIEW_SET_INVESTMENT_NOTE';
export const SUBSCRIPTION_REVIEW_RESET_STATE = 'SUBSCRIPTION_REVIEW_RESET_STATE';
export const SUBSCRIPTION_REVIEW_SET_WHITE_LABEL_PARTNERS = 'SUBSCRIPTION_REVIEW_SET_WHITE_LABEL_PARTNERS';
export const SUBSCRIPTION_REVIEW_SET_RIAS_FIRMS = 'SUBSCRIPTION_REVIEW_SET_RIAS_FIRMS';
export const SUBSCRIPTION_REVIEW_SET_GRID_STATES = 'SUBSCRIPTION_REVIEW_SET_GRID_STATES';
export const SUBSCRIPTION_REVIEW_SET_GRID_STATUS_STATE = 'SUBSCRIPTION_REVIEW_SET_GRID_STATUS_STATE';
export const SUBSCRIPTION_REVIEW_SET_DEFAULT_SAVED_VIEW = 'SUBSCRIPTION_REVIEW_SET_DEFAULT_SAVED_VIEW';
export const SUBSCRIPTION_REVIEW_SET_CURRENT_SAVED_VIEW = 'SUBSCRIPTION_REVIEW_SET_CURRENT_SAVED_VIEW';
export const SUBSCRIPTION_REVIEW_RESET_FILTERS = 'SUBSCRIPTION_REVIEW_RESET_FILTERS';
export const SUBSCRIPTION_REVIEW_GET_SELECTED_PAF = 'SUBSCRIPTION_REVIEW_GET_SELECTED_PAF';
export const SUBSCRIPTION_REVIEW_EXPAND_ALL_STATUS = 'SUBSCRIPTION_REVIEW_EXPAND_ALL_STATUS';
export const SUBSCRIPTION_REVIEW_COLLAPSE_ALL_STATUS = 'SUBSCRIPTION_REVIEW_COLLAPSE_ALL_STATUS';
export const SUBSCRIPTION_REVIEW_COLLAPSE_EXPAND_STATE = 'SUBSCRIPTION_REVIEW_COLLAPSE_EXPAND_STATE';
export const SUBSCRIPTION_REVIEW_STATE_RENDERED = 'SUBSCRIPTION_REVIEW_STATE_RENDERED';
export const SUBSCRIPTION_REVIEW_STATUS_NOT_RENDERED = 'SUBSCRIPTION_REVIEW_STATUS_NOT_RENDERED';
export const SUBSCRIPTION_REVIEW_STATE_LOADING = 'SUBSCRIPTION_REVIEW_STATE_LOADING';
export const SUBSCRIPTION_REVIEW_SET_STATE_FILTER = 'SUBSCRIPTION_REVIEW_SET_STATE_FILTER';
export const SUBSCRIPTION_REVIEW_RESET_STATE_FILTER = 'SUBSCRIPTION_REVIEW_RESET_STATE_FILTER';
export const SUBSCRIPTION_REVIEW_CLEAR_FORCE_REFRESH_CLOSE_DATE_FILTER_OPTIONS =
  'SUBSCRIPTION_REVIEW_CLEAR_FORCE_REFRESH_CLOSE_DATE_FILTER_OPTIONS';
export const SUBSCRIPTION_REVIEW_SET_AVAILABLE_INVESTMENT_SHARE_CLASSES =
  'SUBSCRIPTION_REVIEW_SET_AVAILABLE_INVESTMENT_SHARE_CLASSES';
export const SUBSCRIPTION_REVIEW_SET_INVESTMENT_SHARE_CLASS = 'SUBSCRIPTION_REVIEW_SET_INVESTMENT_SHARE_CLASS';
export const SUBSCRIPTION_REVIEW_SET_PAF_HAS_CANCELED_INVESTMENTS =
  'SUBSCRIPTION_REVIEW_SET_PAF_HAS_CANCELED_INVESTMENTS';
export const SUBSCRIPTION_REVIEW_SELECT_INVESTMENT = 'SUBSCRIPTION_REVIEW_SELECT_INVESTMENT';
export const SUBSCRIPTION_REVIEW_SET_CURRENT_BUCKET = 'SUBSCRIPTION_REVIEW_SET_CURRENT_BUCKET';
export const SUBSCRIPTION_REVIEW_SET_SNACKBAR = 'SUBSCRIPTION_REVIEW_SET_SNACKBAR';

// Manage Funding Status
export const MANAGE_FUNDING_STATUS_SET_SELECTABLE_STATUSES = 'MANAGE_FUNDING_STATUS_SET_SELECTABLE_STATUSES';
export const MANAGE_FUNDING_STATUS_SET_SELECTED_STATUSES = 'MANAGE_FUNDING_STATUS_SET_SELECTED_STATUSES';
export const MANAGE_FUNDING_STATUS_SET_PRIVATE_ACCESS_FUND = 'MANAGE_FUNDING_STATUS_SET_PRIVATE_ACCESS_FUND';
export const MANAGE_FUNDING_STATUS_SET_READY_TO_FUND_INVESTMENTS =
  'MANAGE_FUNDING_STATUS_SET_READY_TO_FUND_INVESTMENTS';
export const MANAGE_FUNDING_STATUS_SET_NOT_READY_TO_FUND_INVESTMENTS =
  'MANAGE_FUNDING_STATUS_SET_NOT_READY_TO_FUND_INVESTMENTS';
export const MANAGE_FUNDING_STATUS_SET_NEXT_FUNDING_WINDOW = 'MANAGE_FUNDING_STATUS_SET_NEXT_FUNDING_WINDOW';
export const MANAGE_FUNDING_STATUS_UPDATE_OVERRIDE_FUNDS = 'MANAGE_FUNDING_STATUS_UPDATE_OVERRIDE_FUNDS';

// Notification Events
export const NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENT = 'NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENT';
export const NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENTS = 'NOTIFICATIONS_EVENTS_SET_NOTIFICATIONS_EVENTS';
export const NOTIFICATIONS_EVENTS_DELETE_NOTIFICATION_EVENT = 'NOTIFICATIONS_EVENTS_DELETE_NOTIFICATION_EVENT';
export const NOTIFICATIONS_EVENTS_SET_NOTIFICATION_EVENT_TYPES = 'NOTIFICATIONS_EVENTS_SET_NOTIFICATION_EVENT_TYPES';
export const NOTIFICATIONS_EVENTS_SET_PRIVATE_ACCESS_FUNDS = 'NOTIFICATIONS_EVENTS_SET_PRIVATE_ACCESS_FUNDS';
export const NOTIFICATIONS_EVENTS_SET_INTEGRATION_PARTNERS = 'NOTIFICATIONS_EVENTS_SET_INTEGRATION_PARTNERS';
export const NOTIFICATIONS_EVENTS_SET_TO_CONTACTABLES = 'NOTIFICATIONS_EVENTS_SET_TO_CONTACTABLES';
export const NOTIFICATIONS_EVENTS_SET_CC_CONTACTABLES = 'NOTIFICATIONS_EVENTS_SET_CC_CONTACTABLES';
export const NOTIFICATION_EVENTS_SET_LOADING_TO_CONTACTABLES = 'NOTIFICATION_EVENTS_SET_LOADING_TO_CONTACTABLES';
export const NOTIFICATION_EVENTS_SET_LOADING_CC_CONTACTABLES = 'NOTIFICATION_EVENTS_SET_LOADING_CC_CONTACTABLES';

// Authorization Groups
export const SET_AUTHORIZATION_GROUPS = 'SET_AUTHORIZATION_GROUPS';
export const SET_WLPS_AG = 'SET_WLPS_AG';
export const SET_PERMISSIONS_AG = 'SET_PERMISSIONS_AG';
export const DELETE_AUTHORIZATION_GROUP = 'DELETE_AUTHORIZATION_GROUP';
export const SET_GROUP_FOR_ACTION = 'SET_GROUP_FOR_ACTION';
export const SET_PERMISSION_FOR_GROUP = 'SET_PERMISSION_FOR_GROUP';
export const ACTION_TYPE_DELETE = 'Delete';
export const ACTION_TYPE_UDPATE = 'Update';
export const ACTION_TYPE_CREATE = 'Create';

// Bulk preview notices
export const CAPITAL_EVENTS_OPEN_BULK_PREVIEW_NOTICES = 'CAPITAL_EVENTS_OPEN_BULK_PREVIEW_NOTICES';
export const CAPITAL_EVENTS_CLOSE_BULK_PREVIEW_NOTICES = 'CAPITAL_EVENTS_CLOSE_BULK_PREVIEW_NOTICES';
export const CAPITAL_EVENTS_SET_BULK_PREVIEW_NOTICES = 'CAPITAL_EVENTS_SET_BULK_PREVIEW_NOTICES';

// Sub Doc Exclusions
export const SE_LOAD_EXCLUSIONS_SUCCESS = 'SE_LOAD_EXCLUSIONS_SUCCESS';
export const SE_REMOVE_EXCLUSION = 'SE_REMOVE_EXCLUSION';
export const SE_SET_SELECTED_EXCLUSIONS = 'SE_SET_SELECTED_EXCLUSIONS';
export const SE_LOAD_EXCLUSIONS_COUNT = 'SE_LOAD_EXCLUSIONS_COUNT';
export const SE_LOAD_RIAS_FIRMS_SUCCESS = 'SE_LOAD_RIAS_FIRMS_SUCCESS';
export const SE_LOAD_FUNDS_SUCCESS = 'SE_LOAD_FUNDS_SUCCESS';
export const SE_SET_ACTIVE_MODAL = 'SE_SET_ACTIVE_MODAL';
export const SE_SET_CURRENT_PAGE = 'SE_SET_CURRENT_PAGE';
export const SE_SET_RESULTS_PER_PAGE = 'SE_SET_RESULTS_PER_PAGE';
export const SE_SET_SELECTED_ROW = 'SE_SET_SELECTED_ROW';
export const SE_SET_SORTING = 'SE_SET_SORTING';

// Report Generator Admin
export const RGA_LOAD_CONFIGS_SUCCESS = 'RGA_LOAD_CONFIGS_SUCCESS';
export const RGA_LOAD_CONFIGS_COUNT = 'RGA_LOAD_CONFIGS_COUNT';
export const RGA_SET_BASE_QUERY_MODELS = 'RGA_SET_BASE_QUERY_MODELS';
export const RGA_REMOVE_CONFIG = 'RGA_REMOVE_CONFIG';
export const RGA_SET_SELECTED_EXCLUSIONS = 'RGA_SET_SELECTED_EXCLUSIONS';
export const RGA_SET_ACTIVE_MODAL = 'RGA_SET_ACTIVE_MODAL';
export const RGA_SET_CURRENT_PAGE = 'RGA_SET_CURRENT_PAGE';
export const RGA_SET_RESULTS_PER_PAGE = 'RGA_SET_RESULTS_PER_PAGE';
export const RGA_SET_SELECTED_ROW = 'RGA_SET_SELECTED_ROW';
export const RGA_SET_CHECKED_ROWS = 'RGA_SET_CHECKED_ROWS';
export const RGA_SET_SORTING = 'RGA_SET_SORTING';
export const RGA_LOAD_CONFIG_SHARED_USERS = 'RGA_LOAD_CONFIG_SHARED_USERS';
export const RGA_CONFIG_SHARED_USERS_COUNT = 'RGA_CONFIG_SHARED_USERS_COUNT';
export const RGA_SET_CONFIG_FIELDS = '[REPORT GENERATOR ADMIN] SET_CONFIG_FIELDS';
export const RG_SET_SNOWFLAKE_CONFIGS = 'RG_SET_SNOWFLAKE_CONFIGS';
export const RG_SET_CONFIG_FILE_ATTRIBUTES = 'RG_SET_CONFIG_FILE_ATTRIBUTES';

// custom query
export const RGA_SET_CUSTOM_QUERIES = 'RGA_SET_CUSTOM_QUERIES';
export const RGA_SET_CREATE_CUSTOM_QUERY = 'RGA_SET_CREATE_CUSTOM_QUERY';
export const RGA_SET_CUSTOM_QUERY = 'RGA_SET_CUSTOM_QUERY';
export const RGA_SET_DYNAMIC_QUERY = 'RGA_SET_DYNAMIC_QUERY';

// Report Generator Schedule Requests
export const RGA_LOAD_SCHEDULED_CONFIGS_COUNT = 'RGA_LOAD_SCHEDULED_CONFIGS_COUNT';
export const RGA_LOAD_SCHEDULED_CONFIGS_SUCCESS = 'RGA_LOAD_SCHEDULED_CONFIGS_SUCCESS';
export const RGA_SCHEDULED_REQUEST_SET_ACTIVE_TAB = 'RGA_SCHEDULED_REQUEST_SET_ACTIVE_TAB';
export const RGA_SCHEDULED_REQUEST_SET_RESULTS_PER_PAGE = 'RGA_SCHEDULED_REQUEST_SET_RESULTS_PER_PAGE';
export const RGA_SCHEDULED_REQUEST_SET_CURRENT_PAGE = 'RGA_SCHEDULED_REQUEST_SET_CURRENT_PAGE';
export const RGA_SCHEDULED_REQUEST_SET_SORTING = 'RGA_SCHEDULED_REQUEST_SET_SORTING';
export const RGA_SCHEDULED_REQUEST_SHOW_DELIVERY_SCHEDULE_MODAL = 'RGA_SCHEDULED_REQUEST_SHOW_DELIVERY_SCHEDULE_MODAL';
export const RGA_SCHEDULED_REQUEST_SET_DELIVERY_SCHEDULES = 'RGA_SCHEDULED_REQUEST_SET_DELIVERY_SCHEDULES';
export const RGA_SCHEDULED_REQUEST_APPLY_FILTERS = 'RGA_SCHEDULED_REQUEST_APPLY_FILTERS';

export const RG_SET_CONFIG_ID_TO_DISABLE = 'RG_SET_CONFIG_ID_TO_DISABLE';

// Report Generator Schedule Logs
export const RG_SET_SCHEDULE_EXECUTION_LOGS = 'RG_SET_SCHEDULE_EXECUTION_LOGS';
export const RG_SET_SCHEDULE_EXECUTION_LOGS_COUNT = 'RG_SET_SCHEDULE_EXECUTION_LOGS_COUNT';
export const RG_SCHEDULE_LOGS_SET_RESULTS_PER_PAGE = 'RG_SCHEDULE_LOGS_SET_RESULTS_PER_PAGE';
export const RG_SCHEDULE_LOGS_SET_CURRENT_PAGE = 'RG_SCHEDULE_LOGS_SET_CURRENT_PAGE';
export const RG_SCHEDULE_LOGS_SET_SORTING = 'RG_SCHEDULE_LOGS_SET_SORTING';
export const RG_SCHEDULE_LOGS_SET_FILTERS = 'RG_SCHEDULE_LOGS_SET_FILTERS';
export const RG_SCHEDULE_LOGS_RESET_FILTERS = 'RG_SCHEDULE_LOGS_RESET_FILTERS';
export const RG_UPDATE_SCHEDULE_EXECUTION_LOG = 'RG_UPDATE_SCHEDULE_EXECUTION_LOG';

// Integration Partners Dashboard
export const INTEGRATION_PARTNER_DASHBOARD_UPDATE_PAGINATION = 'INTEGRATION_PARTNER_DASHBOARD_UPDATE_PAGINATION';
export const INTEGRATION_PARTNER_DASHBOARD_UPDATE_FILTER = 'INTEGRATION_PARTNER_DASHBOARD_UPDATE_FILTER';
export const INTEGRATION_PARTNER_DASHBOARD_SET_RULES = 'INTEGRATION_PARTNER_DASHBOARD_SET_RULES';
export const INTEGRATION_PARTNER_DASHBOARD_SET_WLPS = 'INTEGRATION_PARTNER_DASHBOARD_SET_WLPS';
export const INTEGRATION_PARTNER_DASHBOARD_SET_FUNDS = 'INTEGRATION_PARTNER_DASHBOARD_SET_FUNDS';
export const INTEGRATION_PARTNER_DASHBOARD_SET_TRIGGER = 'INTEGRATION_PARTNER_DASHBOARD_SET_TRIGGER';
export const INTEGRATION_PARTNER_DASHBOARD_SET_DOC_TYPES = 'INTEGRATION_PARTNER_DASHBOARD_SET_DOC_TYPES';
export const INTEGRATION_PARTNER_DASHBOARD_SET_INT_PARTNERS = 'INTEGRATION_PARTNER_DASHBOARD_SET_INT_PARTNERS';
export const INTEGRATION_PARTNER_DASHBOARD_SET_PAGINATION = 'INTEGRATION_PARTNER_DASHBOARD_SET_PAGINATION';

// Integration Partner Templates
export const INTEGRATION_PARTNER_TEMPLATES_SET_INT_PARTNERS = 'INTEGRATION_PARTNER_TEMPLATES_SET_INT_PARTNERS';
export const INTEGRATION_PARTNER_TEMPLATES_SET_FUNDS = 'INTEGRATION_PARTNER_TEMPLATES_SET_FUNDS';
export const INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATES = 'INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATES';
export const INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATE = 'INTEGRATION_PARTNER_TEMPLATES_SET_BASE_TEMPLATE';
export const INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATES = 'INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATES';
export const INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATE = 'INTEGRATION_PARTNER_TEMPLATES_SET_TEMPLATE';
export const INTEGRATION_PARTNER_TEMPLATES_UPDATE_PAGINATION = 'INTEGRATION_PARTNER_TEMPLATES_UPDATE_PAGINATION';
export const INTEGRATION_PARTNER_TEMPLATES_UPDATE_FILTER = 'INTEGRATION_PARTNER_TEMPLATES_UPDATE_FILTER';

// Flexible Feed Framework Dashboard
export const FLEXIBLE_FEED_DASHBOARD_SET_SNACKBAR = '[FLEXIBLE FEED DASHBOARD] SET_SNACKBAR';
export const FLEXIBLE_FEED_DASHBOARD_CLEAR_SNACKBAR = '[FLEXIBLE FEED DASHBOARD] CLEAR_SNACKBAR';
export const FLEXIBLE_FEED_DASHBOARD_SET_BREADCRUMBS = '[FLEXIBLE FEED DASHBOARD] SET_BREADCRUMBS';
export const FLEXIBLE_FEED_DASHBOARD_SET_BASE_TEMPLATES = '[FLEXIBLE FEED DASHBOARD] SET_BASE_TEMPLATES';
export const FLEXIBLE_FEED_DASHBOARD_SET_BASE_TEMPLATE = '[FLEXIBLE FEED DASHBOARD] SET_BASE_TEMPLATE';
export const FLEXIBLE_FEED_DASHBOARD_CLEAR_BASE_TEMPLATE = '[FLEXIBLE FEED DASHBOARD] CLEAR_BASE_TEMPLATE';
export const FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATES = '[FLEXIBLE FEED DASHBOARD] SET_TEMPLATES';
export const FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATES_PAGINATION = '[FLEXIBLE FEED DASHBOARD] SET_TEMPLATES_PAGINATION';
export const FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATE = '[FLEXIBLE FEED DASHBOARD] SET_TEMPLATE';
export const FLEXIBLE_FEED_DASHBOARD_CLEAR_TEMPLATE = '[FLEXIBLE FEED DASHBOARD] CLEAR_TEMPLATE';
export const FLEXIBLE_FEED_DASHBOARD_SET_INTEGRATION_PARTNERS = '[FLEXIBLE FEED DASHBOARD] SET_INTEGRATION_PARTNERS';
export const FLEXIBLE_FEED_DASHBOARD_SET_PRIVATE_ACCESS_FUNDS = '[FLEXIBLE FEED DASHBOARD] SET_PRIVATE_ACCESS_FUNDS';
export const FLEXIBLE_FEED_DASHBOARD_SET_WHITE_LABEL_PARTNERS = '[FLEXIBLE FEED DASHBOARD] SET_WHITE_LABEL_PARTNERS';
export const FLEXIBLE_FEED_DASHBOARD_SET_FIRMS = '[FLEXIBLE FEED DASHBOARD] SET_FIRMS';
export const FLEXIBLE_FEED_DASHBOARD_GET_GENERAL_LEDGER_ACCOUNTS =
  '[FLEXIBLE FEED DASHBOARD] GET_GENERAL_LEDGER_ACCOUNTS';
export const FLEXIBLE_FEED_DASHBOARD_SET_FILTER_OPTIONS =
  '[FLEXIBLE FEED DASHBOARD] FLEXIBLE_FEED_DASHBOARD_SET_FILTER_OPTIONS';
export const FLEXIBLE_FEED_DASHBOARD_CLEAR_FILTERS =
  '[FLEXIBLE FEED DASHBOARD] FLEXIBLE_FEED_DASHBOARD_CLEAR_FILTER_OPTIONS';
export const FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATES_DOWNLOADING = '[FLEXIBLE FEED DASHBOARD] SET_TEMPLATES_DOWNLOADING';
export const FLEXIBLE_FEED_DASHBOARD_GET_PROVIDERS = '[FLEXIBLE FEED DASHBOARD] GET_PROVIDERS';
export const FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATE_TAGS = '[FLEXIBLE FEED DASHBOARD] SET_TEMPLATE_TAGS';

// Investor Representative
export const IR_ADD_INVESTOR = 'IR_ADD_INVESTOR';
export const IR_SET_INVESTOR = 'IR_SET_INVESTOR';
export const IR_SET_INVESTORS = 'IR_SET_INVESTORS';
export const IR_SET_MANAGERS = 'IR_SET_MANAGERS';
export const IR_SET_QUESTIONNAIRE = 'IR_SET_QUESTIONNAIRE';
export const IR_SET_QUESTIONNAIRE_INITIAL_STATE = 'IR_SET_QUESTIONNAIRE_INITIAL_STATE';
export const IR_SET_QUESTIONNAIRE_OPTIONS = 'IR_SET_QUESTIONNAIRE_OPTIONS';
export const IR_SET_SUCCESS_TOAST_MESSAGE = 'IR_SET_SUCCESS_TOAST_MESSAGE';
export const IR_SET_ERROR_TOAST_MESSAGE = 'IR_SET_ERROR_TOAST_MESSAGE';
export const IR_CLEAN_TOAST_MESSAGES = 'IR_CLEAN_TOAST_MESSAGES';
export const IR_SET_IS_FETCHING = 'IR_SET_IS_FETCHING';

// Icn Geo Countries
export const FETCH_ICN_GEO_COUNTRIES = 'FETCH_ICN_GEO_COUNTRIES';

// fund_finances
export const FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS_LIST = 'FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS_LIST';
export const FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS = 'FUND_FINANCES_SYNTHESIS_SET_INDEX_MASTERS';
export const FUND_FINANCES_SYNTHESIS_SET_YEARS_PERIOD = 'FUND_FINANCES_SYNTHESIS_SET_YEARS_PERIOD';
export const FUND_FINANCES_SYNTHESIS_CHANGE_PERIOD = 'FUND_FINANCES_SYNTHESIS_CHANGE_PERIOD';
export const FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS_LIST = 'FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS_LIST';
export const FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS = 'FUND_FINANCES_SYNTHESIS_SET_FUND_GROUPS';
export const FUND_FINANCES_SYNTHESIS_SET_FUND_LIST = 'FUND_FINANCES_SYNTHESIS_SET_FUND_LIST';
export const FUND_FINANCES_SYNTHESIS_SET_FUNDS = 'FUND_FINANCES_SYNTHESIS_SET_FUNDS';
export const FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASS_LIST = 'FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASS_LIST';
export const FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASSES = 'FUND_FINANCES_SYNTHESIS_SET_SHARE_CLASSES';
export const FUND_FINANCES_SYNTHESIS_SET_RETURN_TYPES = 'FUND_FINANCES_SYNTHESIS_SET_RETURN_TYPES';
export const FUND_FINANCES_SYNTHESIS_SET_RESULTS_TOTAL = 'FUND_FINANCES_SYNTHESIS_SET_RESULTS_TOTAL';
export const FUND_FINANCES_SYNTHESIS_SET_GRID_STATE = 'FUND_FINANCES_SYNTHESIS_SET_GRID_STATE';
export const FUND_FINANCES_SYNTHESIS_SET_LOADED_GRID_STATE = 'FUND_FINANCES_SYNTHESIS_SET_LOADED_GRID_STATE';
export const FUND_FINANCES_SYNTHESIS_SET_ALL_FILTERS = 'FUND_FINANCES_SYNTHESIS_SET_ALL_FILTERS';

// export synthesis modal
export const EXPORT_MODAL_SYNTHESIS_SET_INDEX_MASTERS = 'EXPORT_MODAL_SYNTHESIS_SET_INDEX_MASTERS';
export const EXPORT_MODAL_SYNTHESIS_SET_PERIOD = 'EXPORT_MODAL_SYNTHESIS_SET_PERIOD';
export const EXPORT_MODAL_SYNTHESIS_SET_SHARE_CLASSES = 'EXPORT_MODAL_SYNTHESIS_SET_SHARE_CLASSES';
export const EXPORT_MODAL_SYNTHESIS_SET_REPORT_TYPES = 'EXPORT_MODAL_SYNTHESIS_SET_REPORT_TYPES';
export const EXPORT_MODAL_SYNTHESIS_SET_RETURN_TYPES = 'EXPORT_MODAL_SYNTHESIS_SET_RETURN_TYPES';
export const EXPORT_MODAL_SYNTHESIS_SET_FUND_GROUPS = 'EXPORT_MODAL_SYNTHESIS_SET_FUND_GROUPS';
export const EXPORT_MODAL_SYNTHESIS_SET_FUNDS = 'EXPORT_MODAL_SYNTHESIS_SET_FUNDS';
export const EXPORT_MODAL_SYNTHESIS_LOAD_INDEX_MASTER_LIST = 'EXPORT_MODAL_SYNTHESIS_LOAD_INDEX_MASTER_LIST';
export const EXPORT_MODAL_SYNTHESIS_LOAD_SHARE_CLASS_LIST = 'EXPORT_MODAL_SYNTHESIS_LOAD_SHARE_CLASS_LIST';
export const EXPORT_MODAL_SYNTHESIS_LOAD_FUND_GROUP_LIST = 'EXPORT_MODAL_SYNTHESIS_LOAD_FUND_GROUP_LIST';
export const EXPORT_MODAL_SYNTHESIS_LOAD_FUND_LIST = 'EXPORT_MODAL_SYNTHESIS_LOAD_FUND_LIST';

// refresh data synthesis
export const REFRESH_DATE_SELECTION = 'REFRESH_DATE_SELECTION';
export const REFRESH_MODE_SELECTION = 'REFRESH_MODE_SELECTION';

// Levenshtein Matcher
export const LM_SET_SVC_STATE = 'LM_SET_SVC_STATE';
export const LM_SET_POLL_DATA = 'LM_SET_POLL_DATA';

// Registration Account Types
export const RAT_LOAD_ACCOUNT_TYPES = 'RAT_LOAD_ACCOUNT_TYPES';
export const RAT_SET_ACCOUNT_TYPE = 'RAT_SET_ACCOUNT_TYPE';

// FIRMS
export const FIRM_GET_FIRMS_FOR_REGISTRATION = 'FIRM_GET_FIRMS_FOR_REGISTRATION';
export const FIRM_HAS_KNOWN_DOMAIN = 'FIRM_HAS_KNOWN_DOMAIN';

// Registration
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_UPDATE_STEP = 'REGISTER_UPDATE_STEP';
export const REGISTER_SET_PROFILE = 'REGISTER_SET_PROFILE';
export const REGISTER_SET_PROFILE_INITIAL_STATE = 'REGISTER_SET_PROFILE_INITIAL_STATE';
export const REGISTER_SET_PROFILE_OPTIONS = 'REGISTER_SET_PROFILE_OPTIONS';
export const REGISTER_SET_INITIAL_STATE = 'REGISTER_SET_INITIAL_STATE';
export const REGISTER_SET_USER_INVITE = 'REGISTER_SET_USER_INVITE';
export const REGISTER_SET_QUALIFICATION_QUESTIONS = 'REGISTER_SET_QUALIFICATION_QUESTIONS';
export const REGISTER_UPDATE_QUALIFICATION_QUESTIONS = 'REGISTER_UPDATE_QUALIFICATION_QUESTIONS';
export const REGISTER_INVITED_USERS = 'REGISTER_INVITED_USERS';
export const REGISTER_USER_INVITE_OPEN_REMINDER_MODAL = 'REGISTER_USER_INVITE_OPEN_REMINDER_MODAL';
export const REGISTER_USER_INVITE_CLOSE_REMINDER_MODAL = 'REGISTER_USER_INVITE_CLOSE_REMINDER_MODAL';
export const REGISTER_SET_IS_LOADING = 'REGISTER_SET_IS_LOADING';

// AML Suitability Representations
export const AS_LOAD_REPRESENTATIONS_SUCCESS = 'AS_LOAD_REPRESENTATIONS_SUCCESS';
export const AS_LOAD_REPRESENTATIONS_COUNT = 'AS_LOAD_REPRESENTATIONS_COUNT';
export const AS_REMOVE_REPRESENTATION = 'AS_REMOVE_REPRESENTATION';
export const AS_LOAD_RIAS_FIRMS_SUCCESS = 'AS_LOAD_RIAS_FIRMS_SUCCESS';
export const AS_LOAD_WHITE_LABEL_PARTNERS_FIRMS_SUCCESS = 'AS_LOAD_WHITE_LABEL_PARTNERS_FIRMS_SUCCESS';
export const AS_LOAD_FUNDS_SUCCESS = 'AS_LOAD_FUNDS_SUCCESS';
export const AS_LOAD_FILTER_CONTENTS_SUCCESS = 'AS_LOAD_FILTER_CONTENTS_SUCCESS';
export const AS_SET_ACTIVE_MODAL = 'AS_SET_ACTIVE_MODAL';
export const AS_SET_SELECTED_ROW = 'AS_SET_SELECTED_ROW';
export const AS_SET_SELECTED_WLPS = 'AS_SET_SELECTED_WLPS';
export const AS_SET_SELECTED_FUNDS = 'AS_SET_SELECTED_FUNDS';
export const AS_SELECTED_RIAS_FIRMS = 'AS_SELECTED_RIAS_FIRMS';
export const AS_SELECTED_REP_TYPE = 'AS_SELECTED_REP_TYPE';
export const AS_SET_RESULTS_PER_PAGE = 'AS_SET_RESULTS_PER_PAGE';
export const AS_SET_CURRENT_PAGE = 'AS_SET_CURRENT_PAGE';
export const AS_SET_SORTING = 'AS_SET_SORTING';

// Secure Document Transfer
export const SECURE_DOCUMENT_TRANSFER_SET_TRANSFER_OPTIONS = 'SECURE_DOCUMENT_TRANSFER_SET_TRANSFER_OPTIONS';
export const SECURE_DOCUMENT_TRANSFER_SET_DOWNLOAD_DOCUMENTS = 'SECURE_DOCUMENT_TRANSFER_SET_DOWNLOAD_DOCUMENTS';
export const SECURE_DOCUMENT_TRANSFER_SET_AUTHENTICATION_CODE = 'SECURE_DOCUMENT_TRANSFER_SET_AUTHENTICATION_CODE';
export const SECURE_DOCUMENT_TRANSFER_SET_VERIFY_CODE = 'SECURE_DOCUMENT_TRANSFER_SET_VERIFY_CODE';
export const SECURE_DOCUMENT_TRANSFER_SET_PAGE_STATE = 'SECURE_DOCUMENT_TRANSFER_SET_PAGE_STATE';

// Admin Search
export const ADMS_SET_SEARCH_RESULTS_LOADED = 'ADMS_SET_SEARCH_RESULTS_LOADED';
export const ADMS_SET_SEARCH_KEYWORD = 'ADMS_SET_SEARCH_KEYWORD';
export const ADMS_SET_CURRENT_RESULTS = 'ADMS_SET_CURRENT_RESULTS';
export const ADMS_SET_IS_ADMIN_SEARCH_OPEN = 'ADMS_SET_IS_ADMIN_SEARCH_OPEN';
export const ADMS_SET_IS_PREVIEW_RESULTS_OPEN = 'ADMS_SET_IS_PREVIEW_RESULTS_OPEN';
export const ADMS_SET_IS_SEARCH_BUTTON_ENABLE = 'ADMS_SET_IS_SEARCH_BUTTON_ENABLE';
export const ADMS_SET_IS_SEARCH_INPUT_DISABLE = 'ADMS_SET_IS_SEARCH_INPUT_DISABLE';
export const ADMS_SET_TABS = 'ADMS_SET_TABS';
export const ADMS_SET_SELECTED_TAB = 'ADMS_SET_SELECTED_TAB';
export const ADMS_SET_SELECTED_RESULT = 'ADMS_SET_SELECTED_RESULT';
export const ADMS_SET_SELECTED_RESULT_POSITION = 'ADMS_SET_SELECTED_RESULT_POSITION';
export const ADMS_SET_RESULTS_PER_PAGE = 'ADMS_SET_RESULTS_PER_PAGE';
export const ADMS_SET_CURRENT_PAGE = 'ADMS_SET_CURRENT_PAGE';
export const ADMS_SET_RELATED_INFO_DATA = 'ADMS_SET_RELATED_INFO_DATA';
export const ADMS_SET_IS_EDIT_CONTACT_CARD_EMAIL_MODAL_OPEN = 'ADMS_SET_IS_EDIT_CONTACT_CARD_EMAIL_MODAL_OPEN';
export const ADMS_SET_EDIT_CONTACT_CARD_EMAIL_ERRORS = 'ADMS_SET_EDIT_CONTACT_CARD_EMAIL_ERRORS';
export const ADMS_SET_RELATED_TABLES_PAGES = 'ADMS_SET_RELATED_TABLES_PAGES';
export const ADMS_SET_CONTACT_CARD_TO_EDIT = 'ADMS_SET_CONTACT_CARD_TO_EDIT';
export const ADMS_SET_EDIT_TYPE = 'ADMS_SET_EDIT_TYPE';
export const ADMS_SET_CLONED_USER_PASSWORD = 'ADMS_SET_CLONED_USER_PASSWORD';
export const ADMS_SET_CLONED_USER_DATA = 'ADMS_SET_CLONED_USER_DATA';
export const ADMS_SET_PREVIOUS_PAGE_URL = 'ADMS_SET_PREVIOUS_PAGE_URL';

export const OKTA_AUTH_SET_TOKEN = 'OKTA_AUTH_SET_TOKEN';

// Document View Info
export const DVI_SELECT_FUND = 'DVI_SELECT_FUND';
export const DVI_SELECT_DOCUMENT_TYPE = 'DVI_SELECT_DOCUMENT_TYPE';
export const DVI_SELECT_ACT_DATE = 'DVI_SELECT_ACT_DATE';
export const DVI_SELECT_EFF_DATE = 'DVI_SELECT_EFF_DATE';
export const DVI_SET_FUNDS = 'DVI_SET_FUNDS';
export const DVI_SET_DOCUMENT_TYPES = 'DVI_SET_DOCUMENT_TYPES';
export const DVI_SET_SORTING = 'DVI_SET_SORTING';
export const DVI_LOADING_FUNDS = 'DVI_LOADING_FUNDS';
export const DVI_SET_HIT_RECORDS = 'DVI_SET_HIT_RECORDS';
export const DVI_UPDATE_PAGINATION = 'DVI_UPDATE_PAGINATION';
export const DVI_UPDATE_TOTAL_RESULTS = 'DVI_UPDATE_TOTAL_RESULTS';

// Team Management
export const TM_SET_ACCOUNT_PROFILES = 'TM_SET_ACCOUNT_PROFILES';
export const TM_SET_AP_REFERRAL_CODES_FILTER = 'TM_SET_AP_REFERRAL_CODES_FILTER';
export const TM_SET_AP_ACCOUNT_PROFILES_FILTER = 'TM_SET_AP_ACCOUNT_PROFILES_FILTER';
export const TM_SET_AP_BRANCH_CODES_FILTER = 'TM_SET_AP_BRANCH_CODES_FILTER';
export const TM_SET_AP_SELECTED_ACCOUNTS_FILTER = 'TM_SET_AP_SELECTED_ACCOUNTS_FILTER';
export const TM_SET_AP_SELECTED_REF_CODES_FILTER = 'TM_SET_AP_SELECTED_REF_CODES_FILTER';
export const TM_SET_AP_SELECTED_BRANCH_CODES_FILTER = 'TM_SET_AP_SELECTED_BRANCH_CODES_FILTER';
export const TM_SET_AP_SELECTED_ACCOUNTS_OPTIONS_FILTER = 'TM_SET_AP_SELECTED_ACCOUNTS_OPTIONS_FILTER';
export const TM_SET_AP_SELECTED_REF_CODES_OPTIONS_FILTER = 'TM_SET_AP_SELECTED_REF_CODES_OPTIONS_FILTER';
export const TM_SET_AP_SELECTED_BRANCH_CODES_OPTIONS_FILTER = 'TM_SET_AP_SELECTED_BRANCH_CODES_OPTIONS_FILTER';
export const TM_SET_RC_ASSIGNABLE_REFERRAL_CODES = 'TM_SET_RC_ASSIGNABLE_REFERRAL_CODES';
export const TM_TOGGLE_EDIT_TEAM_MODAL_OPEN = 'TM_TOGGLE_EDIT_TEAM_MODAL_OPEN';
export const TM_TOGGLE_DELETE_TEAM_MODAL_OPEN = 'TM_TOGGLE_DELETE_TEAM_MODAL_OPEN';
export const TM_SET_RC_ASSIGNABLE_USERS = 'TM_SET_RC_ASSIGNABLE_USERS';
export const TM_RESET_RC_ASSIGNABLE_USERS = 'TM_RESET_RC_ASSIGNABLE_USERS';
export const TM_SET_RC_REFERRAL_CODE_USERS = 'TM_SET_RC_REFERRAL_CODE_USERS';
export const TM_RESET_RC_REFERRAL_CODE_USERS = 'TM_RESET_RC_REFERRAL_CODE_USERS';
export const TM_SET_RC_USER_REFERRAL_CODES = 'TM_SET_RC_USER_REFERRAL_CODES';
export const TM_RESET_RC_USER_REFERRAL_CODES = 'TM_RESET_RC_USER_REFERRAL_CODES';
export const TM_SET_TEAMS_REFERRAL_CODES = 'TM_SET_TEAMS_REFERRAL_CODES';
export const TM_SET_TEAMS_REFERRAL_CODES_FILTER = 'TM_SET_TEAMS_REFERRAL_CODES_FILTER';
export const TM_SET_TEAMS_USERS_FILTER = 'TM_SET_TEAMS_USERS_FILTER';
export const TM_SET_TEAMS_BRANCH_CODES_FILTER = 'TM_SET_TEAMS_BRANCH_CODES_FILTER';
export const TM_SET_TEAMS_SELECTED_REFERRAL_CODES_FILTER = 'TM_SET_TEAMS_SELECTED_REFERRAL_CODES_FILTER';
export const TM_SET_TEAMS_SELECTED_USERS_FILTER = 'TM_SET_TEAMS_SELECTED_USERS_FILTER';
export const TM_SET_TEAMS_SELECTED_BRANCH_CODES_FILTER = 'TM_SET_TEAMS_SELECTED_BRANCH_CODES_FILTER';
export const TM_SET_TEAMS_SELECTED_REFERRAL_CODES_OPTIONS_FILTER =
  'TM_SET_TEAMS_SELECTED_REFERRAL_CODES_OPTIONS_FILTER';
export const TM_SET_TEAMS_SELECTED_USERS_OPTIONS_FILTER = 'TM_SET_TEAMS_SELECTED_USERS_OPTIONS_FILTER';
export const TM_SET_TEAMS_SELECTED_BRANCH_CODES_OPTIONS_FILTER = 'TM_SET_TEAMS_SELECTED_BRANCH_CODES_OPTIONS_FILTER';
export const TM_RESET_SELECTED_TEAMS_FILTERS = 'TM_RESET_SELECTED_TEAMS_FILTERS';
export const TM_SET_ASSIGNABLE_REFERRAL_CODES_COUNT = 'TM_SET_ASSIGNABLE_REFERRAL_CODES_COUNT';
export const TM_SET_BC_ASSIGNABLE_BRANCH_CODES = 'TM_SET_BC_ASSIGNABLE_BRANCH_CODES';
export const TM_SET_TEAM_INVITES = 'TM_SET_TEAM_INVITES';
export const TM_SET_TEAM_INVITES_FILTER = 'TM_SET_TEAM_INVITES_FILTER';
export const TM_SET_SELECTED_TEAM_INVITES_FILTER = 'TM_SET_SELECTED_TEAM_INVITES_FILTER';
export const TM_SET_BC_BRANCH_REFERRAL_CODES = 'TM_SET_BC_ASSIGNABLE_BRANCH_CODES';
export const TM_RESET_BC_BRANCH_REFERRAL_CODES = 'TM_RESET_BC_BRANCH_REFERRAL_CODES';

// Data Reconciliation
export const DR_LOAD_DATA_RECONS = '[DATA RECONCILIATION] LOAD_DATA_RECONS';
export const DR_LOAD_DATA_RECON_FILES = '[DATA RECONCILIATION] LOAD_DATA_RECON_FILES';
export const DR_LOAD_DATA_RECONS_STATISTICS = '[DATA RECONCILIATION] LOAD_DATA_RECONS_STATISTICS';
export const DR_CREATE_RECONCILIATION = '[DATA RECONCILIATION] CREATE_RECONCILIATION';
export const DR_CREATE_BULK_RECONCILIATION = '[DATA RECONCILIATION] CREATE_BULK_RECONCILIATION';
export const DR_RETRY_RECONCILIATION = '[DATA RECONCILIATION] RETRY_RECONCILIATION';
export const DR_RETRY_BULK_RECONCILIATION = '[DATA RECONCILIATION] RETRY_BULK_RECONCILIATION';
export const DR_UPLOAD_RECONCILIATION = '[DATA RECONCILIATION] UPLOAD_RECONCILIATION';
export const DR_UPLOAD_BULK_RECONCILIATION = '[DATA RECONCILIATION] UPLOAD_BULK_RECONCILIATION';
export const DR_APPROVE_RECONCILIATION = '[DATA RECONCILIATION] APPROVE_RECONCILIATION';
export const DR_APPROVE_BULK_RECONCILIATION = '[DATA RECONCILIATION] APPROVE_BULK_RECONCILIATION';
export const DR_STOP_RECONCILIATION = '[DATA RECONCILIATION] STOP_RECONCILIATION';
export const DR_STOP_BULK_RECONCILIATION = '[DATA RECONCILIATION] STOP_BULK_RECONCILIATION';
export const DR_LOAD_PRIVATE_ACCESS_FUNDS = '[DATA RECONCILIATION] LOAD_PRIVATE_ACCESS_FUNDS';
export const DR_LOAD_FUND_ADMIN_GROUPS = '[DATA RECONCILIATION] LOAD_FUND_ADMIN_GROUPS';
export const DR_LOAD_FUND_ADMINS = '[DATA RECONCILIATION] LOAD_FUND_ADMINS';
export const DR_LOAD_RECONCILIATION_TYPES = '[DATA RECONCILIATION] LOAD_RECONCILIATION_TYPES';
export const DR_LOAD_RECONCILIATION_PERIODS = '[DATA RECONCILIATION] LOAD_RECONCILIATION_PERIODS';
export const DR_GET_SETTINGS = '[DATA RECONCILIATION] GET_RECONCILIATION_SETTINGS';
export const DR_SAVE_SETTINGS = '[DATA RECONCILIATION] SAVE_RECONCILIATION_SETTINGS';

// Compliance
export const COM_SET_FUNDS = 'COM_SET_FUNDS';

// Invalid domain email
export const SET_CUSTOM_ANALYZERS = 'SET_CUSTOM_ANALYZERS';

// Operation Documents notes
export const TOGGLE_NOTES_MODAL = 'TOGGLE_NOTES_MODAL';
export const OPERATION_DOCS_SET_NOTES = 'OPERATION_DOCS_SET_NOTES';

// Published Document Export
export const PDE_SHOW = 'PDE_SHOW';
export const PDE_CREATE = 'PDE_CREATE';
export const PDE_UPDATE = 'PDE_UPDATE';
export const PDE_DELETE = 'PDE_DELETE';
export const PDE_EDIT = 'PDE_EDIT';
export const PDE_SET_DATA = 'PDE_SET_DATA';
export const PDE_RUN = 'PDE_RUN';
export const PDE_SET_FUNDS = 'PDE_SET_FUNDS';
export const PDE_SET_FIRMS = 'PDE_SET_FIRMS';

// Fund Profile
export * from 'modules/fund_profile/actionsConstants';

// PDF Viewer
export * from 'modules/pdf_viewer/state/actionsConstants';

// Access Agreements
export const AA_OPEN_AGREEMENTS_MODAL = 'AA_OPEN_AGREEMENTS_MODAL';
export const AA_CLOSE_AGREEMENTS_MODAL = 'AA_CLOSE_AGREEMENTS_MODAL';
export const AA_ACCEPT_AGREEMENT = 'AA_ACCEPT_AGREEMENT';
export const AA_ACCEPT_LEGACY_AGREEMENT = 'AA_ACCEPT_LEGACY_AGREEMENT';
export const AA_SET_REQUEST_FAILED = 'AA_SET_REQUEST_FAILED';

// Nominee Review
export const NR_FETCH_NOMINEE_REVIEW_LIST = 'NR_FETCH_NOMINEE_REVIEW_LIST';
export const NR_FETCH_NOMINEE_REVIEW_DETAILS = 'NR_FETCH_NOMINEE_REVIEW_DETAILS';
export const NR_NOMINEE_REVIEW_MODAL = 'NR_NOMINEE_REVIEW_MODAL';
export const NR_NOMINEE_REVIEW_MODAL_WITH_CONTEXT = 'NR_NOMINEE_REVIEW_MODAL_WITH_CONTEXT';
export const NR_NOMINEE_REVIEW_EXPORT_HISTORY = 'NR_NOMINEE_REVIEW_EXPORT_HISTORY';
export const NR_FETCH_SR_NOMINEE_REVIEW_DETAILS = 'NR_FETCH_SR_NOMINEE_REVIEW_DETAILS';
export const NR_CLEAR_SR_NOMINEE_REVIEW_DETAILS = 'NR_CLEAR_SR_NOMINEE_REVIEW_DETAILS';
export const NR_NOMINEE_REVIEW_PAGE_TABLE = 'NR_NOMINEE_REVIEW_PAGE_TABLE';
export const NR_NOMINEE_REVIEW_PER_PAGE_TABLE = 'NR_NOMINEE_REVIEW_PER_PAGE_TABLE';
export const NR_NOMINEE_REVIEW_ACCOUNT_ID_FILTER = 'NR_NOMINEE_REVIEW_ACCOUNT_ID_FILTER';
export const NR_NOMINEE_FIELD_OPTIONS = 'NR_NOMINEE_FIELD_OPTIONS';
export const NR_UPDATE_NOMINEE_REVIEW_ALLOCATION = 'NR_UPDATE_NOMINEE_REVIEW_ALLOCATION';

// Investor Qualification Standard Questions
export const SET_INVESTOR_QUALIFICATION_STANDARD_QUESTIONS = 'SET_INVESTOR_QUALIFICATION_STANDARD_QUESTIONS';
export const UPDATE_INVESTOR_QUALIFICATION_STANDARDS_QUESTION = 'UPDATE_INVESTOR_QUALIFICATION_STANDARDS_QUESTION';

// User Acknowledgments
export const SAVE_ACKNOWLEDGMENTS = 'SAVE_ACKNOWLEDGMENTS';
export const GET_LAST_ACKNOWLEDGMENT = 'GET_LAST_ACKNOWLEDGMENT';

// Consent Form
export const CONSENT_FORM_SET_CONTACT_CARD_REQUEST = 'CONSENT_FORM_SET_CONTACT_CARD_REQUEST';
export const CONSENT_FORM_SET_AUTHENTICATION_CODE = 'CONSENT_FORM_SET_AUTHENTICATION_CODE';
export const CONSENT_FORM_SET_VERIFY_CODE = 'CONSENT_FORM_SET_VERIFY_CODE';
export const CONSENT_FORM_SET_PAGE_STATE = 'CONSENT_FORM_SET_PAGE_STATE';
export const CONSENT_FORM_SET_FORM_SUBMITTED = 'CONSENT_FORM_SET_FORM_SUBMITTED';
