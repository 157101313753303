/* eslint-disable max-lines */

const isRiaQpSfoAdminOrIr = (user) =>
  user.isRia || user.isQp || user.isSfo || user.isAdminOrIr;

const isIndividualInvestor = (user) =>
  user.isAi || user.isAicl || user.isQp || user.isQpcl || user.isSfo;

const isQp = (user) => user.isQp;

const isQpcl = (user) => user.isQpcl;

const isEmpAdmin = (user) => user.isAdmin;

export const isRia = (user) => user?.isRia;

export const isSfo = (user) => user?.isSfo;

const isAi = (user) => user.isAi;

const isAicl = (user) => user.isAicl;

const isDocumentReviewer = (user) => user.isDocumentReviewer;

const isHomeOffice = (user) => user.isHomeOffice;

const isPafAdmin = (user) => user.isPafAdmin;

const isInvestor = (user) => user.isInvestor;

const isInvestorRelations = (user) => user.isInvestorRelations;

const isAdminOrIr = (user) => isPafAdmin(user) || isInvestorRelations(user);

const isAdminAndIr = (user) => isPafAdmin(user) && isInvestorRelations(user);

export const isAdmin = (user) => user.isPafAdmin || user.isInvestorRelations;

export const sequenceSignersRequired = (wlp) =>
  wlp && wlp.sequence_signers_required;

export const hideAddSignerLinkFromProfile = (wlp) =>
  wlp && wlp.hide_add_signer_link_from_profile;

const hideIcnSuffixFromProfile = (wlp) =>
  wlp && wlp.hide_icn_suffix_from_profile;

const isExternalApprover = (user) => user.isExternalApprover;

const isCsa = (user) => user.isCsa;

const isBranchManager = (user) => user.isBranchManager;

const isComplianceApprover = (user) => user.isComplianceApprover;

const isGpAdmin = (user) => user.isGpAdmin;

const isSynchronized = (user) => user.isSynchronized;

const canAccessArchitectSaml = (user) => user['canArchitect.access.saml'];
const isComplianceApproverAndNotDocumentReviewer = (user) =>
  user.isComplianceApprover && !user.isDocumentReviewer;

const isSalesManager = (user) => user.isSalesManager;

const isSalesManagerAdmin = (user) => user.isSalesManagerAdmin;

const isSalesManagerWithUnredactedSubReview = (user) =>
  (user.isSalesManager || user.isSalesManagerAdmin) &&
  user.canViewUnredactedSalesUserView;

const isSalesManagerWithPafAdmin = (user) =>
  user.isSalesManager && user.isPafAdmin;

const isFundAdmin = (user) =>
  user.isPafAdmin && !user.isDocumentReviewer && !user.isComplianceApprover;

const isRiaOrBranchManager = (user) => user.isRia || user.isBranchManager;

const isAdvisorInvestor = (user) => user.isAdvisorInvestor;

const isFidelity = (custodian) =>
  custodian &&
  (custodian.name === 'Fidelity RIAs / Family Office' ||
    custodian.name === 'NFS Broker-Dealers');

const isFidelityRia = (custodian) =>
  custodian && custodian.name === 'Fidelity RIAs / Family Office';

const isMainSite = (wlp) => wlp.id === 0;

const isWhiteLabel = (icnBootstrap) =>
  icnBootstrap.white_label_partner && icnBootstrap.white_label_partner.id !== 0;
const whiteLabelPartner = (icnBootstrap) => icnBootstrap.white_label_partner;

const canShowAccountNum = (wlp) => wlp.show_account_num_on_ip_list;

const isUbsWlp = (privateAccessFund) => privateAccessFund.is_ubs_wlp;

const canSetShareClass = (user) => user.canUpdateShareClass;

const canViewApprovalHistory = (user) => user.canViewApprovalHistory;

const canViewEventsHistory = (user) => user.canViewEventsHistory;

const canViewInvestorProfiles = (user) => user.canViewInvestorProfiles;

const canHaveInvestments = (user) => user.canHaveInvestments;

const canAccessTransfers = (user) => user.canAccessTransfers;

const canManageTransfers = (user) => user.canManageTransfers;

const canInitiateTransfer = (user) => user.canInitiateTransfer;

const canReviewTransfers = (user, paf) =>
  user.canReviewTransfers ||
  (paf.is_direct_fund && user.canReviewTransfersDirectsOnly);

const canCancelTransfers = (user) => user.canCancelTransfers;

const canDestroyFile = (user) => user.canDestroyFile;

const canCancelInvestment = (user) => user.canCancelInvestment;

const canCreateLettersOfAuthorization = (user) =>
  user.canCreateLettersOfAuthorization;

const canCancelApprovedInvestment = (user) => user.canCancelApprovedInvestment;

const canRefreshDataExternalApi = (user) => user.canRefreshDataExternalApi;

const canChangeAccountExternalApi = (user) => user.canChangeAccountExternalApi;

const canDeleteInvestments = (user) => user.canDeleteInvestments;

const canSetCloseDates = (user) => user.canSetCloseDates;

const canEditCloseDateAcceptedInvestments = (user) =>
  user.isPafAdmin && user.canEditCloseDateAcceptedInvestments;
const canSetCloseDatesDirectsOnly = (user) => user.canSetCloseDatesDirectsOnly;

const canExportData = (user) => user.canExportData;

const canToggleFunded = (user) => user.canToggleFunded;

const canAdminSignOff = (user) => user.canAdminSignOff;

const canViewSupplementalForm = (user) =>
  user.canViewSupplementalForm || user.canEditSupplementalForm;

const canSaveIncompleteSupplementalForm = (user) =>
  user.canSaveIncompleteSuppForms;

const canEditInvestment = (user) =>
  (user.isDocumentReviewer || user.isHomeOffice) && user.canEditInvestment;

const canEditCommitmentOfApprovedInvestment = (user) =>
  user.isPafAdmin && user.canEditSubscriptionAmountApprovedInvestments;

const canBulkApproveInvestments = (user) => user.canBulkApproveInvestments;

const canBulkToggleFundedInvestments = (user) => user.canBulkToggleFunded;

const isReadOnlyUser = (user) =>
  (isSalesManager(user) || isHomeOffice(user) || isBranchManager(user)) &&
  !(
    isComplianceApprover(user) ||
    isDocumentReviewer(user) ||
    isExternalApprover(user)
  );

const isAllowedToManageExternalCloses = (user) =>
  isPafAdmin(user) ||
  isBranchManager(user) ||
  isHomeOffice(user) ||
  isExternalApprover(user);

const isAllowedToRecallInvestment = (user) =>
  user.canRecallInvestment &&
  (isPafAdmin(user) ||
    isBranchManager(user) ||
    isHomeOffice(user) ||
    isExternalApprover(user));

const isAllowedToResendOfferingMaterials = (user) =>
  isRia(user) ||
  isQp(user) ||
  isAi(user) ||
  isSfo(user) ||
  isBranchManager(user) ||
  isHomeOffice(user) ||
  isExternalApprover(user) ||
  isPafAdmin(user);

const isAllowedToReadOnly = (user) =>
  isReadOnlyUser(user) && !user.canEditSubscriptionReview;

const canExportSubscriptionFiles = (user) => user.canExportSubscriptionFiles;

const canExportRemediationCommentsFile = (user) =>
  user.canExportRemediationComments;

const canExportSignerDetails = (user) => user.canExportSignerDetails;

const canExportSubscriptionData = (user) =>
  user.canExportData &&
  (isPafAdmin(user) ||
    isInvestorRelations(user) ||
    isExternalApprover(user) ||
    isComplianceApprover(user) ||
    isHomeOffice(user) ||
    isDocumentReviewer(user) ||
    isBranchManager(user) ||
    isGpAdmin(user));

const canAddSubReviewNotes = (user) => user.canAddSubReviewNotes;

const canViewSubReviewNotes = (user) => user.canViewSubReviewNotes;

const canManageCloses = (user) => user.canManageCloses;

const canManageClosesDirectsOnly = (user) => user.canManageClosesDirectsOnly;

const canManageFundingStatus = (user) => user.canManageFundingStatus;

const isSalesManagerOnly = (user) =>
  isSalesManager(user) && !isSalesManagerAdmin(user);

const canViewUbsApiLogs = (user) => user.canViewUbsApiLogs;

const canExportApprovalHistory = (user) => user.canExportApprovalHistory;

const canApplyClientWatermarkPreApprovedInvestments = (user) =>
  user.canApplyClientWatermarkPreApprovedInvestments;

const canManageCloseReact = (user) => user.canManageCloseReact;

const canEditFormsReadonlyFields = (user) => user.canEditFormsReadonlyFields;

const canAddNewInvestors = (user) => user.canAddNewInvestors;

const canInviteSavedInvestors = (user) => user.canInviteSavedInvestors;

const isWhitelistedForPaf = (user, pafId) =>
  user.pafs_whitelisted_for_firm?.includes(pafId);

const canApproveDocumentReview = (user) => user.canApproveDocumentReview;

const canApproveExternalReview = (user) => user.canApproveExternalReview;

const canApproveExternalSecondaryReview = (user) =>
  user.canApproveExternalSecondaryReview;

const canApproveComplianceReview = (user) => user.canApproveComplianceReview;

const canApprovePreApproval = (user) => user.canApprovePreApproval;

const canApprovePreApprovalSecondary = (user) =>
  user.canApprovePreApprovalSecondary;

const canRevertApprovedInvestments = (user) =>
  user.canRevertApprovedInvestments;

const canCreateNewProfile = (user) => user.canCreateNewProfile;

const canEditDateOfBirth = (user) => user.canEditDateOfBirth;

const canSendSubscription = (user) =>
  (isInvestor(user) && !isRia(user)) || user.canSendSubscription;

const canResetSubscription = (user) =>
  isInvestor(user) || user.canResetSubscription;

const resendSubdocOnIncreaseOnly = (wlp) =>
  wlp.resend_subdoc_on_increase_only || false;

const canDownloadSubscription = (user) =>
  (isInvestor(user) && !isRia(user)) || user.canDownloadSubscription;

const canAccessClientManagement = (user) => user.canAccessClientManagement;

const canUploadSignedSubscription = (user) =>
  isInvestor(user) || user.canUploadSignedSubscription;
const canCompleteAdvisorAttestation = (user) =>
  user.canCompleteAdvisorAttestation;

const canAddExistingProspectInvestments = (user) =>
  user.canAddExistingProspectInvestments;

const canAddNewProspectInvestments = (user) =>
  user.canAddNewProspectInvestments;

const isNonRiaInvestor = (user) => user.isNonRiaInvestor;

const canEditInvestmentBlocksManager = (user) =>
  user.canEditInvestmentBlocksManager;

const canSetSubscriptionValue = (user) =>
  isInvestor(user) || user.canSetSubscriptionValue;

const canSendReminder = (user) => user.canSendReminder;

const canTeamManagementViewTeams = (user) => user.canTeamManagementViewTeams;

const canTeamManagementCreateDeleteTeams = (user) =>
  user.canTeamManagementCreateDeleteTeams;

const canInitiateAdditionalSubscription = (user) =>
  user.canInitiateAdditionalSubscription;

const canEnableException = (user) => user.user_unused_exceptions;

const canAddExternalNotes = (user) =>
  user.canAddExternalNotes && isPafAdmin(user);

const canOverrideInvestmentStatus = (user) => user.canOverrideInvestmentStatus;

const canFeeExportConfiguration = (user) => user.canFeeExportConfiguration;

const canSendToFidelity = (user) =>
  user.canSendToFidelity && (isRiaOrBranchManager(user) || isHomeOffice(user));

const canSendToGoldmanSachs = (user) => user.canSendToGoldmanSachs;

const canSendToPershing = (user) => user.canSendToPershing;

const canSendToCustodian = (user, custodian) => {
  const canSendFn = {
    nfs: canSendToFidelity,
    frfo: canSendToFidelity,
    goldman_sachs: canSendToGoldmanSachs,
    pershing: canSendToPershing,
  }[custodian?.slug];

  if (!canSendFn) return false;

  return canSendFn(user);
};

const canPublishAnnouncements = (user) => user.canPublishAnnouncements;

const canTranslateAnnouncements = (user) => user.canTranslateAnnouncements;

const canAddAnnouncements = (user) => user.canAddAnnouncements;

const canViewAnnouncements = (user) => user.canViewAnnouncements;

const canAddManagerUpdates = (user) => user.canAddManagerUpdates;

const canPublishIcapitalInsights = (user) => user.canPublishIcapitalInsights;

const canAddIcapitalInsights = (user) => user.canAddIcapitalInsights;

const enableRedemptions = (wlp) => wlp.enable_redemptions || isMainSite(wlp);

const canInitiateRedemption = (user) => user.canInitiateRedemption;

const canAccessRedemptionsDirectOnly = (user, paf, wlp) =>
  user.canEditRedemptionsDirectsOnly && paf.wlp_funds_with_access_redemption_direct_only.includes(wlp.id);

const canAccessRedemption = (user) => user.canEditRedemptions;

const canAssignTransferInvestor = (user) =>
  user.canAssignInvestorsTransfers &&
  [isRiaOrBranchManager, isAdminAndIr, isIndividualInvestor].some((fn) =>
    fn(user)
  );

const canChangeTransferInvestor = (user) =>
  user.canCancelInvestment && canAssignTransferInvestor(user);

const canEditSalesReportingShareInfo = (user) =>
  user.canEditSalesReportingShareInfo;

const canEditApprovedRequiredDocuments = (user) =>
  user.canEditApprovedRequiredDocuments;

const canReactLibraryTemplates = (user) => user.canReactLibraryTemplates;

const canUploadAmlDocuments = (user) =>
  isInvestor(user) || user.canUploadAmlDocuments;

const canViewReactFundProfiles = (user) => user.canViewReactFundProfiles;

const canFollowFund = (user) => user.canFollowFund;

const canApplyClientWatermarkAllSigners = (user) =>
  user.canApplyClientWatermarkAllSigners;

const canShareFundDocumentsAllSigners = (user) =>
  user.canShareFundDocumentsAllSigners;

const canShareFundDocumentsPreApproved = (user) =>
  user.canShareFundDocumentsPreApproved;

const canEditFrozenProfiles = (user, paf) =>
  user?.canEditFrozenProfiles && paf?.allow_edit_frozen_profiles;

const canShowBranchCodeOnCreateInvestment = (wlp) =>
  wlp.show_branch_code_on_create_investment;

const canCopyTemplateSchema = (user) => user.canCopyTemplateSchema;

const canResendOfferingMaterials = (user) => user.canResendOfferingMaterials;

const canViewRedemptionsSubscriptionReview = (user) =>
  user.canViewRedemptionsSubscriptionReview;

const hasReadOnlyRedemptionsAccess = (user) =>
  user.hasReadOnlyRedemptionsAccess;

const canRequestFund = (user) => user.canEnableFundRequests;

const canCloseFunds = (user) => user.canCloseFunds;

const canExecuteClose = (user) => user.canExecuteClose;

const canExecuteCloseDirectsOnly = (user) => user.canExecuteCloseDirectsOnly;

const canCloseFundsDirectsOnly = (user) => user.canCloseFundsDirectsOnly;

const canShowInvestmentIds = (wlp) => wlp.show_investment_ids;

const canMoveInvestmentsOnClose = (user) => user.canMoveInvestmentsOnClose;

// Product Access
const canAccessDefaultSettingsTab = (user) => user.canAccessDefaultSettingsTab;
const canAccessProductsTab = (user) => user.canAccessProductsTab;
const canAccessListingsTab = (user) => user.canAccessListingsTab;

const canAccessProductApprovalsTab = (user) =>
  user.canAccessProductApprovalsTab;

const canAccessProductApprovalsAdvisorTab = (user) =>
  user.canAccessProductApprovalsAdvisorTab;

const canAccessInvestingSpecialAccessTab = (user) =>
  user.canAccessInvestingSpecialAccessTab;

const canAccessVisibilitySpecialAccessTab = (user) =>
  user.canAccessVisibilitySpecialAccessTab;
const canAccessGroupsTab = (user) => user.canAccessGroupsTab;

const canAccessBannedFirms = (user) => user.canAccessBannedFirms;

// Edit Permissions
const canEditListingsVisibility = (user) => user.canEditListingsVisibility;
const canEditListingsVisibilitySpecialAccess = (user) => user.canEditListingsVisibilitySpecialAccess;
const canEditProductsVisibility = (user) => user.canEditProductsVisibility;
const canEditProductsVisibilitySpecialAccess = (user) => user.canEditProductsVisibilitySpecialAccess;
const canEditIsSuperAdminOrAdvisorAdmin = (user) => user.canEditIsSuperAdminOrAdvisorAdmin;
const canProductAccessSuperadmin = (user) => user.canProductAccessSuperadmin;

const canEditFundProfileTemplates = (user) => user.canEditFundProfileTemplates;

const canPublishFundProfileTemplates = (user) =>
  user.canPublishFundProfileTemplates;

const canEditFundProfiles = (user) => user.canEditFundProfiles;

const canPublishFundProfiles = (user) => user.canPublishFundProfiles;

const canManageLeadApprovers = (user) => user.canManageLeadApprovers;

const canAssignIndividualLeadApprover = (user) =>
  user.canAssignIndividualLeadApprover;

const canCancelApprovedRedemptions = (user) =>
  user.canCancelApprovedRedemptions;

const canEditPlacementFee = (user) => user.canEditPlacementFee;

const canBulkApproveComplianceReview = (user) =>
  user.canBulkApproveComplianceReview;

const canBulkApproveDocumentReview = (user) =>
  user.canBulkApproveDocumentReview;

const canAccessEsignHub = (user) => user.canAccessEsignHub;

const canManageBulkInvestments = (user) => user.canManageBulkInvestments;

const canViewInvestmentOpportunities = (user) => user.canViewInvestmentOpportunities;

const canSeeAdvisorNotes = (user) => user.canSeeAdvisorNotes;

const canSyncDataToDlt = (user) => user.canSyncDataToDlt;

export default {
  isRiaQpSfoAdminOrIr,
  isIndividualInvestor,
  isQp,
  isQpcl,
  isRia,
  isSfo,
  isAicl,
  isAi,
  isFidelity,
  isFidelityRia,
  isHomeOffice,
  isPafAdmin,
  isGpAdmin,
  isEmpAdmin,
  isCsa,
  isBranchManager,
  isInvestor,
  isInvestorRelations,
  isMainSite,
  isWhiteLabel,
  whiteLabelPartner,
  isUbsWlp,
  isAdmin,
  isSalesManager,
  isSalesManagerAdmin,
  isSalesManagerWithPafAdmin,
  isSalesManagerWithUnredactedSubReview,
  isComplianceApprover,
  isComplianceApproverAndNotDocumentReviewer,
  isDocumentReviewer,
  isExternalApprover,
  isReadOnlyUser,
  isAllowedToReadOnly,
  isFundAdmin,
  isRiaOrBranchManager,
  isAdvisorInvestor,
  isSynchronized,
  canShowAccountNum,
  isAllowedToManageExternalCloses,
  isAllowedToRecallInvestment,
  isAllowedToResendOfferingMaterials,
  canSetShareClass,
  canViewApprovalHistory,
  canViewEventsHistory,
  canViewInvestorProfiles,
  canHaveInvestments,
  isAdminOrIr,
  canAccessTransfers,
  canManageTransfers,
  canInitiateTransfer,
  canCancelTransfers,
  canReviewTransfers,
  canDestroyFile,
  canEditInvestment,
  canEditCommitmentOfApprovedInvestment,
  canCancelInvestment,
  canCancelApprovedInvestment,
  canRefreshDataExternalApi,
  canChangeAccountExternalApi,
  canDeleteInvestments,
  canViewSupplementalForm,
  canSaveIncompleteSupplementalForm,
  canSetCloseDates,
  canEditCloseDateAcceptedInvestments,
  canFollowFund,
  canSetCloseDatesDirectsOnly,
  canExportData,
  canToggleFunded,
  canAdminSignOff,
  canExportSubscriptionFiles,
  canExportSignerDetails,
  canExportSubscriptionData,
  canManageCloses,
  canManageClosesDirectsOnly,
  canManageFundingStatus,
  isSalesManagerOnly,
  canAddSubReviewNotes,
  canViewSubReviewNotes,
  canBulkApproveInvestments,
  canBulkToggleFundedInvestments,
  canViewUbsApiLogs,
  canExportApprovalHistory,
  canApplyClientWatermarkPreApprovedInvestments,
  canAddNewInvestors,
  canInviteSavedInvestors,
  isWhitelistedForPaf,
  canApproveDocumentReview,
  canApproveComplianceReview,
  canApprovePreApproval,
  canApprovePreApprovalSecondary,
  canCreateNewProfile,
  canEditDateOfBirth,
  canSendSubscription,
  canResetSubscription,
  resendSubdocOnIncreaseOnly,
  canDownloadSubscription,
  sequenceSignersRequired,
  hideAddSignerLinkFromProfile,
  canAccessClientManagement,
  canUploadSignedSubscription,
  canCompleteAdvisorAttestation,
  hideIcnSuffixFromProfile,
  canAddExistingProspectInvestments,
  canAddNewProspectInvestments,
  isNonRiaInvestor,
  canEditInvestmentBlocksManager,
  canExportRemediationCommentsFile,
  canSetSubscriptionValue,
  canSendReminder,
  canTeamManagementViewTeams,
  canTeamManagementCreateDeleteTeams,
  canInitiateAdditionalSubscription,
  canEnableException,
  canAddExternalNotes,
  canOverrideInvestmentStatus,
  canFeeExportConfiguration,
  canSendToFidelity,
  canSendToGoldmanSachs,
  canSendToPershing,
  canSendToCustodian,
  isAdminAndIr,
  canPublishAnnouncements,
  canTranslateAnnouncements,
  canAddAnnouncements,
  canViewAnnouncements,
  canAddManagerUpdates,
  canPublishIcapitalInsights,
  canAddIcapitalInsights,
  enableRedemptions,
  canInitiateRedemption,
  canAccessRedemptionsDirectOnly,
  canAccessRedemption,
  canAssignTransferInvestor,
  canChangeTransferInvestor,
  canManageCloseReact,
  canEditSalesReportingShareInfo,
  canEditApprovedRequiredDocuments,
  canEditFormsReadonlyFields,
  canReactLibraryTemplates,
  canUploadAmlDocuments,
  canViewReactFundProfiles,
  canApproveExternalReview,
  canApproveExternalSecondaryReview,
  canRevertApprovedInvestments,
  canShowBranchCodeOnCreateInvestment,
  canEditFrozenProfiles,
  canCopyTemplateSchema,
  canApplyClientWatermarkAllSigners,
  canShareFundDocumentsAllSigners,
  canShareFundDocumentsPreApproved,
  canResendOfferingMaterials,
  canViewRedemptionsSubscriptionReview,
  hasReadOnlyRedemptionsAccess,
  canCloseFunds,
  canExecuteClose,
  canExecuteCloseDirectsOnly,
  canCloseFundsDirectsOnly,
  canRequestFund,
  canShowInvestmentIds,
  canMoveInvestmentsOnClose,
  canAccessArchitectSaml,
  canAccessDefaultSettingsTab,
  canAccessProductsTab,
  canAccessListingsTab,
  canAccessProductApprovalsTab,
  canAccessProductApprovalsAdvisorTab,
  canAccessGroupsTab,
  canAccessBannedFirms,
  canEditFundProfileTemplates,
  canAccessInvestingSpecialAccessTab,
  canAccessVisibilitySpecialAccessTab,
  canPublishFundProfileTemplates,
  canEditFundProfiles,
  canPublishFundProfiles,
  canManageLeadApprovers,
  canAssignIndividualLeadApprover,
  canCancelApprovedRedemptions,
  canCreateLettersOfAuthorization,
  canEditPlacementFee,
  canBulkApproveComplianceReview,
  canBulkApproveDocumentReview,
  canAccessEsignHub,
  canManageBulkInvestments,
  canEditListingsVisibility,
  canEditListingsVisibilitySpecialAccess,
  canEditProductsVisibility,
  canEditProductsVisibilitySpecialAccess,
  canEditIsSuperAdminOrAdvisorAdmin,
  canProductAccessSuperadmin,
  canViewInvestmentOpportunities,
  canSeeAdvisorNotes,
  canSyncDataToDlt,
};
