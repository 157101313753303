export const CLIENT_VIEW = Object.freeze({
  id: 'client',
  labelKey: 'glossary:client_other',
});

export const ACCOUNT_VIEW = Object.freeze({
  id: 'account',
  labelKey: 'glossary:account_other',
});

export const FUND_VIEW = Object.freeze({
  id: 'fund',
  labelKey: 'glossary:fund_other',
});

export const VIEWS = Object.freeze([CLIENT_VIEW, ACCOUNT_VIEW, FUND_VIEW]);
