import {
  shape, bool, string, number, objectOf, oneOfType, exact, object, arrayOf,
} from 'prop-types';

export default shape({
  id: number,
  main_contact_label: string,
  instructional_text: shape({
    step_one: string,
    step_two_qp: string,
    step_two_ria: string,
    step_three: string,
    required_documents: string,
    terms_and_conditions: string
  }),
  has_header_color: bool,
  login_help: string,
  sales_reporting_style: string,
  third_party_gp_sales_reporting: bool,
  hidden_new_issues: bool,
  investment_status_labels: objectOf(string),
  banner: shape({
    message: string,
    alternative_logo: string,
    background_image: string,
    logo_display_option: string,
    background_color: string,
    background_opacity: string,
    message_content_width: string,
    background_image_orientation: string,
  }),
  privacy_policy: oneOfType([
    shape({
      id: number,
      privacy_policy_version: string,
      privacy_policy_content: string,
    }),
    exact({}),
  ]),
  name: string,
  disclaimer: string,
  hide_funding_tooltip: bool,
  hide_add_signer_link_from_profile: bool,
  hide_icn_suffix_from_profile: bool,
  hide_redemption_confirmation_modal: bool,
  paf_phone_number: string,
  paf_email_address: string,
  portal: bool,
  resend_subdoc_on_increase_only: bool,
  hide_investor_profiles: bool,
  hide_fund_target: bool,
  hide_shore_dropdown: bool,
  hide_interested_party_registration: bool,
  include_all_funds: bool,
  enable_investor_preferences: bool,
  authentication_method: string,
  no_register: bool,
  show_flash_reports: bool,
  enable_sso_login_url: bool,
  sso_login_url: string,
  show_user_settings_edit: bool,
  disable_electronic_signature: bool,
  hide_profile_pages: bool,
  limited_qpcl_paf_access: bool,
  show_account_num_on_ip_list: bool,
  custom_page_templates: object,
  blocked_registration_roles: arrayOf(string),
  custom_css_class: string,
  show_send_investor_registration: bool,
  show_exception_flags_in_client_management: bool,
  show_main_site_exception_flags_in_sub_review: bool,
  disable_editing_sub_review_notes: bool,
  need_assistance_override: string,
  account_type_investor_profile: bool,
  enable_fullstory: bool,
  enable_usersnap: bool,
  domain: string,
  override_login_url: string,
  show_account_number: bool,
  doc_center_show_firm_filter: bool,
  doc_center_show_investors_filter: bool,
  doc_center_allow_position_date_logic: bool,
  doc_center_allow_position_date_watermark: bool,
  doc_center_show_fund_group: bool,
  show_doc_center_advisor_column: bool,
  doc_center_max_result_filter: number,
  investment_dashboard_anonymized_fields: bool,
  enable_redemptions: bool,
  notifications_delivery: bool,
  suppress_investor_communications_default: bool,
  slug: string,
  sequence_signers_required: bool,
  show_external_id_edition: bool,
  enable_fund_doc_watermarking: bool,
  fund_docs_bulk_download_limit: number,
  fund_docs_preview_limit: number,
  auto_mfa_enrollment: bool,
  show_calculated_irr_on_investment_dashboard: bool,
  show_insights_content_on_thank_you_page: bool,
  sso_login_name: string,
  sso_login_help: string,
  hide_sales_reporting_exports: bool,
  dc_document_info: bool,
  enable_nominee_redemptions: bool,
  terms_of_use: oneOfType([
    shape({
      id: number,
      terms_version: string,
      terms_content: string,
      terms_title: string,
      terms_ack_text: string,
      wlp_id: number,
      terms_reacceptance: number,
      enable_custom_wlp_page_and_popup: bool,
    }),
    exact({}),
  ]),
  require_cancellation_note: bool,
});
