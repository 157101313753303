export const BASE_URLS = {
    simonV1: '/simon/api/v1',
    simonV2: '/simon/api/v2',
    icn: '/api/v2',
};
export const ICN = 'icn';
export const HYBRID = 'hybrid';
export const SIMON = 'simon';
export const SIMON_V1 = 'simonV1';
export const SIMON_V2 = 'simonV2';
export const GET = 'get';
export const POST = 'post';
