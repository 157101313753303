import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@icapitalnetwork/authentication';
import { getRequest } from 'services/axiosRequest.js';
import { BASE_URLS, SIMON_V1, SIMON } from 'services/axios/constants';
export const useGetMyProfile = (onSuccess) => {
    var _a, _b;
    const url = 'my-profile';
    const token = (_b = (_a = useAuth()) === null || _a === void 0 ? void 0 : _a.accessToken) === null || _b === void 0 ? void 0 : _b.accessToken;
    return useQuery({
        queryKey: [url],
        queryFn: () => getRequest({
            token,
            baseURL: BASE_URLS[SIMON_V1],
            url,
            platform: SIMON,
        }),
        onSuccess,
    });
};
