// Subscriptions Dashboard
export const SDB_SET_FUNDS = '[SUBSCRIPTIONS DASHBOARD] SET_FUNDS';
export const SDB_SET_FUNDS_SUMMARY = '[SUBSCRIPTIONS DASHBOARD] SET_FUNDS_SUMMARY';
export const SDB_SET_FUNDS_FIRMS = '[SUBSCRIPTIONS DASHBOARD] SDB_SET_FUNDS_FIRMS';
export const SDB_SET_ADVISORY_FIRMS_FOR_FILTER = '[SUBSCRIPTIONS DASHBOARD] SDB_SET_ADVISORY_FIRMS_FOR_FILTER';
export const SDB_SET_FUNDS_FOR_FILTER = '[SUBSCRIPTIONS DASHBOARD] SDB_SET_FUNDS_FOR_FILTER';
export const SDB_SET_WORKFLOW_STATUSES_FOR_FILTER = '[SUBSCRIPTIONS DASHBOARD] SDB_SET_WORKFLOW_STATUSES_FOR_FILTER';
export const SDB_SELECTED_ADVISORY_FIRMS = '[SUBSCRIPTIONS DASHBOARD] SELECTED_ADVISORY_FIRMS';
export const SDB_SELECTED_FUNDS = '[SUBSCRIPTIONS DASHBOARD] SELECTED_FUNDS';
export const SDB_SELECTED_STATUSES = '[SUBSCRIPTIONS DASHBOARD] SELECTED_STATUSES';
export const SDB_SET_FILTERED_FUNDS = '[SUBSCRIPTIONS DASHBOARD] SET_FILTERED_FUNDS';
export const SDB_SET_ACTIVE_SAVED_VIEW = '[SUBSCRIPTIONS DASHBOARD] SDB_SET_ACTIVE_SAVED_VIEW';
export const SDB_SELECTED_FUND_STATUS = '[SUBSCRIPTIONS DASHBOARD] SELECTED_FUND_STATUS';
export const SDB_SET_SEARCH_TYPE = '[SUBSCRIPTIONS DASHBOARD] SDB_SET_SEARCH_TYPE';
export const SDB_SET_SEARCH_TERM = '[SUBSCRIPTIONS DASHBOARD] SDB_SET_SEARCH_TERM';
export const SDB_SET_STATUS_DISPLAY_LABELS = '[SUBSCRIPTIONS DASHBOARD] SDB_SET_STATUS_DISPLAY_LABELS';
export const SDB_SELECTED_ACTIVITY = '[SUBSCRIPTIONS DASHBOARD] SELECTED_ACTIVITY';
export const SBD_SET_BRANCH_CODES_FOR_FILTER = '[SUBSCRIPTIONS DASHBOARD] SET_BRANCH_CODES_FOR_FILTER';
export const SDB_SELECTED_BRANCH_CODES = '[SUBSCRIPTIONS DASHBOARD] SELECTED_BRANCH_CODES';
export const SDB_SET_REFERRAL_CODES_FOR_FILTER = '[SUBSCRIPTIONS DASHBOARD] SET_REFERRAL_CODES_FOR_FILTER';
export const SDB_SELECTED_REFERRAL_CODES = '[SUBSCRIPTIONS DASHBOARD] SELECTED_REFERRAL_CODES';
