/* eslint-disable max-lines */
/* eslint-disable max-statements */
import axios from 'axios';
import i18n from 'i18n';
import { groupBy, noop } from 'lodash';
import { beginApiRequest, endApiRequest } from 'actions/spinner_actions/spinnerActions';
import { fundTypes, reversionContactCardId } from 'services/subscription_review';
import { createAction } from 'redux-actions';
import { selectedFilters } from 'services/subscription_review/investmentFilters';
import { actionFactory } from 'services/actions';
import { getRequest, postRequest, patchRequest, deleteRequest } from 'actions/utils';
import { getRequest as genericGetRequest, postRequest as genericPostRequest } from 'services/apiRequest';
import investmentCustodianFormService from 'services/investment_custodian_forms';
import { createRequestTypes } from 'actions/helpers';

import {
  SUBSCRIPTION_REVIEW_SELECT_PAF,
  SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENTS,
  SUBSCRIPTION_REVIEW_SET_PAFS,
  SUBSCRIPTION_REVIEW_SET_FUND_CATEGORIES,
  SUBSCRIPTION_REVIEW_SELECT_FUND_CATEGORY,
  SUBSCRIPTION_REVIEW_UPDATE_INVESTMENT,
  SUBSCRIPTION_REVIEW_ADD_INVESTMENT,
  SUBSCRIPTION_REVIEW_REMOVE_INVESTMENT,
  SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS,
  SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS_FOR_GROUP,
  SUBSCRIPTION_REVIEW_SET_SEARCH_TERM,
  SUBSCRIPTION_REVIEW_SET_SEARCH_TYPE,
  SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENT_GROUPS,
  SUBSCRIPTION_REVIEW_SET_PAF_TRANSFERS,
  SUBSCRIPTION_REVIEW_REMOVE_PAF_TRANSFERS,
  SUBSCRIPTION_REVIEW_SET_PAF_SUMMARY,
  SUBSCRIPTION_REVIEW_SET_PAF_NEXT_CLOSE,
  SUBSCRIPTION_REVIEW_SET_PAF_AGGREGATIONS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_REDEMPTIONS_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_INVESTMENTS_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_REMEDIATION_COMMENTS_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_SIGNERS_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_APPROVAL_HISTORY_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_GENERATED_LEAD_APPROVER_ASSIGNMENTS_EXPORTS,
  SUBSCRIPTION_REVIEW_SET_INVESTMENT_STATS_BY_STATUS,
  SUBSCRIPTION_REVIEW_SET_CLOSE_DATE_FOR_INVESTMENT,
  SUBSCRIPTION_REVIEW_SET_CLOSES_FOR_FUND,
  SUBSCRIPTION_REVIEW_ADD_NEW_CLOSE,
  SUBSCRIPTION_REVIEW_REMOVE_CLOSE,
  SUBSCRIPTION_REVIEW_SET_PAF_NOTE,
  SUBSCRIPTION_REVIEW_DELETE_PAF_NOTE,
  SUBSCRIPTION_REVIEW_SET_USER_VIEWS,
  SUBSCRIPTION_REVIEW_UPDATE_CLOSE,
  SUBSCRIPTION_REVIEW_SET_INVESTMENT_NOTES,
  SUBSCRIPTION_REVIEW_ADD_INVESTMENT_NOTE,
  SUBSCRIPTION_REVIEW_SET_SUB_BOOKS_FOR_INVESTMENT,
  SUBSCRIPTION_REVIEW_SET_PLACEMENT_FEE,
  SUBSCRIPTION_REVIEW_SET_COMMITMENT,
  SUBSCRIPTION_REVIEW_SET_SUB_ADVISORY_FEE,
  SUBSCRIPTION_REVIEW_SET_NEW_SUB_BOOKS,
  SUBSCRIPTION_REVIEW_REMOVE_INVESTMENT_NOTE,
  SUBSCRIPTION_REVIEW_SET_INVESTMENT_NOTE,
  SUBSCRIPTION_REVIEW_RESET_STATE,
  SUBSCRIPTION_REVIEW_SET_WHITE_LABEL_PARTNERS,
  SUBSCRIPTION_REVIEW_SET_RIAS_FIRMS,
  SUBSCRIPTION_REVIEW_SET_GRID_STATES,
  SUBSCRIPTION_REVIEW_SET_GRID_STATUS_STATE,
  SUBSCRIPTION_REVIEW_SET_DEFAULT_SAVED_VIEW,
  SUBSCRIPTION_REVIEW_SET_CURRENT_SAVED_VIEW,
  SUBSCRIPTION_REVIEW_RESET_FILTERS,
  SUBSCRIPTION_REVIEW_GET_SELECTED_PAF,
  SUBSCRIPTION_REVIEW_EXPAND_ALL_STATUS,
  SUBSCRIPTION_REVIEW_COLLAPSE_ALL_STATUS,
  SUBSCRIPTION_REVIEW_COLLAPSE_EXPAND_STATE,
  SUBSCRIPTION_REVIEW_STATE_RENDERED,
  SUBSCRIPTION_REVIEW_STATE_LOADING,
  SUBSCRIPTION_REVIEW_STATUS_NOT_RENDERED,
  SUBSCRIPTION_REVIEW_SET_STATE_FILTER,
  SUBSCRIPTION_REVIEW_RESET_STATE_FILTER,
  SUBSCRIPTION_REVIEW_CLEAR_FORCE_REFRESH_CLOSE_DATE_FILTER_OPTIONS,
  SUBSCRIPTION_REVIEW_SET_AVAILABLE_INVESTMENT_SHARE_CLASSES,
  SUBSCRIPTION_REVIEW_SET_INVESTMENT_SHARE_CLASS,
  SUBSCRIPTION_REVIEW_SET_PAF_HAS_CANCELED_INVESTMENTS,
  SUBSCRIPTION_REVIEW_SELECT_INVESTMENT,
  SUBSCRIPTION_REVIEW_SET_CURRENT_BUCKET,
  SUBSCRIPTION_REVIEW_SET_SNACKBAR,
} from 'actions/actionsConstants';
import { INVESTMENT_APPROVAL_STATUS_OVERRIDES } from 'constants/index';

export const SR_GET_SELECTED_PAF = createRequestTypes(SUBSCRIPTION_REVIEW_GET_SELECTED_PAF);

const { updateInvestmentCustodianForm } = investmentCustodianFormService;

const subscriptionReviewSelectPaf = actionFactory(SUBSCRIPTION_REVIEW_SELECT_PAF);

const subscriptionReviewSelectFundCategory = actionFactory(SUBSCRIPTION_REVIEW_SELECT_FUND_CATEGORY);

const subscriptionReviewSetFundCategories = actionFactory(SUBSCRIPTION_REVIEW_SET_FUND_CATEGORIES);

const subscriptionReviewSetSelectedInvestmentFilters = createAction(
  SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS,
  (relevantFilters) => ({
    relevantFilters,
    isSavedViewIncomplete: false,
  })
);

const subscriptionReviewSetSelectedInvestmentFiltersForGroup = actionFactory(
  SUBSCRIPTION_REVIEW_SET_SELECTED_INVESTMENT_FILTERS_FOR_GROUP
);

const subscriptionReviewSetSearchTerm = actionFactory(SUBSCRIPTION_REVIEW_SET_SEARCH_TERM);

const subscriptionReviewSetSearchType = actionFactory(SUBSCRIPTION_REVIEW_SET_SEARCH_TYPE);

const subscriptionReviewSetPafs = actionFactory(SUBSCRIPTION_REVIEW_SET_PAFS);

const subscriptionReviewSetPafInvestments = actionFactory(SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENTS);

const subscriptionReviewSetPafInvestmentGroups = actionFactory(SUBSCRIPTION_REVIEW_SET_PAF_INVESTMENT_GROUPS);

const subscriptionReviewSetPafTransfers = actionFactory(SUBSCRIPTION_REVIEW_SET_PAF_TRANSFERS);

const subscriptionReviewRemovePafTransfers = actionFactory(SUBSCRIPTION_REVIEW_REMOVE_PAF_TRANSFERS);

const subscriptionReviewSetPafSummary = actionFactory(SUBSCRIPTION_REVIEW_SET_PAF_SUMMARY);

const subscriptionReviewSetPafHasCanceledInvestments = actionFactory(
  SUBSCRIPTION_REVIEW_SET_PAF_HAS_CANCELED_INVESTMENTS
);

const subscriptionReviewSetPafNextClose = actionFactory(SUBSCRIPTION_REVIEW_SET_PAF_NEXT_CLOSE);

const subscriptionReviewSetPafAggregations = actionFactory(SUBSCRIPTION_REVIEW_SET_PAF_AGGREGATIONS);

const subscriptionReviewUpdateInvestment = actionFactory(SUBSCRIPTION_REVIEW_UPDATE_INVESTMENT);

const subscriptionReviewAddInvestment = actionFactory(SUBSCRIPTION_REVIEW_ADD_INVESTMENT);

const subscriptionReviewRemoveInvestment = actionFactory(SUBSCRIPTION_REVIEW_REMOVE_INVESTMENT);

const subscriptionReviewSetGeneratedInvestmentsExports = actionFactory(
  SUBSCRIPTION_REVIEW_SET_GENERATED_INVESTMENTS_EXPORTS
);

const subscriptionReviewSetGeneratedRedemptionsExports = actionFactory(
  SUBSCRIPTION_REVIEW_SET_GENERATED_REDEMPTIONS_EXPORTS
);

const setSubscriptionReviewGeneratedRemediationCommentsExports = actionFactory(
  SUBSCRIPTION_REVIEW_SET_GENERATED_REMEDIATION_COMMENTS_EXPORTS
);

const subscriptionReviewSetGeneratedSignersExports = actionFactory(SUBSCRIPTION_REVIEW_SET_GENERATED_SIGNERS_EXPORTS);

const subscriptionReviewSetGeneratedApprovalHistoryExports = actionFactory(
  SUBSCRIPTION_REVIEW_SET_GENERATED_APPROVAL_HISTORY_EXPORTS
);

const subscriptionReviewSetGeneratedLeadApproverAssignmentsExports = actionFactory(
  SUBSCRIPTION_REVIEW_SET_GENERATED_LEAD_APPROVER_ASSIGNMENTS_EXPORTS
);

const subscriptionReviewSetInvestmentStatsByStatus = actionFactory(SUBSCRIPTION_REVIEW_SET_INVESTMENT_STATS_BY_STATUS);

const subscriptionReviewSetClosesForFund = actionFactory(SUBSCRIPTION_REVIEW_SET_CLOSES_FOR_FUND);

const subscriptionReviewAddNewClose = actionFactory(SUBSCRIPTION_REVIEW_ADD_NEW_CLOSE);
const subscriptionReviewSetPafNote = actionFactory(SUBSCRIPTION_REVIEW_SET_PAF_NOTE);
const subscriptionReviewRemovePafNote = actionFactory(SUBSCRIPTION_REVIEW_DELETE_PAF_NOTE);

const subscriptionReviewSetCloseDateForInvestment = actionFactory(SUBSCRIPTION_REVIEW_SET_CLOSE_DATE_FOR_INVESTMENT);

const subscriptionReviewRemoveClose = actionFactory(SUBSCRIPTION_REVIEW_REMOVE_CLOSE);

const subscriptionReviewSetUserViews = actionFactory(SUBSCRIPTION_REVIEW_SET_USER_VIEWS);

const subscriptionReviewUpdateClose = actionFactory(SUBSCRIPTION_REVIEW_UPDATE_CLOSE);

const subscriptionReviewSetSubBooksForInvestment = actionFactory(SUBSCRIPTION_REVIEW_SET_SUB_BOOKS_FOR_INVESTMENT);

const subscriptionReviewSetPlacementFee = actionFactory(SUBSCRIPTION_REVIEW_SET_PLACEMENT_FEE);

const subscriptionReviewSetCommitment = actionFactory(SUBSCRIPTION_REVIEW_SET_COMMITMENT);

const subscriptionReviewSetSubAdvisoryFee = actionFactory(SUBSCRIPTION_REVIEW_SET_SUB_ADVISORY_FEE);

const subscriptionReviewSetNewSubBooks = actionFactory(SUBSCRIPTION_REVIEW_SET_NEW_SUB_BOOKS);

const subscriptionReviewSetInvestmentNotes = actionFactory(SUBSCRIPTION_REVIEW_SET_INVESTMENT_NOTES);

const subscriptionReviewAddInvestmentNote = actionFactory(SUBSCRIPTION_REVIEW_ADD_INVESTMENT_NOTE);

const subscriptionReviewDeleteInvestmentNote = actionFactory(SUBSCRIPTION_REVIEW_REMOVE_INVESTMENT_NOTE);

const subscriptionReviewSetInvestmentNote = actionFactory(SUBSCRIPTION_REVIEW_SET_INVESTMENT_NOTE);

const subscriptionReviewSetWhiteLabelPartners = actionFactory(SUBSCRIPTION_REVIEW_SET_WHITE_LABEL_PARTNERS);

const subscriptionReviewSetRiasFirms = actionFactory(SUBSCRIPTION_REVIEW_SET_RIAS_FIRMS);

const subscriptionReviewResetState = actionFactory(SUBSCRIPTION_REVIEW_RESET_STATE);

const subscriptionReviewSetGridStates = actionFactory(SUBSCRIPTION_REVIEW_SET_GRID_STATES);

const subscriptionReviewSetGridStatusState = actionFactory(SUBSCRIPTION_REVIEW_SET_GRID_STATUS_STATE);

const subscriptionReviewSetDefaultSavedView = actionFactory(SUBSCRIPTION_REVIEW_SET_DEFAULT_SAVED_VIEW);

const subscriptionReviewSetCurrentSavedView = actionFactory(SUBSCRIPTION_REVIEW_SET_CURRENT_SAVED_VIEW);

const subscriptionReviewResetFilters = actionFactory(SUBSCRIPTION_REVIEW_RESET_FILTERS);

const subscriptionReviewStateRendered = actionFactory(SUBSCRIPTION_REVIEW_STATE_RENDERED);

const subscriptionReviewSetAvailableInvestmentShareClasses = actionFactory(
  SUBSCRIPTION_REVIEW_SET_AVAILABLE_INVESTMENT_SHARE_CLASSES
);

const subscriptionReviewSetInvestmentShareClass = actionFactory(SUBSCRIPTION_REVIEW_SET_INVESTMENT_SHARE_CLASS);

const subscriptionReviewStateLoading = actionFactory(SUBSCRIPTION_REVIEW_STATE_LOADING);

const subscriptionReviewResetStateFilter = actionFactory(SUBSCRIPTION_REVIEW_RESET_STATE_FILTER);

const subscriptionReviewSelectInvestment = actionFactory(SUBSCRIPTION_REVIEW_SELECT_INVESTMENT);

const subscriptionReviewSetCurrentBucket = actionFactory(SUBSCRIPTION_REVIEW_SET_CURRENT_BUCKET);

const subscriptionReviewSetSnackbar = actionFactory(SUBSCRIPTION_REVIEW_SET_SNACKBAR);

function isEqualSearch(search1, search2) {
  return JSON.stringify(search1) === JSON.stringify(search2);
}

function subscriptionReviewGetCloseDates(status) {
  return (dispatch, getState) => {
    const { subscriptionReview: sr } = getState();
    const pafId = sr.selected_paf_id;
    const searchTerm = sr.searchTerm;
    const searchType = sr.searchType;
    const filterOptions = selectedFilters(sr.selected_filters);
    const approvalStatusOverride = [status];

    const data = {
      pafId,
      search: searchTerm,
      search_type: searchType,
      filterOptions,
      approvalStatusOverride,
    };

    return dispatch(
      postRequest({
        showSpinner: false,
        requestParams: status,
        url: '/aml_approval_document_status_dashboard/filtered_investments/close_dates',
        data,
        actions: {
          SUCCESS: SUBSCRIPTION_REVIEW_CLEAR_FORCE_REFRESH_CLOSE_DATE_FILTER_OPTIONS,
        },
      })
    );
  };
}

function subscriptionReviewGetInvestmentActions(investment) {
  return genericPostRequest({
    url: '/aml_approval_document_status_dashboard/investment_actions',
    data: { investment_id: investment.id },
  });
}

function subscriptionReviewLoadFilteredInvestmentsForState(status) {
  return (dispatch, getState) => {
    const { subscriptionReview: sr } = getState();
    const pafId = sr.selected_paf_id;
    const searchTerm = sr.searchTerm;
    const searchType = sr.searchType;
    const filterOptions = {
      ...selectedFilters(sr.selected_filters),
      ...(sr.state_filters[status] || {}),
    };

    const approvalStatusOverride = [status];
    let investmentCount = sr.paf_investment_stats_by_status[status].investments_count;

    if (status === INVESTMENT_APPROVAL_STATUS_OVERRIDES.REVERTED) {
      const autoReverted = INVESTMENT_APPROVAL_STATUS_OVERRIDES.INVESTMENT_AUTO_REVERTED;
      approvalStatusOverride.push(autoReverted);
      investmentCount += sr.paf_investment_stats_by_status[autoReverted]?.investments_count || 0;
    }

    const data = {
      pafId,
      search: searchTerm,
      search_type: searchType,
      filterOptions,
      approvalStatusOverride,
      limit: investmentCount <= 500 ? 500 : 100,
      offset: 0,
    };

    if (isEqualSearch(sr.gridToggleStates.isLoading[status]?.data, data)) {
      return Promise.resolve();
    }

    if (sr.gridToggleStates.isLoading[status]) {
      sr.gridToggleStates.isLoading[status].cancel();
    }

    // When no investments to fetch, clear the store
    // need to fetch for accepted regardless, because of possible transfers
    if (!investmentCount && status !== INVESTMENT_APPROVAL_STATUS_OVERRIDES.ACCEPTED) {
      return Promise.all([
        dispatch(subscriptionReviewStateRendered(status)),
        dispatch(subscriptionReviewSetPafInvestments([])),
        dispatch(subscriptionReviewSetPafInvestmentGroups({ investments: [], status, total: 0 })),
      ]);
    }
    const cancel = axios.CancelToken.source();

    dispatch(subscriptionReviewStateLoading({ status, value: { data, cancel } }));
    return axios({
      url: '/aml_approval_document_status_dashboard/filtered_investments',
      cancelToken: cancel.token,
      method: 'post',
      withCredentials: true,
      data,
    })
      .then((response) => {
        dispatch(subscriptionReviewSetPafInvestments(response.data.records));
        dispatch(
          subscriptionReviewSetPafInvestmentGroups({
            investments: response.data.records,
            status,
            total: response.data.total,
          })
        );
        dispatch(subscriptionReviewStateRendered(status));
      })
      .catch((error) => Promise.reject(error))
      .finally(() => dispatch(subscriptionReviewStateLoading({ status, value: null })));
  };
}

function subscriptionReviewLoadFilteredInvestments() {
  return (dispatch, getState) => {
    const { subscriptionReview: sr } = getState();
    const approvalStatusOverride = Object.keys(sr.gridToggleStates.isExpanded).filter(
      (state) => sr.gridToggleStates.isExpanded[state] && !sr.gridToggleStates.didRenderOnce[state]
    );

    return Promise.all(
      approvalStatusOverride.map((key) => dispatch(subscriptionReviewLoadFilteredInvestmentsForState(key)))
    );
  };
}

function subscriptionReviewUpdateTransferEffectiveDate(transfer, month, year, endOfQuarter) {
  return patchRequest({
    url: `transfers/${transfer.id}`,
    data: {
      transfer: {
        effective_date_month: month,
        effective_date_year: year,
        is_end_of_quarter: endOfQuarter,
      },
    },
  });
}

function subscriptionReviewLoadPafs() {
  return (dispatch) => {
    dispatch(beginApiRequest());

    return axios({
      url: 'private_access_funds/for_subscription_page',
      method: 'get',
      withCredentials: true,
      data: {},
    })
      .then((response) => {
        dispatch(subscriptionReviewSetPafs(response.data));
        dispatch(subscriptionReviewSetFundCategories(fundTypes(response.data)));
      })
      .catch((error) => Promise.reject(error))
      .finally(() => dispatch(endApiRequest()));
  };
}

function subscriptionReviewLoadPafSummary() {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());
    const id = getState().subscriptionReview.selected_paf_id;
    return axios({
      url: `/aml_approval_document_status_dashboard/${id}/paf_summary`,
      method: 'get',
      withCredentials: true,
      data: {},
    })
      .then((response) => {
        dispatch(subscriptionReviewSetPafSummary(response.data));
      })
      .catch((error) => Promise.reject(error))
      .finally(() => dispatch(endApiRequest()));
  };
}

function subscriptionReviewLoadPafHasCanceledInvestments(id) {
  return (dispatch) =>
    dispatch(
      getRequest({
        url: `/aml_approval_document_status_dashboard/${id}/paf_has_canceled_investments`,
        onSuccess: (response) => subscriptionReviewSetPafHasCanceledInvestments(response.has_canceled_investments),
      })
    );
}

function subscriptionReviewLoadPafNextClose() {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());
    const id = getState().subscriptionReview.selected_paf_id;
    return genericGetRequest({
      url: `/closes/fund_next_close?fund_id=${id}`,
    })
      .then((response) => {
        dispatch(subscriptionReviewSetPafNextClose(response));
      })
      .catch(noop)
      .finally(() => {
        dispatch(endApiRequest());
      });
  };
}

function subscriptionReviewLoadPafAggregations() {
  return (dispatch, getState) => {
    dispatch(beginApiRequest());
    const id = getState().subscriptionReview.selected_paf_id;
    return axios({
      url: `/share_classes/aggregations_for_paf?paf_id=${id}`,
      method: 'get',
      withCredentials: true,
      data: {},
    })
      .then((response) => {
        dispatch(subscriptionReviewSetPafAggregations(response.data));
      })
      .catch((error) => Promise.reject(error))
      .finally(() => dispatch(endApiRequest()));
  };
}

function subscriptionReviewSetShareClass(investment, shareClassId) {
  const data = { investmentId: investment.id };
  if (shareClassId !== 0) {
    data.share_class_id = shareClassId;
  }

  return (dispatch) =>
    dispatch(
      patchRequest({
        url: `investments/${investment.id}/set_share_class`,
        data,
        onSuccess: subscriptionReviewLoadPafAggregations,
      })
    );
}

function subscriptionReviewSetCustodianFormFlag(investment) {
  return (dispatch) => {
    dispatch(beginApiRequest());

    return updateInvestmentCustodianForm(investment.id, !investment.custodian_forms_received)
      .then((inv) => {
        dispatch(subscriptionReviewUpdateInvestment(inv));
      })
      .catch((error) => Promise.reject(error))
      .finally(() => dispatch(endApiRequest()));
  };
}

function hasAdministratorSignedOff(investment) {
  return investment.administrator_sign_off_status === 'Complete';
}

function subscriptionReviewSetAdministratorSignoffFlag(investment) {
  const url = 'fund_admin_sign_offs';
  const id = investment.id;

  return (dispatch) =>
    patchRequest({
      url: `${url}/${id}`,
      method: 'patch',
      data: {
        investment: {
          id,
          admin_sign_off_status: !hasAdministratorSignedOff(investment),
        },
      },
    })(dispatch)
      .then((response) => {
        dispatch(subscriptionReviewUpdateInvestment(response.investment_aml_approval));
      })
      .catch((error) => Promise.reject(error));
}

function subscriptionReviewLoadGeneratedRedemptionsExports() {
  return (dispatch, getState) =>
    dispatch(
      getRequest({
        url: 'subscription_exported_files',
        params: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          export_type: 'redemptions',
        },
        onSuccess: subscriptionReviewSetGeneratedRedemptionsExports,
      })
    );
}

function subscriptionReviewLoadGeneratedInvestmentsExports(status) {
  return (dispatch, getState) =>
    dispatch(
      getRequest({
        url: 'subscription_exported_files',
        params: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          approval_status: status,
          export_type: 'investments',
        },
        onSuccess: subscriptionReviewSetGeneratedInvestmentsExports,
      })
    );
}

function subscriptionReviewLoadGeneratedRemediationCommentsExports(status) {
  return (dispatch, getState) =>
    dispatch(
      getRequest({
        url: 'subscription_exported_files',
        params: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          approval_status: status,
          export_type: 'remediation_comments',
        },
        onSuccess: setSubscriptionReviewGeneratedRemediationCommentsExports,
      })
    );
}

function subscriptionReviewLoadGeneratedSignersExports(status) {
  return (dispatch, getState) =>
    dispatch(
      getRequest({
        url: 'subscription_exported_files',
        params: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          approval_status: status,
          export_type: 'signers',
        },
        onSuccess: subscriptionReviewSetGeneratedSignersExports,
      })
    );
}

function subscriptionReviewSetProfileMatched(investment) {
  const status = !investment.profile_matched;

  return patchRequest({
    url: `investment_profile_matched/${investment.id}`,
    data: { investment_profile_matched: { id: investment.id, profile_matched: status } },
    onSuccess: (data) => subscriptionReviewUpdateInvestment(data.investment_aml_approval),
  });
}

function subscriptionsReviewGenerateRedemptionsExport() {
  return (dispatch, getState) =>
    dispatch(
      postRequest({
        url: 'subscription_exported_files',
        data: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          export_type: 'redemptions',
        },
      })
    );
}

function subscriptionsReviewGenerateInvestmentsExport(status) {
  return (dispatch, getState) =>
    dispatch(
      postRequest({
        url: 'subscription_exported_files',
        data: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          approval_status: status,
          export_type: 'investments',
        },
      })
    );
}

function subscriptionsReviewGenerateRemediationCommentsExport(status) {
  return (dispatch, getState) =>
    dispatch(
      postRequest({
        url: 'subscription_exported_files',
        data: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          approval_status: status,
          export_type: 'remediation_comments',
        },
      })
    );
}

function subscriptionsReviewGenerateSignersExport(status) {
  return (dispatch, getState) =>
    dispatch(
      postRequest({
        url: 'subscription_exported_files',
        data: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          approval_status: status,
          export_type: 'signers',
        },
      })
    );
}

function subscriptionReviewLoadGeneratedApprovalHistoryExports() {
  return (dispatch, getState) =>
    dispatch(
      getRequest({
        url: 'exports',
        params: {
          only: 'approval_history_export',
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          range: 30,
        },
        onSuccess: subscriptionReviewSetGeneratedApprovalHistoryExports,
      })
    );
}

function subscriptionReviewGenerateApprovalHistoryExport() {
  return (dispatch, getState) =>
    dispatch(
      postRequest({
        url: 'exports',
        data: {
          export_type: 'approval_history_export',
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
        },
      })
    );
}

function subscriptionReviewLoadGeneratedLeadApproverAssignmentsExports() {
  return (dispatch, getState) =>
    dispatch(
      getRequest({
        url: 'exports',
        params: {
          only: 'lead_approver_assignments_export',
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          range: 30,
        },
        onSuccess: subscriptionReviewSetGeneratedLeadApproverAssignmentsExports,
      })
    );
}

function subscriptionReviewGenerateLeadApproverAssignmentsExport() {
  return (dispatch, getState) =>
    dispatch(
      postRequest({
        url: 'exports',
        data: {
          export_type: 'lead_approver_assignments_export',
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
        },
      })
    );
}

const subscriptionReviewStatusNotRendered = actionFactory(SUBSCRIPTION_REVIEW_STATUS_NOT_RENDERED);

function subscriptionsReviewInvestmentsForPafByStatus() {
  return (dispatch, getState) => {
    const { subscriptionReview: sr } = getState();
    const pafId = sr.selected_paf_id;
    const search = getState().subscriptionReview.searchTerm;
    const searchType = getState().subscriptionReview.searchType;
    const filterOptions = selectedFilters(sr.selected_filters);

    return dispatch(
      postRequest({
        url: 'aml_approval_document_status_dashboard/investments_for_paf_by_status',
        data: {
          pafId,
          search,
          search_type: searchType,
          filterOptions,
        },
        onSuccess: (response) => (innerDispatch) => {
          innerDispatch(subscriptionReviewSetInvestmentStatsByStatus(response));
          // mark the state as "dirty" (refresh next time)
          innerDispatch(subscriptionReviewStatusNotRendered());

          // clear state filters
          innerDispatch(subscriptionReviewResetStateFilter());

          // fetch investments for this search criteria
          innerDispatch(subscriptionReviewLoadFilteredInvestments());
        },
      })
    );
  };
}

function subscriptionsReviewLoadClosesForFund(includeExecutedCloses = false) {
  const closesStatus = includeExecutedCloses ? 'all' : 'next_close';
  return (dispatch, getState) =>
    dispatch(
      getRequest({
        url: 'closes/closes_for_fund',
        params: {
          fund_id: getState().subscriptionReview.selected_paf_id,
          status: closesStatus,
        },
        onSuccess: subscriptionReviewSetClosesForFund,
      })
    );
}

function subscriptionsReviewCreateNewClose(close) {
  return (dispatch, getState) =>
    dispatch(
      postRequest({
        url: 'closes',
        data: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          status: 'next_close',
          close_date: close.closeDate,
          doc_due_date: close.dueDate,
          wires_due_date: close.wiresDueDate,
          funding_window_start: close.fundingStartDate,
          funding_window_end: close.fundingEndDate,
          funding_call_percentage: close.fundingCallPercentage / 100,
          white_label_partner_id: close.wlp && close.wlp.id,
          firm_id: close.firm && close.firm.id,
        },
        onSuccess: subscriptionReviewAddNewClose,
      })
    );
}

function subscriptionReviewSetCloseDate(investment, selectedCloseId) {
  return (dispatch, getState) =>
    dispatch(
      patchRequest({
        url: `investments/${investment.id}/toggle_in_next_close_date`,
        data: {
          fund_id: getState().subscriptionReview.selected_paf_id,
          id: investment.id,
          close_id: selectedCloseId,
        },
        onSuccess: (data) => subscriptionReviewSetCloseDateForInvestment(data.investment),
      })
    );
}

function subscriptionReviewUpdateCloseDate(investment, selectedCloseId) {
  return (dispatch, getState) =>
    dispatch(
      patchRequest({
        url: `investments/${investment.id}/update_close_date`,
        data: {
          fund_id: getState().subscriptionReview.selected_paf_id,
          id: investment.id,
          close_id: selectedCloseId,
        },
        onSuccess: () =>
          subscriptionReviewSetCloseDateForInvestment({
            investment,
            close_id: selectedCloseId,
          }),
      })
    );
}

function subscriptionsReviewDeleteClose(close) {
  return (dispatch, getState) =>
    dispatch(
      deleteRequest({
        url: `closes/${close.id}`,
        params: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
        },
        onSuccess: () => subscriptionReviewRemoveClose(close),
      })
    );
}

function subscriptionReviewCreatePafNote(subject, body, selectedPafId) {
  return (dispatch) =>
    dispatch(
      postRequest({
        url: 'notes',
        data: {
          note: {
            noteable_id: selectedPafId,
            noteable_type: 'PrivateAccessFund',
            subject,
            body,
          },
        },
        onSuccess: subscriptionReviewSetPafNote,
        successMessage: { message: i18n.t('subscription_review:paf_note.note_created_successfully') },
        errorMessage: { message: i18n.t('subscription_review:paf_note.failed_to_create_note') },
      })
    );
}

function subscriptionReviewUpdatePafNote(id, subject, body, selectedPafId) {
  return (dispatch) =>
    dispatch(
      patchRequest({
        url: `notes/${id}`,
        data: {
          id: selectedPafId,
          note: {
            subject,
            body,
          },
        },
        onSuccess: subscriptionReviewSetPafNote,
        successMessage: { message: i18n.t('subscription_review:paf_note.note_updated_successfully') },
        errorMessage: { message: i18n.t('subscription_review:paf_note.failed_to_update_note') },
      })
    );
}

function subscriptionReviewDeletePafNote(id) {
  return (dispatch) =>
    dispatch(
      deleteRequest({
        url: `notes/${id}`,
        onSuccess: subscriptionReviewRemovePafNote,
        successMessage: { message: i18n.t('subscription_review:paf_note.note_deleted_successfully') },
        errorMessage: { message: i18n.t('subscription_review:paf_note.failed_to_delete_note') },
      })
    );
}

function subscriptionReviewUserViews() {
  return (dispatch, getState) =>
    dispatch(
      getRequest({
        url: 'user_subscription_review_views',
        params: { private_access_fund_id: getState().subscriptionReview.selected_paf_id },
        onSuccess: subscriptionReviewSetUserViews,
      })
    );
}

function subscriptionReviewCreateUserViews(statuses) {
  return (dispatch, getState) =>
    dispatch(
      postRequest({
        url: 'user_subscription_review_views',
        method: 'patch',
        data: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          data: { statuses },
        },
        onSuccess: subscriptionReviewSetUserViews,
      })
    );
}

function subscriptionReviewUpdateUserViews(statuses) {
  return (dispatch, getState) =>
    dispatch(
      patchRequest({
        url: `user_subscription_review_views/${getState().subscriptionReview.user_views.id}`,
        method: 'patch',
        data: { private_access_fund_id: getState().subscriptionReview.selected_paf_id, data: { statuses } },
        onSuccess: (response) => subscriptionReviewSetUserViews(response),
      })
    );
}

function subscriptionReviewUpdateOrCreateUserViews(statuses) {
  return (dispatch, getState) => {
    if (getState().subscriptionReview.user_views) {
      return dispatch(subscriptionReviewUpdateUserViews(statuses));
    }
    return dispatch(subscriptionReviewCreateUserViews(statuses));
  };
}

function subscriptionsReviewApprovalHistoryEvents(investmentId) {
  return getRequest({
    url: `investments/${investmentId}/audit_trail_approval`,
  });
}

function subscriptionReviewFetchReversionEmailBlacklist(investment, cardId = null) {
  const contactCardId = cardId || reversionContactCardId(investment);
  return getRequest({
    url: `contact_cards/${contactCardId}/reversion_email_blacklist`,
    params: { inv_id: investment.id },
  });
}

function subscriptionReviewSendRemediationNotice(email, investmentId) {
  return postRequest({
    url: 'subscription_review/aml_approval_documents/mail_notice',
    data: {
      email: [email],
      investment_id: investmentId,
    },
  });
}

function subscriptionReviewLoadInvestmentsByStatusData() {
  return (dispatch) => {
    dispatch(subscriptionsReviewInvestmentsForPafByStatus());
    dispatch(subscriptionReviewSetCurrentBucket());
  };
}

function subscriptionReviewOverrideApprovalStatus(investmentId, status) {
  return patchRequest({
    url: `investments/${investmentId}/override_approval_status`,
    data: {
      investmentId,
      overrideApprovalStatus: status,
    },
    onSuccess: () => subscriptionReviewLoadInvestmentsByStatusData(),
  });
}

function subscriptionReviewUpdateSelectedInvestmentFiltersForGroup(options, filterType) {
  return (dispatch) => {
    dispatch(subscriptionReviewSetCurrentSavedView(null));
    dispatch(
      subscriptionReviewSetSelectedInvestmentFiltersForGroup({
        filterType,
        options,
      })
    );
    dispatch(subscriptionReviewLoadInvestmentsByStatusData());
  };
}

function subscriptionsReviewEditClose(close) {
  return (dispatch, getState) =>
    dispatch(
      patchRequest({
        url: `closes/${close.id}`,
        data: {
          private_access_fund_id: getState().subscriptionReview.selected_paf_id,
          close_date: close.close_date,
          doc_due_date: close.doc_due_date,
          wires_due_date: close.wires_due_date,
          funding_window_start: close.funding_window_start,
          funding_window_end: close.funding_window_end,
          funding_call_percentage: close.funding_call_percentage,
          white_label_partner_id: close.white_label_partner && close.white_label_partner.id,
          firm_id: close.firm && close.firm.id,
        },
        onSuccess: subscriptionReviewUpdateClose,
      })
    );
}

function subscriptionReviewLoadInvestmentsData() {
  return (dispatch) => {
    dispatch(subscriptionsReviewInvestmentsForPafByStatus());
    dispatch(subscriptionReviewLoadPafSummary());
    dispatch(subscriptionReviewLoadPafNextClose());
    dispatch(subscriptionReviewLoadPafAggregations());
    dispatch(subscriptionReviewSetCurrentBucket());
  };
}

function subscriptionReviewCreateTransfer(transfer, transferInvestments) {
  return (dispatch) =>
    dispatch(
      postRequest({
        url: 'transfers',
        data: {
          transfer: {
            ...transfer,
            transfer_investments_attributes: transferInvestments,
          },
        },
        onSuccess: subscriptionReviewLoadInvestmentsByStatusData,
      })
    );
}

function subscriptionReviewCancelInvestment(investment) {
  return patchRequest({
    url: `investments/${investment.id}/cancel`,
    onSuccess: () => subscriptionReviewLoadInvestmentsData(),
  });
}

function subscriptionReviewCancelTransfer(transfer) {
  return (dispatch) =>
    dispatch(
      deleteRequest({
        url: `transfers/${transfer.id}`,
        onSuccess: () => subscriptionReviewRemovePafTransfers(transfer),
      })
    );
}

function subscriptionReviewExecuteTransfer(transfer) {
  return (dispatch) =>
    dispatch(
      postRequest({
        url: `transfers/${transfer.id}/execute`,
      })
    );
}

function subscriptionReviewUpdateInvestmentShareClass(investment, investmentShareClassId) {
  return patchRequest({
    url: `investments/${investment.id}/set_investment_share_class`,
    data: {
      investment_share_class_id: investmentShareClassId,
    },
    onSuccess: subscriptionReviewSetInvestmentShareClass,
  });
}

function subscriptionReviewLoadInvestmentShareClasses(pafId, wlpId, firmId) {
  return getRequest({
    url: 'investment_share_classes/available',
    params: {
      private_access_fund_id: pafId,
      white_label_partner_id: wlpId,
      firm_id: firmId,
    },
    onSuccess: subscriptionReviewSetAvailableInvestmentShareClasses,
  });
}

function subscriptionReviewLoadSubBooksForInvestment(investmentId) {
  return getRequest({
    url: 'subscription_document_definitions/for_investment',
    params: {
      investment_id: investmentId,
      bypass_limit_code: true,
    },
    onSuccess: subscriptionReviewSetSubBooksForInvestment,
  });
}

function subscriptionReviewUpdateCommitment(investment, commitment, hasNetCommitment = null) {
  return (dispatch, getState) => {
    const updatedInvestment = { ...investment, commitment, hasNetCommitment };
    const pafId = getState().subscriptionReview.selected_paf_id;
    return dispatch(
      patchRequest({
        url: `private_access_funds/${pafId}/investments/${investment.id}/set_commitment_amount`,
        method: 'patch',
        data: {
          privateAccessFundId: pafId,
          investmentId: investment.id,
          investment: updatedInvestment,
        },
        onSuccess: subscriptionReviewSetCommitment,
      })
    );
  };
}

function subscriptionReviewUpdateApprovedCommitment(investment, commitment) {
  return (dispatch, getState) => {
    const updatedInvestment = { ...investment, commitment };
    const pafId = getState().subscriptionReview.selected_paf_id;
    return dispatch(
      patchRequest({
        url: `private_access_funds/${pafId}/investments/${investment.id}/update_approved_commitment_amount`,
        data: {
          privateAccessFundId: pafId,
          investmentId: investment.id,
          investment: updatedInvestment,
        },
        onSuccess: subscriptionReviewSetCommitment,
      })
    );
  };
}

function subscriptionReviewUpdatePlacementFee(investment, placementFee, setPlacementFeeException = false) {
  return (dispatch, getState) => {
    const updatedInvestment = { ...investment, placement_fee: placementFee };
    const pafId = getState().subscriptionReview.selected_paf_id;
    return dispatch(
      patchRequest({
        url: `private_access_funds/${pafId}/investments/${investment.id}/set_placement_fee`,
        method: 'patch',
        data: {
          privateAccessFundId: pafId,
          investmentId: investment.id,
          investment: updatedInvestment,
          setPlacementFeeException,
        },
        onSuccess: subscriptionReviewSetPlacementFee,
      })
    );
  };
}

function subscriptionReviewUpdateSubAdvisoryFee(investment, subAdvisoryFee) {
  return (dispatch, getState) => {
    const updatedInvestment = { ...investment, sub_advisory_fee: subAdvisoryFee };
    const pafId = getState().subscriptionReview.selected_paf_id;
    return dispatch(
      patchRequest({
        url: `private_access_funds/${pafId}/investments/${investment.id}/set_sub_advisory_fee`,
        method: 'patch',
        data: {
          privateAccessFundId: pafId,
          investmentId: investment.id,
          investment: updatedInvestment,
        },
        onSuccess: subscriptionReviewSetSubAdvisoryFee,
      })
    );
  };
}

function setSubBooksSuccess(response) {
  return (dispatch) => {
    const newResponse = {
      ...response,
      sub_doc_type_name: response.simplified_sub_doc_type_name,
    };
    dispatch(subscriptionReviewSetNewSubBooks(newResponse));
  };
}

function subscriptionReviewUpdateSubBooks(investment, subBookId, bypassLimitCode = false) {
  return (dispatch, getState) => {
    const updatedInvestment = { ...investment, sub_book_id: subBookId };
    const pafId = getState().subscriptionReview.selected_paf_id;
    return dispatch(
      patchRequest({
        url: `private_access_funds/${pafId}/investments/${investment.id}/set_sub_books`,
        method: 'patch',
        data: {
          privateAccessFundId: pafId,
          subBookId,
          investmentId: investment.id,
          investment: updatedInvestment,
          bypass_limit_code: bypassLimitCode,
        },
        onSuccess: setSubBooksSuccess,
      })
    );
  };
}

function subscriptionReviewLoadInvestmentsNotes(investment) {
  return getRequest({
    url: 'notes',
    params: {
      investment_id: investment.id,
      investor_profile_id: investment.investor_profile_id,
      wlp_id: investment.white_label_partner_id,
      react: 'investment',
    },
    onSuccess: subscriptionReviewSetInvestmentNotes,
    errorMessage: { message: 'Failed to get notes' },
  });
}

function subscriptionReviewCreateInvestmentNote(investment, note, whiteLabelPartnerId, tabType) {
  return postRequest({
    url: 'notes',
    data: {
      investment_id: investment.id,
      wlp_id: whiteLabelPartnerId,
      note,
    },
    onSuccess: (response) =>
      subscriptionReviewAddInvestmentNote({
        note: response.note,
        tab: `tab${tabType}`,
      }),
    errorMessage: { message: 'Failed to create note' },
  });
}

function subscriptionReviewRemoveInvestmentNote(investment, note, tabType) {
  return deleteRequest({
    url: `notes/${note.id}`,
    data: { investment_id: investment.id },
    onSuccess: () =>
      subscriptionReviewDeleteInvestmentNote({
        note,
        tab: `tab${tabType}`,
      }),
    errorMessage: { message: 'Failed to delete note' },
  });
}

function subscriptionReviewUpdateInvestmentNote(note, tabType) {
  return patchRequest({
    url: `notes/${note.id}`,
    data: { note },
    onSuccess: (response) =>
      subscriptionReviewSetInvestmentNote({
        note: response.note,
        tab: `tab${tabType}`,
      }),
    errorMessage: { message: 'Failed to update note' },
  });
}

function subscriptionReviewSetFundedStatus(investmentId, funded, message) {
  return (dispatch) =>
    dispatch(
      patchRequest({
        url: `investments/${investmentId}/toggle_funded`,
        data: { investmentId, funded },
        onSuccess: (data) => {
          if (funded) {
            dispatch(subscriptionReviewSetSnackbar({ isVisible: true, message }));
          }
          return subscriptionReviewUpdateInvestment(data.investment);
        },
      })
    );
}

function subscriptionReviewSendForGpCountersignature(invId, options, callback) {
  const url = (pafId) => `private_access_funds/${pafId}/investments/${invId}/send_general_partner_certificate`;

  const data = (pafId) => {
    const base = {
      gp_signatory_name: options.name,
      gp_signatory_title: options.title,
      investmentId: invId,
      privateAccessFundId: pafId,
      sign_method: options.signMethod,
      gp_signatory_gp: options.pafGpLegalEntityName,
    };
    if (options.signMethod === 'electronic') {
      base.gp_signatory_email = options.email;
    }

    return base;
  };

  return (dispatch, getState) =>
    dispatch(
      patchRequest({
        url: url(getState().subscriptionReview.selected_paf_id),
        data: data(getState().subscriptionReview.selected_paf_id),
        onSuccess: (inv) => {
          if (options.signMethod === 'manual') {
            window.open(inv.general_partner_signature.file_url, '_blank');
          }
          callback();
          return subscriptionReviewUpdateInvestment(inv);
        },
      })
    );
}

function subscriptionsReviewGetIntegrationPartners(callback) {
  return (dispatch, getState) =>
    dispatch(
      getRequest({
        url: `integration_partners/for_paf/${getState().subscriptionReview.selected_paf_id}`,
        onSuccess: (data) => {
          callback(data);
          return () => {};
        },
      })
    );
}

function subscriptionReviewDocumentQuery(query, callback) {
  return getRequest({
    url: 'subscription_review/aml_approval_documents',
    params: query,
    onSuccess: (data) => {
      callback(data);
      return () => {};
    },
  });
}

function subscriptionReviewSendToIntegrationPartners(investmentId, data, callback) {
  return patchRequest({
    url: `investments/${investmentId}/send_documents_to_integration_partner`,
    data: { ...data, investmentId },
    onSuccess: callback,
  });
}

function subscriptionReviewRecallInvestment(investment, toStatus, note) {
  return (dispatch) =>
    dispatch(
      patchRequest({
        url: `investments/${investment.id}/recall_investment`,
        data: { toStatus, note },
        onSuccess: (updatedInvestment) => {
          dispatch(subscriptionReviewRemoveInvestment(investment));
          return subscriptionReviewAddInvestment(updatedInvestment);
        },
      })
    );
}

function subscriptionReviewUploadGpCountersignature(investmentId, file) {
  const formData = new FormData();
  formData.append('file', file);
  return postRequest({
    url: `investments/${investmentId}/upload_gp_signature`,
    data: formData,
    withCredentials: false,
    onSuccess: subscriptionReviewUpdateInvestment,
  });
}

function subscriptionReviewValidateAccount({ accountNumber, isProspect }) {
  return (dispatch, getState) => {
    const pafId = getState().subscriptionReview.selected_paf_id;
    const type = isProspect ? 'prospect' : 'account';
    return dispatch(
      getRequest({
        url: 'client_management/external_apis/accounts/fetch',
        params: {
          type,
          private_access_fund_id: pafId,
          account_number: accountNumber,
        },
      })
    );
  };
}

function subscriptionReviewLoadWhiteLabelPartners() {
  return getRequest({
    url: '/white_label_partners',
    onSuccess: subscriptionReviewSetWhiteLabelPartners,
  });
}

function subscriptionReviewLoadRiasFirms() {
  return getRequest({
    url: 'firms/advisors_firms',
    onSuccess: subscriptionReviewSetRiasFirms,
  });
}

const subscriptionReviewLoadSavedView = (data) => (dispatch) => {
  const filters = data.filters.drop_down_filters;
  const groupedFilters = groupBy(filters, 'filter_type');

  dispatch(subscriptionReviewSetCurrentSavedView(data));
  dispatch(subscriptionReviewResetFilters());

  const savedViewFilters = {
    rias_firms: [],
    rias_users: [],
    wlps: [],
    custodians: [],
    lead_approvers: [],
    sign_methods: [],
    branch_codes: [],
    referral_codes: [],
    show_cancelled_investments: false,
  };
  if (groupedFilters.sr_rias_firms && groupedFilters.sr_rias_firms[0].filters.length > 0) {
    savedViewFilters.rias_firms = groupedFilters.sr_rias_firms[0].filters.map((filter) => filter.typed_filter_id);
  }
  if (groupedFilters.sr_rias_users && groupedFilters.sr_rias_users[0].filters.length > 0) {
    savedViewFilters.rias_users = groupedFilters.sr_rias_users[0].filters.map((filter) => filter.typed_filter_id);
  }
  if (groupedFilters.sr_wlps && groupedFilters.sr_wlps[0].filters.length > 0) {
    savedViewFilters.wlps = groupedFilters.sr_wlps[0].filters.map((filter) => filter.typed_filter_id);
  }
  if (groupedFilters.sr_custodians && groupedFilters.sr_custodians[0].filters.length > 0) {
    savedViewFilters.custodians = groupedFilters.sr_custodians[0].filters.map((filter) => filter.typed_filter_id);
  }
  if (groupedFilters.sr_lead_approvers && groupedFilters.sr_lead_approvers[0].filters.length > 0) {
    savedViewFilters.lead_approvers = groupedFilters.sr_lead_approvers[0].filters.map(
      (filter) => filter.typed_filter_id
    );
  }
  if (groupedFilters.sr_branch_codes && groupedFilters.sr_branch_codes[0].filters.length > 0) {
    savedViewFilters.branch_codes = groupedFilters.sr_branch_codes[0].filters.map((filter) => filter.typed_filter_id);
  }
  if (groupedFilters.sr_referral_codes && groupedFilters.sr_referral_codes[0].filters.length > 0) {
    savedViewFilters.referral_codes = groupedFilters.sr_referral_codes[0].filters.map((filter) => filter.typed_filter_id);
  }
  if (groupedFilters.sr_sign_methods && groupedFilters.sr_sign_methods[0].filters.length > 0) {
    savedViewFilters.sign_methods = groupedFilters.sr_sign_methods[0].filters.map((filter) => filter.typed_filter_id);
  }
  if (groupedFilters.search && groupedFilters.search[0].filters.length > 0) {
    dispatch(subscriptionReviewSetSearchTerm(groupedFilters.search[0].filters[0].content));
  }
  if (groupedFilters.searchType && groupedFilters.searchType[0].filters.length > 0) {
    dispatch(subscriptionReviewSetSearchType(groupedFilters.searchType[0].filters[0].content));
  }
  if (groupedFilters.gridStates && groupedFilters.gridStates[0].filters.length > 0) {
    dispatch(subscriptionReviewSetGridStates(groupedFilters.gridStates[0].filters[0].content));
  }

  dispatch(subscriptionReviewSetSelectedInvestmentFilters(savedViewFilters));

  dispatch(subscriptionReviewLoadInvestmentsByStatusData());
};

const subscriptionReviewLoadDefaultView = () =>
  getRequest({
    url: 'user_saved_views/default_view',
    params: { page_slug: 'subscription_review' },
    onSuccess: subscriptionReviewSetDefaultSavedView,
  });

const subscriptionReviewReloadPafInvestments = () => (dispatch, getState) => {
  const { subscriptionReview: sr } = getState();
  if (sr.defaultSavedView) {
    dispatch(subscriptionReviewLoadSavedView(sr.defaultSavedView));
  } else {
    dispatch(subscriptionReviewLoadInvestmentsByStatusData());
  }
};

const resetFilters = () => (dispatch) => {
  dispatch(subscriptionReviewResetFilters());
  dispatch(subscriptionReviewSetCurrentSavedView(null));
  dispatch(subscriptionReviewLoadInvestmentsByStatusData());
};

export const subscriptionReviewGetTransferActions = (transfer) =>
  genericGetRequest({ url: `transfers/${transfer.id}/transfer_actions` });

export const subscriptionReviewGetSelectedPaf = (pafId) =>
  getRequest({
    url: `private_access_funds/${pafId}/selected_paf_for_subscription_page`,
    errorMessage: { message: 'Sorry, something went wrong.' },
    actions: SR_GET_SELECTED_PAF,
  });

const subscriptionReviewExpandAllStatus = () => (dispatch) =>
  Promise.all([
    dispatch({ type: SUBSCRIPTION_REVIEW_EXPAND_ALL_STATUS }),
    dispatch(subscriptionReviewLoadFilteredInvestments()),
  ]);

const subscriptionReviewCollapseAllStatus = actionFactory(SUBSCRIPTION_REVIEW_COLLAPSE_ALL_STATUS);

const subscriptionReviewSetGridToggleState = (state) => (dispatch) =>
  Promise.all([
    dispatch({ type: SUBSCRIPTION_REVIEW_COLLAPSE_EXPAND_STATE, payload: state }),
    dispatch(subscriptionReviewLoadFilteredInvestments()),
  ]);

const subscriptionReviewSetStateFilter = (state, filters) => (dispatch) =>
  Promise.all([
    dispatch({ type: SUBSCRIPTION_REVIEW_SET_STATE_FILTER, payload: { state, filters } }),
    dispatch(subscriptionReviewLoadFilteredInvestmentsForState(state)),
  ]);

export default {
  subscriptionReviewSelectPaf,
  subscriptionReviewSetFundCategories,
  subscriptionReviewSelectFundCategory,
  subscriptionReviewLoadFilteredInvestments,
  subscriptionReviewUpdateTransferEffectiveDate,
  subscriptionReviewSetSearchTerm,
  subscriptionReviewSetSearchType,
  subscriptionReviewUpdateSelectedInvestmentFiltersForGroup,
  subscriptionReviewLoadPafs,
  subscriptionReviewLoadPafSummary,
  subscriptionReviewLoadPafNextClose,
  subscriptionReviewLoadPafAggregations,
  subscriptionReviewSetAdministratorSignoffFlag,
  subscriptionReviewUpdateInvestment,
  subscriptionReviewSetCustodianFormFlag,
  subscriptionReviewLoadGeneratedApprovalHistoryExports,
  subscriptionReviewGenerateApprovalHistoryExport,
  subscriptionReviewLoadGeneratedLeadApproverAssignmentsExports,
  subscriptionReviewGenerateLeadApproverAssignmentsExport,
  subscriptionReviewLoadGeneratedInvestmentsExports,
  subscriptionReviewLoadGeneratedRedemptionsExports,
  subscriptionReviewLoadGeneratedRemediationCommentsExports,
  subscriptionReviewLoadGeneratedSignersExports,
  subscriptionsReviewGenerateRedemptionsExport,
  subscriptionsReviewGenerateInvestmentsExport,
  subscriptionsReviewGenerateRemediationCommentsExport,
  subscriptionsReviewGenerateSignersExport,
  subscriptionReviewSetProfileMatched,
  subscriptionsReviewInvestmentsForPafByStatus,
  subscriptionReviewLoadInvestmentsByStatusData,
  subscriptionsReviewLoadClosesForFund,
  subscriptionsReviewCreateNewClose,
  subscriptionReviewSetCloseDate,
  subscriptionReviewUpdateCloseDate,
  subscriptionsReviewDeleteClose,
  subscriptionsReviewEditClose,
  subscriptionReviewCreatePafNote,
  subscriptionReviewUpdatePafNote,
  subscriptionReviewDeletePafNote,
  subscriptionReviewUserViews,
  subscriptionReviewUpdateUserViews,
  subscriptionReviewCreateUserViews,
  subscriptionReviewUpdateOrCreateUserViews,
  subscriptionsReviewApprovalHistoryEvents,
  subscriptionReviewFetchReversionEmailBlacklist,
  subscriptionReviewSendRemediationNotice,
  subscriptionReviewOverrideApprovalStatus,
  subscriptionReviewSetShareClass,
  subscriptionReviewCancelInvestment,
  subscriptionReviewCancelTransfer,
  subscriptionReviewExecuteTransfer,
  subscriptionReviewLoadInvestmentsNotes,
  subscriptionReviewCreateInvestmentNote,
  subscriptionReviewLoadSubBooksForInvestment,
  subscriptionReviewUpdatePlacementFee,
  subscriptionReviewUpdateCommitment,
  subscriptionReviewUpdateApprovedCommitment,
  subscriptionReviewUpdateSubAdvisoryFee,
  subscriptionReviewUpdateSubBooks,
  subscriptionReviewRemoveInvestmentNote,
  subscriptionReviewSetFundedStatus,
  subscriptionReviewSendForGpCountersignature,
  subscriptionReviewUpdateInvestmentNote,
  subscriptionsReviewGetIntegrationPartners,
  subscriptionReviewDocumentQuery,
  subscriptionReviewSendToIntegrationPartners,
  subscriptionReviewRecallInvestment,
  subscriptionReviewUploadGpCountersignature,
  subscriptionReviewValidateAccount,
  subscriptionReviewGetTransferActions,
  subscriptionReviewCreateTransfer,
  subscriptionReviewResetState,
  subscriptionReviewLoadWhiteLabelPartners,
  subscriptionReviewLoadRiasFirms,
  subscriptionReviewSetGridStates,
  subscriptionReviewSetGridStatusState,
  subscriptionReviewSetCurrentSavedView,
  subscriptionReviewLoadSavedView,
  subscriptionReviewLoadDefaultView,
  subscriptionReviewReloadPafInvestments,
  subscriptionReviewGetSelectedPaf,
  subscriptionReviewExpandAllStatus,
  subscriptionReviewCollapseAllStatus,
  subscriptionReviewSetGridToggleState,
  subscriptionReviewSetStateFilter,
  subscriptionReviewGetCloseDates,
  subscriptionReviewGetInvestmentActions,
  subscriptionReviewLoadInvestmentShareClasses,
  subscriptionReviewUpdateInvestmentShareClass,
  subscriptionReviewLoadPafHasCanceledInvestments,
  subscriptionReviewSelectInvestment,
  subscriptionReviewSetCurrentBucket,
  subscriptionReviewSetSnackbar,
  resetFilters,
};
