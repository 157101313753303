/* eslint-disable max-lines */
import axios from 'axios';
import { createAction } from 'redux-actions';
import { getRequest, postRequest } from 'actions/utils';

import groupBy from 'lodash/groupBy';

import {
  RECONCILIATION_SET_SUMMARY_TOTALS,
  RECONCILIATION_SET_PERIOD,
  RECONCILIATION_SET_FUND_GROUP,
  RECONCILIATION_SET_PAFS_WITHOUT_REPORTS,
  RECONCILIATION_SET_DATA_TOTALS,
  RECONCILIATION_SET_DOCUMENT_EXPORTS,
  RECONCILIATION_SET_TAB_STATUS,
  RECONCILIATION_SET_APPROVAL_HISTORY,
  RECONCILIATION_UPDATE_ACTION_COMMENTS,
  RECONCILIATION_SET_PUBLISH_TYPES,
  RECONCILIATION_SET_FUNDS,

  RECONCILIATION_SELECTED_FUND_GROUPS,
  RECONCILIATION_SELECTED_PERIODS,
  RECONCILIATION_SELECTED_DEFAULT_PERIOD,
  RECONCILIATION_SELECTED_FUND,
  RECONCILIATION_SELECTED_PUBLISH_TYPES,
  RECONCILIATION_SET_GRID_COLUMNS_STATE,
  RECONCILIATION_SET_GRID_COLUMNS_SET_STATE,
} from '../actionsConstants';

const cancelFetchSummaryTotals = {
  instance: undefined,
};
const cancelFetchPafsWithoutReports = {
  instance: undefined,
};
const cancelReconciliationFetchPeriods = {
  instance: undefined,
};
const cancelReconciliationFetchPafs = {
  instance: undefined,
};

const setPeriod = createAction(RECONCILIATION_SET_PERIOD);
const setPrivateAccessFunds = createAction(RECONCILIATION_SET_FUNDS);
const setFundGroup = createAction(RECONCILIATION_SET_FUND_GROUP);
const setPafsWithoutReports = createAction(RECONCILIATION_SET_PAFS_WITHOUT_REPORTS);
const setReconciliationTabStatus = createAction(RECONCILIATION_SET_TAB_STATUS);
const setActionsApprovalHistory = createAction(RECONCILIATION_SET_APPROVAL_HISTORY);
const setPublishTypes = createAction(RECONCILIATION_SET_PUBLISH_TYPES);
const setSelectedFundGroups = createAction(RECONCILIATION_SELECTED_FUND_GROUPS);
const reconciliationSetSelectedPeriods = createAction(RECONCILIATION_SELECTED_PERIODS);
const setDefaultPeriod = createAction(RECONCILIATION_SELECTED_DEFAULT_PERIOD);
const reconciliationSetSelectedFund = createAction(RECONCILIATION_SELECTED_FUND);
const setSelectedPublishType = createAction(RECONCILIATION_SELECTED_PUBLISH_TYPES);
const setSavedViewGridColumnsState = createAction(RECONCILIATION_SET_GRID_COLUMNS_STATE);
const setGridColumnsState = createAction(RECONCILIATION_SET_GRID_COLUMNS_SET_STATE);
const setSummaryTotals = createAction(RECONCILIATION_SET_SUMMARY_TOTALS);
const setDataTotals = createAction(RECONCILIATION_SET_DATA_TOTALS);
const setExportHistoryResults = createAction(RECONCILIATION_SET_DOCUMENT_EXPORTS);
const setUpdatedActionComment = createAction(RECONCILIATION_UPDATE_ACTION_COMMENTS);

const fetchSummaryTotals = (fundGroupId, periodDate) => (dispatch) => (
  dispatch(getRequest({
    url: '/hedge_fund_accounting_report_records/summary_totals',
    params: {
      fund_group_id: fundGroupId,
      period: periodDate,
    },
    cancelInstance: cancelFetchSummaryTotals,
    showSpinner: false,
    onSuccess: setSummaryTotals,
  }))
);

const fetchPeriod = (selectedFund, fundGroupId) => (dispatch) => (
  dispatch(getRequest({
    url: '/hedge_fund_accounting_report_records/period',
    params: {
      selected_fund: selectedFund,
      fund_group_id: fundGroupId,
    },
    showSpinner: false,
  }))
);

const fetchExportFunds = (fundGroupId) => (dispatch) => (
  dispatch(getRequest({
    url: '/private_access_funds/for_reconciliation',
    params: {
      fund_group_id: fundGroupId,
      fund_type: 'hedge',
    },
    showSpinner: false,
  }))
);

const fetchDataTotals = (params) => (dispatch) => (
  dispatch(getRequest({
    url: params.url,
    params: {
      paf_id: params.pafId,
      period: params.periodDate,
      publish_type: params.publishType,
    },
    showSpinner: false,
    onSuccess: setDataTotals,
  }))
);

const fetchBeginningBalanceTotals = (pafId, periodDate, publishType) => (
  fetchDataTotals({
    pafId,
    periodDate,
    publishType,
    url: '/hedge_fund_accounting_report_records/beginning_balance_totals',
  })
);

const fetchEndingBalanceTotals = (pafId, periodDate, publishType) => (
  fetchDataTotals({
    pafId,
    periodDate,
    publishType,
    url: '/hedge_fund_accounting_report_records/ending_balance_totals',
  })
);

const fetchManagementFeeTotals = (pafId, periodDate, publishType) => (
  fetchDataTotals({
    pafId,
    periodDate,
    publishType,
    url: '/hedge_fund_accounting_report_records/management_fee_totals',
  })
);

const fetchPafsWithoutReports = (fundGroupId, periodDate) => (dispatch) => (
  dispatch(getRequest({
    url: '/hedge_fund_accounting_report_records/pafs_without_reports',
    params: {
      fund_group_id: fundGroupId,
      period: periodDate,
    },
    cancelInstance: cancelFetchPafsWithoutReports,
    showSpinner: false,
    onSuccess: setPafsWithoutReports,
  }))
);

const exportReconciliation = (pafIds, periodDate, groupShareClass, icnDifferenceColumns, publishType) => (dispatch) => (
  dispatch(postRequest({
    url: '/hedge_fund_accounting_report_records/export_reconciliation',
    data: {
      private_access_fund_ids: pafIds,
      period: periodDate,
      groupByShareClass: groupShareClass,
      publish_type: publishType,
      icn_difference_columns: icnDifferenceColumns,
    },
    showSpinner: false,
  }))
);

const refreshDataReconciliation = (pafIds, periodDate, publishType) => (dispatch) => (
  dispatch(postRequest({
    url: '/hedge_fund_accounting_report_records/refresh_data_reconciliation',
    data: {
      private_access_fund_ids: pafIds,
      period: periodDate,
      publish_type: publishType,
    },
    showSpinner: false,
  }))
);

const fetchReconciliationExports = (currentPage = 1, resultsPerPage = 25) => (dispatch) => (
  dispatch(getRequest({
    url: '/document_exports',
    params: {
      document_types: ['HedgeFundAccountingReportRecordExportXlsx'],
      page: currentPage,
      per_page: resultsPerPage,
    },
    showSpinner: false,
    onSuccess: setExportHistoryResults,
  }))
);

const approveReconciliation = (
  pafId, periodDate, publishType, reconciliationPath, note, approverAction
) => (dispatch) => (
  dispatch(postRequest({
    url: '/statement_reconciliation_statuses/approve',
    data: {
      private_access_fund_id: pafId,
      period: periodDate,
      view: reconciliationPath,
      comments: note,
      approver_action: approverAction,
      publish_type: publishType,
    },
    showSpinner: false,
  }))
);

const cancelReconciliation = (
  pafId, periodDate, publishType, reconciliationPath, note
) => (dispatch) => (
  dispatch(postRequest({
    url: '/statement_reconciliation_statuses/cancel',
    data: {
      private_access_fund_id: pafId,
      period: periodDate,
      view: reconciliationPath,
      comments: note,
      publish_type: publishType,
    },
    showSpinner: false,
  }))
);

const fetchReconciliationTabStatus = (pafId, periodDate, publishType, reconciliationPath) => (dispatch) => (
  dispatch(getRequest({
    url: '/statement_reconciliation_statuses',
    params: {
      private_access_fund_id: pafId,
      period: periodDate,
      view: reconciliationPath,
      publish_type: publishType,
    },
    showSpinner: false,
    onSuccess: setReconciliationTabStatus,
  }))
);

const fetchActionsApprovalHistory = (pafId, periodDate, publishType) => (dispatch) => (
  dispatch(getRequest({
    url: '/statement_reconciliation_statuses/actions_history',
    params: {
      private_access_fund_id: pafId,
      period: periodDate,
      publish_type: publishType,
    },
    showSpinner: false,
    onSuccess: setActionsApprovalHistory,
  }))
);

const updateActionComments = (actionId, userType, note) => (dispatch) => (
  dispatch(postRequest({
    url: '/statement_reconciliation_statuses/update_action_comments',
    data: {
      action_id: actionId,
      type: userType,
      comments: note,
    },
    showSpinner: false,
    onSuccess: setUpdatedActionComment,
  }))
);

const bulkAssignShareClasses = (file) => (dispatch) => (
  dispatch(postRequest({
    url: '/investments/bulk_assign_share_classes',
    data: file,
    showSpinner: false,
  }))
);

const reconciliationFetchFundGroup = () => (dispatch) => (
  dispatch(getRequest({
    url: '/fund_admin_groups/reconciliation_groups',
    showSpinner: false,
    onSuccess: setFundGroup,
  }))
);

const reconciliationFetchPeriods = (selectedFund, fundGroupId) => (dispatch) => (
  dispatch(getRequest({
    url: '/hedge_fund_accounting_report_records/period',
    params: {
      selected_fund: selectedFund,
      fund_group_id: fundGroupId,
    },
    showSpinner: false,
    cancelInstance: cancelReconciliationFetchPeriods,
    onSuccess: setPeriod,
  }))
);

const reconciliationFetchPafs = (fundGroupId, period, periodPage) => (dispatch) => (
  dispatch(getRequest({
    url: 'private_access_funds/for_reconciliation',
    params: {
      fund_group_id: fundGroupId,
      period: periodPage ? period : undefined,
      vintage: true,
      fund_type: 'hedge',
    },
    showSpinner: false,
    cancelInstance: cancelReconciliationFetchPafs,
    onSuccess: setPrivateAccessFunds,
  }))
);

const fetchPossiblePublishTypes = (pafId, periodDate) => (dispatch) => (
  dispatch(getRequest({
    url: '/hedge_fund_accounting_report_records/possible_publish_types',
    params: {
      paf_id: pafId,
      period: periodDate,
    },
    showSpinner: false,
    onSuccess: setPublishTypes,
  }))
);

function reconciliationLoadSavedView(data) {
  return (dispatch, getState) => {
    let fetchPromise = Promise.resolve();
    const filters = data.filters.drop_down_filters;
    const groupedFilters = groupBy(filters, 'filter_type');

    const fundGroupId = groupedFilters.fundGroupId[0].filters[0].typed_filter_id;
    const period = groupedFilters.period[0].filters[0].typed_filter_id;
    const paf = groupedFilters.paf[0].filters[0].typed_filter_id;
    const publishType = groupedFilters.publishType[0].filters[0].typed_filter_id;
    const columnsState = groupedFilters.columnsState[0].filters[0].content;

    const reconState = getState().reconciliation;

    if (columnsState) {
      dispatch(setSavedViewGridColumnsState(columnsState));
      dispatch(setGridColumnsState(columnsState));
    }
    if (fundGroupId && reconState.selected_fund_groups !== fundGroupId) {
      dispatch(setSelectedFundGroups(fundGroupId));
    }
    if (period && reconState.selected_period !== period) {
      dispatch(reconciliationSetSelectedPeriods(period));
    }
    if (paf && paf.id && reconState.selected_fund?.id !== paf.id) {
      fetchPromise = dispatch(reconciliationFetchPafs(fundGroupId, period, true)).then((lol) => {
        if (lol.findIndex((it) => it.id === paf.id) !== -1) {
          dispatch(reconciliationSetSelectedFund(paf));
          if (publishType) {
            dispatch(setSelectedPublishType(publishType));
          }
        }
      });
    }

    return fetchPromise;
  };
}

const reconciliationLoadDefaultView = (slug) => (dispatch) => (
  dispatch(getRequest({
    url: '/user_saved_views/default_view',
    params: { page_slug: `reconciliation/${slug}` },
    onSuccess: reconciliationLoadSavedView,
    showSpinner: false,
  })).catch((err) => {
    if (err.status !== 404) throw err;
  })
);

const clearSummaryTotals = () => (dispatch) => (
  dispatch(setSummaryTotals({
    summary_totals: {},
    total_records: 0,
    total_records_approved: 0,
  }))
);

export default {
  fetchSummaryTotals,
  fetchPeriod,
  fetchExportFunds,
  fetchPafsWithoutReports,
  fetchBeginningBalanceTotals,
  fetchEndingBalanceTotals,
  fetchManagementFeeTotals,
  exportReconciliation,
  refreshDataReconciliation,
  fetchReconciliationExports,
  approveReconciliation,
  cancelReconciliation,
  fetchReconciliationTabStatus,
  fetchActionsApprovalHistory,
  updateActionComments,
  bulkAssignShareClasses,
  reconciliationFetchFundGroup,
  reconciliationFetchPeriods,
  reconciliationFetchPafs,
  fetchPossiblePublishTypes,
  reconciliationLoadSavedView,
  reconciliationLoadDefaultView,
  reconciliationSetSelectedFund,
  reconciliationSetSelectedPeriods,
  clearSummaryTotals,
  setSavedViewGridColumnsState,
  setPrivateAccessFunds,
  setPeriod,
  setDefaultPeriod,
  setSelectedFundGroups,
  setSelectedPublishType,
  setPublishTypes,
  setGridColumnsState,
};
