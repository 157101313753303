/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { getRequest, postRequest } from 'actions/utils';
import i18n from 'i18n.js';
import { createAction } from 'redux-actions';
import capitalizeFirstLetter from 'services/sales_invite_clients/wordsCapitalization';

import {
  IC_RESET_FORM_DATA,
  IC_RESET_INVITE_CLIENTS_DATA,
  IC_SET_ALLOW_FILES_MANAGEMENT,
  IC_SET_TOTAL_BATCH_INVITES,
  IC_SET_CLIENT_EMAIL,
  IC_SET_CLIENT_FIRST_NAME,
  IC_SET_CLIENT_LAST_NAME,
  IC_SET_CLIENT_TYPE,
  IC_SET_CLIENT_USER_TYPE,
  IC_SET_CLIENTS_LIST,
  IC_SET_CURRENT_BATCH_LABEL,
  IC_SET_DEEPLINKING_FUNDS_LIST,
  IC_SET_DEEPLINKING_SELECTED_FUNDS,
  IC_SET_ERROR,
  IC_SET_FUNDS_LIST,
  IC_SET_INVITE_MODAL_STATUS,
  IC_SET_HIDE_COOLOFF_GP_ATTESTATION,
  IC_SET_INVITE_RESPONSE,
  IC_SET_INVITE_TYPE,
  IC_SET_MESSAGE,
  IC_SET_MULTIPLE_CLIENTS_INVITATION_LIST,
  IC_SET_PRE_EXISTING_RELATIONSHIP,
  IC_SET_SELECTED_FUNDS,
  IC_SET_STORED_FILES,
  IC_SET_GP_ADMIN_COOLOFF_DIRECTS_ATTESTATION,
  IC_SET_GP_ADMIN_COOLOFF_FEEDERS_ATTESTATION,
} from 'actions/actionsConstants';

import { INVITE_MODAL_STATUS } from 'reducers/sales_invite_clients/inviteClientsReducer';
import { GATEWAY_TIMEOUT_ERROR } from 'components/sales_invite_clients/InviteFailureConstants';
import { postRequest as axiosPostRequest } from 'services/apiRequest';

const icSetClientFirstName = createAction(IC_SET_CLIENT_FIRST_NAME);
const icSetClientLastName = createAction(IC_SET_CLIENT_LAST_NAME);
const icSetClientEmail = createAction(IC_SET_CLIENT_EMAIL);
const icSetClientType = createAction(IC_SET_CLIENT_TYPE);
const icSetClientUserType = createAction(IC_SET_CLIENT_USER_TYPE);
const icSetClientsList = createAction(IC_SET_CLIENTS_LIST);
const icSetFundsList = createAction(IC_SET_FUNDS_LIST);
const icSetDeeplinkingFundsList = createAction(IC_SET_DEEPLINKING_FUNDS_LIST);
const icSetSelectedFunds = createAction(IC_SET_SELECTED_FUNDS);
const icSetDeeplinkingSelectedFunds = createAction(IC_SET_DEEPLINKING_SELECTED_FUNDS);
const icSetInviteModalStatus = createAction(IC_SET_INVITE_MODAL_STATUS);
const icSetHideCooloffGPAttestation = createAction(IC_SET_HIDE_COOLOFF_GP_ATTESTATION);
const icSetError = createAction(IC_SET_ERROR);
const icSetMessage = createAction(IC_SET_MESSAGE);
const icResetFormData = createAction(IC_RESET_FORM_DATA);
const icSetAllowFilesManagement = createAction(IC_SET_ALLOW_FILES_MANAGEMENT);
const icSetStoredFiles = createAction(IC_SET_STORED_FILES);
const icSetMultipleClientsInvitationList = createAction(IC_SET_MULTIPLE_CLIENTS_INVITATION_LIST);
const icSetInviteType = createAction(IC_SET_INVITE_TYPE);
const icResetInviteClientsData = createAction(IC_RESET_INVITE_CLIENTS_DATA);
const icSetPreExistingRelationship = createAction(IC_SET_PRE_EXISTING_RELATIONSHIP);
const icSetInviteResponse = createAction(IC_SET_INVITE_RESPONSE);
const icSetCurrentBatchLabel = createAction(IC_SET_CURRENT_BATCH_LABEL);
const icSetTotalBatchInvites = createAction(IC_SET_TOTAL_BATCH_INVITES);
const icSetGPAdminCooloffDirectsAttestation = createAction(IC_SET_GP_ADMIN_COOLOFF_DIRECTS_ATTESTATION);
const icSetGPAdminCooloffFeedersAttestation = createAction(IC_SET_GP_ADMIN_COOLOFF_FEEDERS_ATTESTATION);

const BATCH_INVITES_SIZE = 500;

function icCloseInviteFormModal() {
  return (dispatch) => {
    dispatch(icSetInviteModalStatus(INVITE_MODAL_STATUS.none));
  };
}

function icSetHideGpAttestation(value) {
  return (dispatch) => {
    dispatch(icSetHideCooloffGPAttestation(value));
  };
}

function icFormInputChange(input, value) {
  switch (input) {
    case 'client_first_name':
      return (dispatch) => {
        dispatch(icSetClientFirstName(value));
      };

    case 'client_last_name':
      return (dispatch) => {
        dispatch(icSetClientLastName(value));
      };

    case 'client_type':
      return (dispatch) => {
        dispatch(icSetClientType(value));
      };

    case 'client_user_type':
      return (dispatch) => {
        dispatch(icSetClientUserType(value));
      };

    case 'client_email_address':
      return (dispatch) => {
        dispatch(icSetClientEmail(value));
      };

    case 'selected_funds':
      return (dispatch, getState) => {
        const fundsList = getState().inviteClients.funds_list;
        dispatch(icSetSelectedFunds(fundsList.filter((fund) => value.includes(fund.paf_id))));
      };

    case 'deeplinking_selected_funds':
      return (dispatch, getState) => {
        const fundsList = getState().inviteClients.funds_list;
        const deeplinkingList = getState().inviteClients.deeplinking_funds_list.filter((fund) => (!fundsList.map((e) => e.paf_id).includes(fund.paf_id)));
        dispatch(
          icSetDeeplinkingSelectedFunds(
            {
              funds_list: fundsList.filter((fund) => value.includes(fund.paf_id)),
              deeplinking_funds_list: deeplinkingList.filter((fund) => value.includes(fund.paf_id)),
             }
          )
        );
      };

    case 'pre_existing_relationship':
      return (dispatch) => {
        dispatch(icSetPreExistingRelationship(value));
      };
    case 'gp_admin_cooloff_directs_attestation':
      return (dispatch) => {
        dispatch(icSetGPAdminCooloffDirectsAttestation(value));
      };
    case 'gp_admin_cooloff_feeders_attestation':
        return (dispatch) => {
          dispatch(icSetGPAdminCooloffFeedersAttestation(value));
        };

    default:
      return '';
  }
}

function icHandleErrors(payload) {
  return (dispatch) => {
    let data;
    if (payload.status === 504) {
      data = GATEWAY_TIMEOUT_ERROR;
    } else {
      data = payload.data;
    }
    dispatch(icSetInviteResponse(data));
    dispatch(icSetInviteModalStatus(INVITE_MODAL_STATUS.show_failure_message));
    dispatch(icSetError(true));
  };
}

function userInviteHandleErrors(payload){
  return (dispatch) => {
    dispatch(icSetInviteResponse(payload.data));
  };
};

function handleUserInvitationSuccessfullySent() {
  return (dispatch) => {
    dispatch(icSetError(false));
  };
}

function handleInvitationSuccessfullySent(payload, message, type) {
  return (dispatch) => {
    const fullMessage = [];
    const reinvitedClients = payload.invited_clients;
    const reminderClients = payload.reminded_clients;

    const modalStatus = INVITE_MODAL_STATUS.show_status_message;

    switch (type) {
      case 'reminderReinvitation':
        if (reinvitedClients > 0) {
          fullMessage.push(
            i18n.t(
              'sales_dashboard:client_invitations_resent_count',
              `Client Invitation(s) Resent (${reinvitedClients})`,
              { count: reinvitedClients },
            ),
          );
        }

        if (reminderClients > 0) {
          fullMessage.push(
            i18n.t(
              'sales_dashboard:client_reminders_sent_count',
              `Client Reminder(s) Sent (${reminderClients})`,
              { count: reminderClients },
            )
          );
        }

        dispatch(icSetInviteModalStatus(modalStatus));
        dispatch(icSetMessage(fullMessage));
        break;

      default:
        dispatch(icSetInviteModalStatus(modalStatus));
        dispatch(icSetMessage([message]));
        break;
    }

    dispatch(icSetError(false));
    dispatch(icResetFormData());
  };
}

function getBatchInvites({ clients_list: clientsList, clients_reminder_list: reminderList, ...rest }) {
  const batches = [];
  let batchIndex = 0;

  const getBatchesFromList = (list, listKeyName) => {
    for (let i = 0; i < list.length; i += 1) {
      const listItem = list[i];

      const batchList = batches[batchIndex]?.[listKeyName];

      if (batchList) {
        batchList.push(listItem);
      } else {
        batches[batchIndex] = {
          ...rest,
          ...batches[batchIndex],
          [listKeyName]: [listItem],
        };
      }

      if ((i + 1) % BATCH_INVITES_SIZE === 0) {
        batchIndex += 1;
      }
    }
  };

  if (clientsList && Array.isArray(clientsList)) {
    getBatchesFromList(clientsList, 'clients_list');
  }
  if (reminderList && Array.isArray(reminderList)) {
    getBatchesFromList(reminderList, 'clients_reminder_list');
  }

  return batches;
}

function mergeErrorObjects(baseObject = {}, incomingObject = {}) {
  const incomingKeys = Object.keys(incomingObject);
  incomingKeys.forEach((incomingKey) => {
    if (baseObject[incomingKey]) {
      baseObject[incomingKey] = baseObject[incomingKey].concat(incomingObject[incomingKey]);
      return;
    }
    baseObject[incomingKey] = incomingObject[incomingKey];
  });
  return baseObject;
}

function handleBatchInvites(data, totalInvites, successMessage, type, dispatch) {
  const UNPROCESSABLE_ENTITY_STATUS = 'unprocessable_entity';
  const SUCCESS_STATUS = 'ok';
  const batches = getBatchInvites(data);
  let batchHasErrors = false;
  let currentBatchIndex = 0;

  const inviteResponse = {
    errors: {},
    invited_clients: 0,
    reminded_clients: 0,
    status: null,
    warnings: {},
  };

  const processBatchResult = (payload) => {
    currentBatchIndex += 1;
    if (payload?.status === 504) {
      dispatch(icSetCurrentBatchLabel(null));
      dispatch(icSetInviteResponse(GATEWAY_TIMEOUT_ERROR));
      dispatch(icSetInviteModalStatus(INVITE_MODAL_STATUS.show_failure_message));
      return dispatch(icSetError(true));
    }
    dispatch(icSetCurrentBatchLabel(`${currentBatchIndex * BATCH_INVITES_SIZE}/${totalInvites}`));
    const {
      data: {
        errors, invited_clients, reminded_clients, status, warnings,
      },
    } = payload;
    if (status === UNPROCESSABLE_ENTITY_STATUS) {
      batchHasErrors = true;
    }
    inviteResponse.errors = mergeErrorObjects(inviteResponse.errors, errors);
    inviteResponse.invited_clients += invited_clients;
    inviteResponse.reminded_clients += reminded_clients;
    inviteResponse.status = batchHasErrors ? UNPROCESSABLE_ENTITY_STATUS : status;
    inviteResponse.warnings = mergeErrorObjects(inviteResponse.warnings, warnings);

    if (currentBatchIndex === batches.length) {
      dispatch(icSetCurrentBatchLabel(null));
      dispatch(icSetTotalBatchInvites(null));
      if (inviteResponse.status === SUCCESS_STATUS) {
        return dispatch(handleInvitationSuccessfullySent(inviteResponse, successMessage, type));
      }
      return dispatch(icHandleErrors({ data: inviteResponse }));
    }
    return axiosPostRequest({
      url: 'fund_raising_activity/invite_clients',
      data: batches[currentBatchIndex],
    }).then((response) => {
      processBatchResult({ data: response });
    }).catch((response) => {
      processBatchResult(response);
    });
  };

  dispatch(icSetInviteModalStatus(INVITE_MODAL_STATUS.send_batch_invites));
  dispatch(icSetCurrentBatchLabel(`${currentBatchIndex * BATCH_INVITES_SIZE}/${totalInvites}`));
  dispatch(icSetTotalBatchInvites(totalInvites));
  axiosPostRequest({
    url: 'fund_raising_activity/invite_clients',
    data: batches[currentBatchIndex],
  }).then((payload) => {
    processBatchResult({ data: payload });
  }).catch((payload) => {
    processBatchResult(payload);
  });
}

function handleInvite(data, successMessage, type, dispatch) {
  if (data !== null) {
    const { clients_list: clientsList, clients_reminder_list: reminderList } = data;
    const clientsLength = clientsList?.length || 0;
    const reminderLength = reminderList?.length || 0;
    const totalInvites = clientsLength + reminderLength;
    if (totalInvites > BATCH_INVITES_SIZE) {
      return handleBatchInvites(data, totalInvites, successMessage, type, dispatch);
    }
    return dispatch(postRequest({
      url: 'fund_raising_activity/invite_clients',
      data,
      onSuccess: (payload) => handleInvitationSuccessfullySent(
        payload,
        successMessage,
        type
      ),
      onFailure: (payload) => icHandleErrors(payload),
    }));
  }
  return dispatch(icHandleErrors('Required fields missing.'));
}

function userInviteReinvitationReminder(user_invite) {
  return (dispatch) => {
    if (user_invite !== null) {
      const data = {};
      data.clients_list = [];
      user_invite.client_type = user_invite.role;
      data.clients_list.push(user_invite);
      data.reinvitation_confirmed = true;

      return dispatch(postRequest({
        url: 'fund_raising_activity/invite_clients',
        data,
        onSuccess: (payload) => handleUserInvitationSuccessfullySent(payload),
        onFailure: (payload) => userInviteHandleErrors(payload),
      }));
    }
    return dispatch(userInviteHandleErrors('Required fields missing.'));
  };
}

/* This action will be used in InviteFailureModal
 It sends reminders and / or reinvitations */
function icSendReinvitationReminder() {
  return (dispatch, getState) => {
    const data = {};
    data.clients_list = [];
    data.clients_reminder_list = [];
    data.reinvitation_confirmed = true;

    if (getState().inviteClients.invite_response.warnings.clients_for_reminder) {
      data.clients_reminder_list = getState().inviteClients.invite_response.warnings.clients_for_reminder;
    }

    if (getState().inviteClients.invite_response.warnings.clients_for_reinvitation) {
      data.clients_list.push(...getState().inviteClients.invite_response.warnings.clients_for_reinvitation);
    }

    if (getState().inviteClients.invite_response.warnings.clients_for_representative_confirmation) {
      data.clients_list.push(...getState().inviteClients.invite_response.warnings.clients_for_representative_confirmation);
    }

    const numberOfClients = data.clients_list.length + data.clients_reminder_list.length;
    const successMessage = i18n.t(
      'sales_dashboard:client_invitation_resent_count',
      `Client Invitation Resent (${numberOfClients})`,
      { count: numberOfClients },
    );

    return handleInvite(data, successMessage, 'reminderReinvitation', dispatch);
  };
}

function icSendInvitation() {
  return (dispatch, getState) => {
    const data = {};

    const hasMultipleClient = getState().inviteClients.multiple_clients_invitation_list.length > 0;

    if (getState().inviteClients.invite_type.type === 'individual') {
      if (hasMultipleClient) {
        data.clients_list = getState().inviteClients.multiple_clients_invitation_list;
      } else {
        data.clients_list = getState().inviteClients.clients_list;
      }
    }

    if (getState().inviteClients.invite_type.type === 'bulk_invitation') {
      data.clients_list = getState().inviteClients.multiple_clients_invitation_list;
    }

    let successMessage = '';
    const numberOfClients = data.clients_list.length;

    if (numberOfClients > 0) {
      successMessage = i18n.t(
        'sales_dashboard:client_invitations_sent_count',
        `Client Invitation(s) Sent (${numberOfClients})`,
        { count: numberOfClients },
      );
    }

    return handleInvite(data, successMessage, 'invitation', dispatch);
  };
}

function icFetchFunds() {
  return (dispatch) => dispatch(
    getRequest({
      url: 'private_access_funds/for_invite_clients',
      onSuccess: (payload) => icSetFundsList(payload),
    })
  );
}

const icFetchDeeplinkingFunds = () => (
  getRequest({
    url: 'private_access_funds/for_invite_clients_deep_link_funds',
    onSuccess: (payload) => icSetDeeplinkingFundsList(payload),
  })
);

function icAddClient() {
  return (dispatch, getState) => {
    const {
      client_first_name: clientFirstName,
      client_last_name: clientLastName,
      client_email_address: clientEmailAddress,
      client_type: clientType,
      selected_funds: selectedFunds,
      deeplinking_selected_funds: deeplinkingSelectedFunds,
      pre_existing_relationship: preExistingRelationship,
      clients_list: clientsList,
      client_user_type: clientUserType,
      gp_admin_cooloff_directs_attestation: gpAdminCooloffDirectsAttestation,
      gp_admin_cooloff_feeders_attestation: gpAdminCooloffFeedersAttestation,
    } = getState().inviteClients;

    const client = {
      first_name: capitalizeFirstLetter(clientFirstName),
      last_name: capitalizeFirstLetter(clientLastName),
      email: clientEmailAddress,
      client_type: clientType,
      access_given_pafs: selectedFunds,
      deeplinking_funds: deeplinkingSelectedFunds,
      pre_existing_relationship: preExistingRelationship,
      client_user_type: clientUserType,
      gp_admin_cooloff_directs_attestation: gpAdminCooloffDirectsAttestation,
      gp_admin_cooloff_feeders_attestation: gpAdminCooloffFeedersAttestation,
    };

    clientsList.push(client);

    dispatch(icSetClientsList(clientsList));
    dispatch(icResetFormData());
  };
}

export default {
  icAddClient,
  icFetchFunds,
  icFetchDeeplinkingFunds,
  icSendInvitation,
  icSendReinvitationReminder,
  icSetClientsList,
  icSetInviteModalStatus,
  icSetHideGpAttestation,
  icCloseInviteFormModal,
  icFormInputChange,
  icSetAllowFilesManagement,
  icSetStoredFiles,
  icSetMultipleClientsInvitationList,
  icSetInviteType,
  icResetInviteClientsData,
  icResetFormData,
  icSetDeeplinkingFundsList,
  icSetDeeplinkingSelectedFunds,
  userInviteReinvitationReminder,
};
