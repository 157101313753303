import React, { useCallback } from 'react';
import { post } from 'axios';
import { string, shape, bool } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import {
  getAdvisorMarketingFields as getAdvisorMarketingFieldsSelector,
  getShowAdvisorMarketingBanner,
} from 'modules/landing_page/selectors';

import styles from './MarketingBanner.module.scss';

export function MarketingBanner({
  className = '',
  dataTestId,
  showAdvisorMarketingBanner,
  advisorMarketingFields: { announcement, announcementUrl, fundName, id } = {},
}) {
  const onHit = useCallback(() => {
    post('/hits', {
      key: 'view',
      hitable: 'AdvisorMarketingField',
      hitable_id: id,
      note: `${fundName} - ${announcement}`,
    });
  }, [id, fundName, announcement]);

  return showAdvisorMarketingBanner ? (
    <div className={classNames(styles.banner, className)} data-test-id={dataTestId}>
      <div className={styles.announcementIcon} />
      <div className={styles.fundName}>{fundName}</div>
      <div className={styles.announcementSeparator} />
      <a
        href={announcementUrl}
        data-test-id={`${dataTestId}-link`}
        className={styles.announcement}
        onClick={onHit}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.announcementText}>{announcement}</div>
        <div className={styles.announcementArrowIcon} />
      </a>
    </div>
  ) : null;
}
MarketingBanner.propTypes = {
  className: string,
  dataTestId: string,
  showAdvisorMarketingBanner: bool,
  advisorMarketingFields: shape({
    fundName: string,
    announcement: string,
    announcementUrl: string,
  }),
};
MarketingBanner.defaultProps = {
  className: '',
  dataTestId: '',
  showAdvisorMarketingBanner: false,
  advisorMarketingFields: undefined,
};
function mapStateToProps(state) {
  return {
    showAdvisorMarketingBanner: getShowAdvisorMarketingBanner(state),
    advisorMarketingFields: getAdvisorMarketingFieldsSelector(state),
  };
}
export default connect(mapStateToProps)(MarketingBanner);
