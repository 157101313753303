import { FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATE_TAGS } from 'actions/actionsConstants';

import { updateState } from '../helpers';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case FLEXIBLE_FEED_DASHBOARD_SET_TEMPLATE_TAGS:
      return updateState(state, action.payload, (data) => ({
        templateTags: data,
      }));
    default:
      return state;
  }
};
