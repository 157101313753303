import { actionFactory } from 'services/actions';
import { BEGIN_API_REQUEST, END_API_REQUEST } from 'actions/actionsConstants';
import { PASSPORT_SET_CANDIDATES_DATA, PASSPORT_MENU_TOGGLE } from 'modules/passport/actions/constants';
import { postRequest } from '../../../services/axiosRequest';
import { BASE_URLS, ICN } from '../../../services/axios/constants';

const togglePassportMenu = actionFactory(PASSPORT_MENU_TOGGLE);

export const passFetchCandidates = () => {
  const url = 'passport/candidates';
  return (dispatch) => {
    dispatch({ type: BEGIN_API_REQUEST });
    return postRequest({ baseURL: BASE_URLS[ICN], url })
      .then((data) => {
        dispatch({ type: PASSPORT_SET_CANDIDATES_DATA, payload: data });
        dispatch({ type: END_API_REQUEST });
      })
      .catch((error) => {
        console.log('Sorry, something went wrong.');
        dispatch({ type: END_API_REQUEST });
        throw error;
      });
  };
};

export default {
  togglePassportMenu,
};
