import update from 'immutability-helper';
import {
  SDB_SET_FUNDS,
  SDB_SET_FUNDS_SUMMARY,
  SDB_SET_FUNDS_FIRMS,
  SDB_SET_ADVISORY_FIRMS_FOR_FILTER,
  SDB_SET_FUNDS_FOR_FILTER,
  SDB_SET_WORKFLOW_STATUSES_FOR_FILTER,
  SDB_SET_STATUS_DISPLAY_LABELS,
  SDB_SELECTED_ADVISORY_FIRMS,
  SDB_SELECTED_FUNDS,
  SDB_SELECTED_STATUSES,
  SDB_SET_FILTERED_FUNDS,
  SDB_SET_ACTIVE_SAVED_VIEW,
  SDB_SELECTED_FUND_STATUS,
  SDB_SET_SEARCH_TYPE,
  SDB_SET_SEARCH_TERM,
  SDB_SELECTED_ACTIVITY,
  SBD_SET_BRANCH_CODES_FOR_FILTER,
  SDB_SELECTED_BRANCH_CODES,
  SDB_SET_REFERRAL_CODES_FOR_FILTER,
  SDB_SELECTED_REFERRAL_CODES,
} from './actionsConstants';

import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case SDB_SET_FUNDS: {
      const data = action.payload.map((fund) => ({ ...fund, fund_name: fund.fund_name || '' }));
      return update(state, {
        funds: { $set: data },
      });
    }
    case SDB_SET_FILTERED_FUNDS:
      return update(state, {
        filtered_funds: { $set: action.payload },
      });
    case SDB_SET_FUNDS_FIRMS:
      return update(state, {
        funds_firms: { $set: action.payload },
      });
    case SDB_SET_FUNDS_SUMMARY:
      return update(state, {
        funds_total_count: { $set: action.payload.funds_count },
        totals_summary: { $set: action.payload.totals_summary },
      });
    case SDB_SET_ADVISORY_FIRMS_FOR_FILTER:
      return update(state, {
        advisory_firms: {
          $set: [{ id: -1, name: 'None' }].concat(action.payload || []),
        },
      });
    case SDB_SET_FUNDS_FOR_FILTER:
      return update(state, {
        funds_for_filter: {
          $set: action.payload || [],
        },
      });
    case SDB_SET_WORKFLOW_STATUSES_FOR_FILTER:
      return update(state, {
        workflow_statuses: { $set: action.payload },
      });
    case SDB_SET_STATUS_DISPLAY_LABELS:
      return update(state, {
        status_display_labels: { $set: action.payload },
      });
    case SDB_SELECTED_ADVISORY_FIRMS:
      return update(state, {
        selected_advisory_firms: { $set: action.payload },
      });
    case SDB_SELECTED_FUNDS:
      return update(state, {
        selected_funds: { $set: action.payload },
      });
    case SDB_SELECTED_STATUSES:
      return update(state, {
        selected_statuses: { $set: action.payload },
      });
    case SDB_SET_ACTIVE_SAVED_VIEW:
      return update(state, {
        active_saved_view: { $set: action.payload },
      });
    case SDB_SELECTED_FUND_STATUS:
      return update(state, {
        selected_fund_status: { $set: action.payload },
      });
    case SDB_SET_SEARCH_TYPE:
      return update(state, {
        search_type: { $set: action.payload },
      });
    case SDB_SET_SEARCH_TERM:
      return update(state, {
        search_term: { $set: action.payload },
      });
    case SDB_SELECTED_ACTIVITY:
      return update(state, {
        selected_activity: { $set: action.payload },
      });
    case SBD_SET_BRANCH_CODES_FOR_FILTER:
      return update(state, {
        branch_codes: { $set: action.payload || [] },
      });
    case SDB_SELECTED_BRANCH_CODES:
      return update(state, {
        selected_branch_codes: { $set: action.payload || [] },
      });
    case SDB_SET_REFERRAL_CODES_FOR_FILTER:
      return update(state, {
        referral_codes: { $set: action.payload || [] },
      });
    case SDB_SELECTED_REFERRAL_CODES:
      return update(state, {
        selected_referral_codes: { $set: action.payload || [] },
      });
    default:
      return state;
  }
};
