import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useGetMyProfile } from './query';
const redirect = (data) => {
    if (data === null || data === void 0 ? void 0 : data.wlpUrl) {
        window.location.replace(data.wlpUrl);
    }
};
function SsoRedirect() {
    const { data: profile, isLoading, isError } = useGetMyProfile(redirect);
    if (isLoading) {
        return _jsx("div", { children: "Loading..." });
    }
    if (isError) {
        return _jsx("div", { children: "Error while redirecting" });
    }
    return _jsxs("div", { children: ["Redirecting to ", profile === null || profile === void 0 ? void 0 : profile.wlpUrl, "..."] });
}
export default SsoRedirect;
