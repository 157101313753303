import { ACCOUNT_NUMBER_SEARCH } from 'components/subscriptions_dashboard/constants';
export const gtmHelper = ({ selected_activity, selected_funds, selected_advisory_firms, selected_statuses, selected_fund_status, selected_branch_codes, selected_referral_codes, search_type, search_term, }) => {
    const filters = {
        searchType: !search_term ? undefined : search_type === ACCOUNT_NUMBER_SEARCH.id ? 'Account Number' : 'Account Name',
        selectedFunds: selected_funds.length > 0 ? 'Fund' : undefined,
        selectedAdvisoryFirms: selected_advisory_firms.length > 0 ? 'Firm' : undefined,
        selectedStatuses: selected_statuses.length > 0 ? 'Investment Status' : undefined,
        selectedFundStatuses: !Array.isArray(selected_fund_status) ? 'Fund Status' : undefined,
        selectedBranchCodes: selected_branch_codes.length > 0 ? 'Branch Code' : undefined,
        selectedReferralCodes: selected_referral_codes.length > 0 ? 'Referral Code' : undefined,
    };
    const filtered = Object.values(filters).filter((filter) => filter);
    return {
        dateRange: selected_activity === -1 ? 'All' : `${selected_activity} days`,
        filters: filtered,
    };
};
