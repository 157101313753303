/* eslint-disable max-statements */
import axios from 'axios';
import { SIMON, HYBRID } from 'services/axios/constants';

/**
 * Helper for api requests that calls "axios" service.
 * This is an alternative of apiRequest to use in React Query hooks
 * The "return" in the "catch" of apiRequest is causing an issue in useQuery
 * Returning "undefined" will be transformed into a failed Promise,
 * which means you will get an error,
 * which will also be logged to the console in development mode.
 *
 * References
 *  https://tanstack.com/query/v4/docs/framework/react/guides/query-cancellation#using-axios-with-version-lower-than-v0220
 *  https://tanstack.com/query/v4/docs/framework/react/guides/migrating-to-react-query-4#undefined-is-an-illegal-cache-value-for-successful-queries
 *
 * @typedef {Object} ApiRequestConfig
 *
 * @typedef {import("axios").AxiosRequestConfig} AxiosRequestConfig
 * @typedef {ApiRequestConfig & AxiosRequestConfig} GenericApiRequestConfig
 * @typedef {GenericApiRequestConfig & { token?: string; platform?: string }} ExtendedApiRequestConfig
 *
 * @param {ExtendedApiRequestConfig} config
 */
export const axiosRequest = ({
  url,
  method = 'get',
  withCredentials = true,
  params = {},
  data = {},
  customInterceptors = null,
  responseType = 'json',
  ...config
}) => {
  let axiosInstance = axios;

  if (customInterceptors) {
    axiosInstance = axios.create();

    if (customInterceptors.request) {
      axiosInstance.interceptors.request.use(customInterceptors.request.success, customInterceptors.request.error);
    }

    if (customInterceptors.response) {
      axiosInstance.interceptors.response.use(customInterceptors.response.success, customInterceptors.response.error);
    }
  }

  if (config.platform === SIMON || config.platform === HYBRID) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${config.token}`;
  }

  return axiosInstance({
    url,
    method,
    withCredentials,
    params,
    data,
    responseType,
    ...config,
  }).then((response) => response.data);
};

// Aliases for different request methods
/** @param {ExtendedApiRequestConfig} config */
export const getRequest = (config) => axiosRequest({ ...config, method: 'get' });

/** @param {ExtendedApiRequestConfig} config */
export const postRequest = (config) => axiosRequest({ ...config, method: 'post' });

/** @param {ExtendedApiRequestConfig} config */
export const putRequest = (config) => axiosRequest({ ...config, method: 'put' });

/** @param {ExtendedApiRequestConfig} config */
export const patchRequest = (config) => axiosRequest({ ...config, method: 'patch' });

/** @param {ExtendedApiRequestConfig} config */
export const deleteRequest = (config) => axiosRequest({ ...config, method: 'delete' });

export default axiosRequest;
