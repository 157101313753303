export const getConfigurations = (state) => state.landingPage.configurations;
export const getHeroConfiguration = (state) => state.landingPage.heroConfiguration;
export const getHasAdvisorMarketingFields = (state) => state.landingPage.hasAdvisorMarketingFields;
export const getAdvisorMarketingFields = (state) => state.landingPage.advisorMarketingFields;
export const getShowAdvisorMarketingBanner = (state) =>
  state.landingPage.hasAdvisorMarketingFields &&
  !!state.landingPage.advisorMarketingFields.announcement &&
  !!state.landingPage.advisorMarketingFields.announcementUrl;
export const getAnnouncementsList = (state) => state.landingPage.announcements.list;
export const isLoading = (state) => state.landingPage.loading;
export const getAnnouncementsSize = (state) => state.landingPage.announcements.size;
export const getAnnouncementsFilter = (state) => state.landingPage.announcements.filter;
export const getAnnouncement = (state) => state.landingPage.announcement;
export const getAnnouncementFunds = (state) => state.landingPage.announcementFunds;

// Insights
export const getIsLoadingInsights = (state) => state.landingPage.insights.isLoading;
export const getInsightsList = (state) => state.landingPage.insights.list;
export const getInsightsSize = (state) => state.landingPage.insights.size;
export const getInsightsFilter = (state) => state.landingPage.insights.filter;
export const getAllInsightsCategories = (state) => state.landingPage.insights.categories;
export const showIcapitalInsights = (state) => state.landingPage.configurations.showIcapitalInsights;

// Insights And Research Edit
export const getInitialInsight = (state) => state.landingPage.insights.edit.initialState;
export const getInsightDialogOpen = (state) => state.landingPage.insights.edit.dialogOpen;
export const getInsightCheckForErrors = (state) => state.landingPage.insights.edit.checkForErrors;
export const insightHasChanged = (state) => state.landingPage.insights.edit.changed;
export const getInsightEditMode = (state) => state.landingPage.insights.edit.mode;

// AnnouncementEdit
export const getPageAction = (state) => state.landingPage.edit.pageAction;
export const getRedirectToHome = (state) => state.landingPage.edit.redirectToHome;
export const getInitialAnnouncement = (state) => state.landingPage.edit.initialAnnouncement;
export const getUpdatedAnnouncement = (state) => state.landingPage.edit.updatedAnnouncement;
export const getInitialAnnouncementId = (state) => state.landingPage.edit.initialAnnouncement.id;
export const getInitialAnnouncementVisibleDate = (state) => state.landingPage.edit.initialAnnouncement.visible_date;
export const getInitialAnnouncementExpirationDate = (state) =>
  state.landingPage.edit.initialAnnouncement.expiration_date;
export const getInitialAnnouncementFeaturedUntil = (state) => state.landingPage.edit.initialAnnouncement.featured_until;
export const getInitialAnnouncementTitle = (state) => state.landingPage.edit.initialAnnouncement.title;
export const getInitialAnnouncementDocuments = (state) =>
  state.landingPage.edit.initialAnnouncement.announcement_documents;
export const getInitialAnnouncementAnnouncementCategoryId = (state) =>
  state.landingPage.edit.initialAnnouncement.announcement_category_id;
export const getInitialAnnouncementImageUrl = (state) => state.landingPage.edit.initialAnnouncement.image_url;
export const getInitialAnnouncementFunds = (state) => state.landingPage.edit.initialAnnouncement.funds;
export const getInitialAnnouncementBody = (state) => state.landingPage.edit.initialAnnouncement.body;
export const getInitialAnnouncementDisclaimer = (state) => state.landingPage.edit.initialAnnouncement.disclaimer;
export const getInitialAnnouncementButtonText = (state) => state.landingPage.edit.initialAnnouncement.button_text;
export const getInitialAnnouncementButtonUrl = (state) => state.landingPage.edit.initialAnnouncement.button_url;
export const getInitialAnnouncementStatus = (state) => state.landingPage.edit.initialAnnouncement.status;
export const getInitialAnnouncementTranslations = (state) => state.landingPage.edit.initialAnnouncement.translations;
export const getAddAnnouncementPermissionEnabled = (state) =>
  state.icnReactBootstrap.icn_react_bootstrap.user.canAddAnnouncements;
export const getPublishedAnnouncementPermissionEnabled = (state) =>
  state.icnReactBootstrap.icn_react_bootstrap.user.canPublishAnnouncements;
export const getTranslateAnnouncementsPermissionEnabled = (state) =>
  state.icnReactBootstrap.icn_react_bootstrap.user.canTranslateAnnouncements;
export const getInitialAnnouncementButtonInteraction = (state) =>
  state.landingPage.edit.initialAnnouncement.button_interaction;
export const getCategoriesOptions = (state) => state.landingPage.edit.options.categoriesOptions;
export const getFundsOptions = (state) => state.landingPage.edit.options.fundsOptions;
export const getCheckForErrors = (state) => state.landingPage.edit.checkForErrors;
export const getDialogOpen = (state) => state.landingPage.edit.dialogOpen;
export const getMode = (state) => state.landingPage.edit.mode;
export const getMissingCategoriesData = (state) => state.landingPage.edit.missingCategoriesData;

// Translations
export const getAnnouncementListTranslations = (state) => state.landingPage.edit.translation.list;
export const getAnnouncementTranslation = (state) => state.landingPage.edit.translation.announcement;
export const getAnnouncementOriginalTranslation = (state) => state.landingPage.edit.translation.originalAnnouncement;
export const getAnnouncementDialogIsOpen = (state) => state.landingPage.edit.translation.announcementDialogOpen;
