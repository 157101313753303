export const capableEvaluatingInvestments = {
  questionKey: 'capable_evaluating_investments',
  text: 'I am capable of evaluating investment risks independently, both in general and with regard to all transactions and investment strategies involving a security or securities',
};

export const exerciseIndependentJudgment = {
  questionKey: 'exercise_independent_judgement',
  text: 'I do exercise independent judgment in evaluating the recommendations of any broker-dealer or its associated persons',
};

export const responsibleVerificationClientEligibility = {
  questionKey: 'responsible_verification_client_eligibility',
  text: 'I agree that I am solely responsible for verifying that any client meets any applicable eligibility requirements prior to entering into any transaction and/or investment strategies involving a security or securities',
};

export const attestationsData = {
  capable_evaluating_investments: {
    questionKey: 'capable_evaluating_investments',
    text: 'I am capable of evaluating investment risks independently, both in general and with regard to all transactions and investment strategies involving a security or securities.',
  },
  exercise_independent_judgement: {
    questionKey: 'exercise_independent_judgement',
    text: 'I exercise independent judgment in evaluating the recommendations of any broker-dealer or its associated persons.',
  },
  responsible_verification_client_eligibility: {
    questionKey: 'responsible_verification_client_eligibility',
    text: 'I agree that I am solely responsible for verifying that any client meets any applicable eligibility requirements prior to entering into any transaction and/or investment strategies involving a security or securities.',
  },
  access_for_research: {
    questionKey: 'access_for_research',
    text: 'I will only access the iCapital platform for research purposes and will not make investment decisions on behalf of any individual account.',
  },
  access_for_admin: {
    questionKey: 'access_for_admin',
    text: 'I will only access the iCapital platform for the purpose of conducting certain administrative services and will not make investment decisions on behalf of any individual account.',
  },
  no_securities_registration: {
    questionKey: 'no_securities_registration',
    text: 'I will not engage in any activities on the iCapital platform requiring registration or licensing with the Securities and Exchange Commission, FINRA, any state securities commission or agency, or any securities exchange or other securities regulatory or self-regulatory body (“securities registrations”).',
  },
  access_for_supervisory: {
    questionKey: 'access_for_supervisory',
    text: 'I will only access the iCapital platform for the purpose of conducting certain supervisory functions and will not make investment decisions on behalf of any individual account.',
  },
  access_for_operations: {
    questionKey: 'access_for_operations',
    text: 'I will only access the iCapital platform for the purpose of conducting certain operational functions and will not make investment decisions on behalf of any individual account.',
  },
  access_for_compliance: {
    questionKey: 'access_for_compliance',
    text: 'I will only access the iCapital platform for the purpose of conducting certain compliance functions and will not make investment decisions on behalf of any individual account.',
  },
  my_firm_capable_evaluating_investments: {
    questionKey: 'my_firm_capable_evaluating_investments',
    text: 'My firm is capable of evaluating investment risks independently, both in general and with regard to all transactions and investment strategies involving a security or securities.',
  },
  my_firm_exercises_independent_judgment: {
    questionKey: 'my_firm_exercises_independent_judgment',
    text: 'My firm exercises independent judgment in evaluating the recommendations of any broker-dealer or its associated persons.',
  },
  my_firm_responsible_verification_client_eligibility: {
    questionKey: 'my_firm_responsible_verification_client_eligibility',
    text: 'My firm agrees that its personnel are solely responsible for verifying that any client meets any applicable eligibility requirements prior to entering into any transaction and/or investment strategies involving a security or securities.',
  },
};

export const attestationGroups = {
  financialProfessionals: [
    'capable_evaluating_investments',
    'exercise_independent_judgement',
    'responsible_verification_client_eligibility',
  ],
  investmentResearch: [
    'access_for_research',
    'my_firm_capable_evaluating_investments',
    'my_firm_exercises_independent_judgment',
    'my_firm_responsible_verification_client_eligibility',
  ],
  clientServices: [
    'access_for_admin',
    'no_securities_registration',
    'my_firm_capable_evaluating_investments',
    'my_firm_exercises_independent_judgment',
    'my_firm_responsible_verification_client_eligibility',
  ],
  supervisoryRoles: [
    'access_for_supervisory',
    'my_firm_capable_evaluating_investments',
    'my_firm_exercises_independent_judgment',
    'my_firm_responsible_verification_client_eligibility',
  ],
  operations: [
    'access_for_operations',
    'my_firm_capable_evaluating_investments',
    'my_firm_exercises_independent_judgment',
    'my_firm_responsible_verification_client_eligibility',
  ],
  compliance: [
    'access_for_compliance',
    'my_firm_capable_evaluating_investments',
    'my_firm_exercises_independent_judgment',
    'my_firm_responsible_verification_client_eligibility',
  ],
};

const roleToGroupKey = {
  'Financial Advisor': 'financialProfessionals',
  'CIO or Investment Research': 'investmentResearch',
  'Client Service or Admin Support': 'clientServices',
  'Home Office, Sales Manager, Branch Manager or Supervisor': 'supervisoryRoles',
  Operations: 'operations',
  Compliance: 'compliance',
};

export const getRoleKey = (role) => roleToGroupKey[role] || 'financialProfessionals';
